export const monthNames = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
];

export const getCurrentAndLastFiveYears = () => {
	const currentYear = new Date().getFullYear();
	const years = [currentYear + ''];

	for (let i = 1; i <= 5; i++) {
		let yearString = currentYear - i;
		years.push(yearString + '');
	}

	return years;
};

export const columns = [
	{
		field: 'id',
		headerName: 'ID',
		headerClassName: 'label',
		width: 50,
	},
	{
		field: 'RFC',
		headerName: 'RFC',
		headerClassName: 'label',
		minWidth: 150,
	},
	{
		field: 'CantidadTotal',
		headerName: 'Cantidad',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'Mes',
		headerName: 'Mes',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'Año',
		headerName: 'Año',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'Estatus',
		headerName: 'Estatus',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'FechaVigencia',
		headerName: 'Vigencia',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'FechaPago',
		headerName: 'Pago',
		headerClassName: 'label',
		minWidth: 150,
	},
];

export const columnsAprobadas = [
	{
		field: 'clientId',
		headerName: 'Client ID',
		headerClassName: 'label',
		width: 100,
	},
	{
		field: 'RFC',
		headerName: 'RFC',
		headerClassName: 'label',
		minWidth: 150,
	},
	{
		field: 'fechaLimite',
		headerName: 'Fecha Límite',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'añoRegistro',
		headerName: 'Año',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'mesRegistro',
		headerName: 'Mes',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'total',
		headerName: 'Cantidad',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'estatus',
		headerName: 'Estatus',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'fechaAprobado',
		headerName: 'Fecha de Aprobación',
		headerClassName: 'label',
		minWidth: 240,
	},
];

export const columnsSolicitadas = [
	{
		field: 'id',
		headerName: 'ID',
		headerClassName: 'label',
		width: 50,
	},
	{
		field: 'RFC',
		headerName: 'RFC',
		headerClassName: 'label',
		minWidth: 170,
	},
	{
		field: 'Usuario',
		headerName: 'Usuario ID',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'CantidadTotal',
		headerName: 'Cantidad',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'Mes',
		headerName: 'Mes',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'Año',
		headerName: 'Año',
		headerClassName: 'label',
		minWidth: 130,
	},
];

export const columnsFactura = [
	{
		field: 'fechaSolicitud',
		headerName: 'Fecha de Solicitud',
		headerClassName: 'label',
		width: 150,
	},
	{
		field: 'userRFC',
		headerName: 'RFC de Usuario',
		headerClassName: 'label',
		width: 150,
	},
	{
		field: 'extranjero',
		headerName: 'Extranjero',
		headerClassName: 'label',
		width: 100,
	},
	{
		field: 'pais',
		headerName: 'País',
		headerClassName: 'label',
		minWidth: 100,
	},
	{
		field: 'idFiscal',
		headerName: 'ID Fiscal',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'nombre',
		headerName: 'Nombre',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'RFC',
		headerName: 'RFC de Cliente',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'CP',
		headerName: 'Código Postal',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'email',
		headerName: 'Email de Cliente',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'regimenFiscal',
		headerName: 'Regimen Fiscal',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'formaPago',
		headerName: 'Forma de Pago',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'usoCFDI',
		headerName: 'Uso CFDI',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'descripcion',
		headerName: 'Descripción Detallada',
		headerClassName: 'label',
		minWidth: 170,
	},
	{
		field: 'metodoPago',
		headerName: 'Método de Pago',
		headerClassName: 'label',
		minWidth: 130,
	},
	{
		field: 'total',
		headerName: 'Total',
		headerClassName: 'label',
		minWidth: 130,
	},
];
