import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileNav from '../components/layout/navbar/ProfileNav';
import Aviso from '../components/layout/views/Client/Privacidad/Aviso';
import Preguntas from '../components/layout/views/Client/Preguntas/Preguntas';
import { Información } from '../components/layout/views/Client/Información/Información';
import Miplan from '../components/layout/views/Client/MiPlan/Miplan';
import Profile from '../components/layout/views/Client/Dashboard/Profile';
import ProfileLeft from '../components/layout/menu/ProfileLeft';
import { profileActions } from '../store/profile';
import generals from '../css/Generals.module.css';
import Facturas from '../components/layout/views/Client/Facturas/Facturas';
import Declaraciones from '../components/layout/views/Client/Declaraciones/Declaraciones';
import Footer from '../components/layout/footer/Footer';

const ProfilePage = () => {
	const dispatch = useDispatch();
	const { userprofiledata } = useSelector((state) => state.profile);
	if (!userprofiledata) {
		dispatch(profileActions.adduserprofiledata('profile'));
	}
	return (
		<div>
			<ProfileNav />
			<div className='container pt-[100px]'>
				<div className={generals.page_sidemenu}>
					<ProfileLeft />
				</div>
				<div
					className={`${generals.page_main_container} ${generals.page_main_welcome_container}`}
				>
					{(userprofiledata === 'profile' || !userprofiledata) && <Profile />}
					{userprofiledata === 'Facturas' && <Facturas />}
					{userprofiledata === 'Declaraciones' && <Declaraciones />}
					{userprofiledata === 'Información' && <Información />}
					{userprofiledata === 'Mi' && <Miplan />}
					{userprofiledata === 'Preguntas' && <Preguntas />}
					{userprofiledata === 'Aviso' && <Aviso title='Aviso de Privacidad' />}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ProfilePage;
