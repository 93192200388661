import React from 'react';
import logo from '../../../assets/images/logo.png';
import googlePlayBadge from '../../../assets/images/google-play-badge.png';
import appStoreBadge from '../../../assets/images/Download_on_the_App_Store_Badge_ES_RGB_wht_100217.svg';
import facebook from '../../../assets/images/facebook.png';
import twitter from '../../../assets/images/twitter.png';
import linkidin from '../../../assets/images/linkidin.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classes from './Footer.module.css';

const FooterHome = ({ scrollSection, projectRef, aboutRef, reviewsRef }) => {
	const getCurrentYear = () => {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		return currentYear;
	};
	const location = useLocation();
	const navigate = useNavigate();

	const isHomePage = location.pathname === '/';
	const appleStoreLink =
		'https://apps.apple.com/us/app/ciberconta/id6462903867';
	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=com.nainkhaskheli.Ciberconta';
	return (
		<div className='md:pt-[70px] py-[20px]'>
			<div className='container'>
				<div className='flex items-center md:items-start flex-col md:flex-row justify-between'>
					<div className='flex flex-col items-center md:items-start mt-[20px]'>
						<div className='mb-3'>
							<img src={logo} alt='Ciberconta' />
						</div>
						<div className={`${classes.insignia_footer} mt-[40px]`}>
							<div className='flex'>
								<a
									className={`${classes.insignia_footer_img} flex w-[155px]`}
									href={googlePlayLink}
									target='_blank'
								>
									<img src={googlePlayBadge} alt='Google Play' />
								</a>
							</div>

							<div
								className={`${classes.insignia_footer_img} flex justify-center relative`}
							>
								<a
									className='flex w-[145px]'
									href={appleStoreLink}
									target='_blank'
								>
									<img src={appStoreBadge} alt='App Store' />
								</a>
							</div>
						</div>
					</div>

					<div className='flex md:text-[20px] text-[14px] font-normal flex items-end lg:gap-[130px] md:gap-[70px] gap-[20px] mb-[50px]'>
						<div className='lg:flex hidden'>
							<ul className='footer_ul'>
								<li
									onClick={() => {
										isHomePage ? scrollSection(projectRef) : navigate('/');
									}}
									className='text-[20px] cursor-pointer font-normal'
								>
									Cómo funciona
								</li>

								<li
									onClick={() => {
										isHomePage ? scrollSection(aboutRef) : navigate('/');
									}}
									className='text-[20px] cursor-pointer font-normal'
								>
									Precios
								</li>

								<li
									onClick={() => {
										isHomePage ? scrollSection(reviewsRef) : navigate('/');
									}}
									className='text-[20px] cursor-pointer font-normal'
								>
									Dudas
								</li>
							</ul>
						</div>
						<div>
							<ul className='footer_ul'>
								<div className='flex items-start gap-5 py-[10px]'>
									<div className={`md:flex items-center gap-4 hidden`}>
										<a
											href='https://www.linkedin.com/company/ciberconta/'
											target='_blank'
										>
											<img
												className={`${classes.insignia_footer_icon}`}
												style={{ width: '25px' }}
												src={linkidin}
												alt='LinkedIn Ciberconta'
											/>
										</a>
										<a href='https://twitter.com/cibercontamx/' target='_blank'>
											<img
												className={`${classes.insignia_footer_icon}`}
												style={{ width: '25px' }}
												src={twitter}
												alt='Twitter Ciberconta'
											/>
										</a>

										<a
											href='https://www.facebook.com/cibercontamx/'
											target='_blank'
										>
											{' '}
											<img
												className={`${classes.insignia_footer_icon}`}
												style={{ width: '25px' }}
												src={facebook}
												alt='Facebook Ciberconta'
											/>
										</a>
									</div>
								</div>
								<Link to={'/aviso'} target='_blank'>
									{' '}
									<li className='text-center md:text-left'>
										Aviso de privacidad
									</li>
								</Link>
								<Link to={'/terminos'} target='_blank'>
									{' '}
									<li className='text-center md:text-left'>
										Términos y condiciones
									</li>
								</Link>
								<Link to={'/'}>
									{' '}
									<li className='text-center md:text-left'>
										Ciberconta<sup>®</sup> {getCurrentYear()}
									</li>
								</Link>
							</ul>
						</div>
					</div>
					<div
						className={`md:hidden flex w-full justify-center items-center gap-4`}
					>
						<a
							href='https://www.linkedin.com/company/ciberconta/'
							target='_blank'
						>
							<img
								className={`${classes.insignia_footer_icon}`}
								style={{ width: '25px' }}
								src={linkidin}
								alt='LinkedIn Ciberconta'
							/>
						</a>
						<a href='https://twitter.com/cibercontamx/' target='_blank'>
							<img
								className={`${classes.insignia_footer_icon}`}
								style={{ width: '25px' }}
								src={twitter}
								alt='Twitter Ciberconta'
							/>
						</a>
						<a href='https://www.facebook.com/cibercontamx/' target='_blank'>
							{' '}
							<img
								className={`${classes.insignia_footer_icon}`}
								style={{ width: '25px' }}
								src={facebook}
								alt='Facebook Ciberconta'
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterHome;
