export const roundToTwoDecimals = (numericString) => {
	const numericValue = parseFloat(numericString);

	if (!isNaN(numericValue)) {
		const roundedValue = Math.round(numericValue * 100) / 100;
		const [integerPart, decimalPart] = roundedValue.toFixed(2).split('.');
		const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${integerWithCommas}.${decimalPart}`;
	} else {
		return numericString;
	}
};

export const extractDate = (str) => {
	if (str) {
		const indexOfT = str.indexOf('T');

		if (indexOfT !== -1) {
			return str.substring(0, indexOfT);
		} else {
			return str;
		}
	} else {
		return '-';
	}
};

export const capitalizeEveryWord = (str) => {
	return str.replace(/\b\w+\b/g, function (word) {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
};

export const cropWord = (word, maxLength) => {
	if (word.length > maxLength) {
		// Crop the word to maxLength characters and add "..."
		return word.substring(0, maxLength) + '...';
	}
	return word;
};
