import React from 'react';
import { persistor } from '../../../store/index';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import mobileLogo from '../../../assets/images/mobileLogo.png';
import commonStyles from '../../common/Common.module.css';

const LoginNav = () => {
	const navigate = useNavigate();
	const Logout = () => {
		persistor.purge();
		navigate('/signup');
	};

	return (
		<div className=' shadow-lg bg-white lg:h-[100px] h-[66px] fixed w-[100%] z-20 lg:flex hidden items-center'>
			<div className='container'>
				<div className='flex items-center justify-between'>
					<div className='logo -mt-2'>
						<Link className=' hidden lg:flex' to={'/'}>
							<img src={logo} alt='' />
						</Link>
						<Link className=' lg:hidden' to={'/'}>
							<img src={mobileLogo} alt='' />
						</Link>
					</div>

					<div className='flex items-center gap-2'>
						<p className='text-[24px] font-normal'>
							¿Aún no eres usuario de ciberconta?
						</p>
						<button
							onClick={Logout}
							type='submit'
							className={`${commonStyles.button} ml-3 py-3 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[20px]`}
						>
							Regístrate
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginNav;
