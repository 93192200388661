import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Accordion = () => {
	const [select, setSelect] = useState(null);

	const trogle = (i) => {
		if (select === i) {
			setSelect(null);
		} else {
			setSelect(i);
		}
	};

	const dataOdd = [
		{
			id: 1,
			que: '¿Cómo se calcula el ISR?',
			ans: [
				'Para saber cuánto debes pagar sigue los siguientes 5 pasos para calcular el pago de impuestos de manera sencilla.',
				'> Determine su ingreso bruto anual: esto incluye todos los ingresos que haya recibido durante el año, incluyendo su salario, ingresos por inversiones, rentas, entre otros.',
				'> Aplique las deducciones: México permite ciertas deducciones que se pueden restar del ingreso bruto para reducir el monto de impuestos que se deben pagar.',
				'> Calcule su ingreso neto: esto se obtiene al restar las deducciones aplicables del ingreso bruto anual.',
				'> Determine la tarifa impositiva: México tiene un sistema de tarifas progresivas, lo que significa que la tasa impositiva aumenta a medida que aumenta el ingreso. La tarifa impositiva aplicable dependerá del ingreso neto',
				'> Calcule el ISR a pagar: una vez que se conoce la tarifa impositiva correspondiente, se puede calcular el ISR multiplicando el ingreso neto por la tasa impositiva aplicable.',
				'Es importante tener en cuenta que existen otros factores que pueden afectar el cálculo del ISR, como las deducciones específicas aplicables a ciertas actividades económicas y los créditos fiscales disponibles.',
			],
		},
		{
			id: 2,
			que: '¿Cuál es la multa por no declarar?',
			ans: [
				'En México, las multas por no presentar la declaración de impuestos o por presentarla fuera del plazo establecido pueden variar dependiendo del tipo de impuesto y de la gravedad de la infracción. A continuación se describen las multas más comunes:',
				'> Multa por no presentar la declaración: Si un contribuyente no presenta su declaración de impuestos, puede ser sancionado con una multa de hasta $17,460 pesos.',
				'> Multa por presentar la declaración fuera de plazo: Si el contribuyente presenta su declaración de impuestos fuera del plazo establecido, se le puede imponer una multa de entre $1,120 y $3,360 pesos por cada mes de retraso.',
				'> Multa por presentar una declaración con errores u omisiones: Si la declaración de impuestos contiene errores u omisiones que resulten en una disminución del impuesto a pagar, se puede imponer una multa de entre el 50% y el 75% del impuesto omitido.',
				'> Multa por no retener o no enterar impuestos retenidos: Si el contribuyente no retiene o no entera los impuestos que ha retenido a terceros, puede ser sancionado con una multa de hasta el 100% del impuesto retenido.',
				'Es importante tener en cuenta que estas multas pueden ser acumulativas y que, en algunos casos, pueden ser acompañadas por recargos y/o intereses moratorios. Por lo tanto, es recomendable cumplir con las obligaciones fiscales en tiempo y forma para evitar sanciones y reducir el costo financiero asociado a las multas y recargos.',
			],
		},
		{
			id: 3,
			que: '¿Qué productos o servicios puedo deducir de mis impuestos?',
			ans: [
				'Existen ciertos gastos que puedes deducir de tus ingresos para reducir la base sobre la cual se calcula el Impuesto Sobre la Renta (ISR). A continuación se describen algunos de los gastos que podrías considerar:',
				'> Gasolina: los gastos en combustible pueden ser deducidos siempre y cuando estén debidamente comprobados con facturas o tickets de compra.',
				'> Mantenimiento y reparaciones del vehículo: los costos de mantenimiento y reparación del vehículo utilizado para prestar servicios de Uber, como cambios de aceite, servicios de frenos, reparaciones mecánicas, etc., pueden ser deducidos.',
				'> Pagos de intereses de un préstamo para adquirir el vehículo: si el vehículo utilizado para prestar servicios de Uber fue adquirido mediante un préstamo, los pagos de intereses pueden ser deducidos.',
				'> Gastos de limpieza y lavado del vehículo: los gastos de limpieza y lavado del vehículo utilizado para prestar servicios de Uber también pueden ser deducidos.',
				'> Gastos de seguros: los gastos de seguros de auto necesarios para operar en Uber también pueden ser deducidos.',
				'Es importante tener en cuenta que para poder deducir estos gastos, es necesario contar con los comprobantes fiscales correspondientes y cumplir con los requisitos establecidos por las autoridades fiscales en México. Además, es recomendable asesorarse con un experto en impuestos para optimizar la deducción de gastos y cumplir adecuadamente con las obligaciones fiscales correspondientes.',
			],
		},

		{
			id: 4,
			que: '¿Qué es el IVA?',
			ans: [
				'El Impuesto al Valor Agregado (IVA) es un impuesto indirecto que se aplica en México sobre la venta de bienes, la prestación de servicios y la importación de bienes, y que es recaudado por el gobierno federal. El IVA se aplica a una tasa general del 16%, aunque existen ciertas excepciones y tasas reducidas en algunos productos y servicios.',
				'Para calcular el IVA que debes pagar, es necesario multiplicar el monto total de la venta o del servicio prestado por la tasa de IVA correspondiente. Por ejemplo, si vendes un producto por $1,000 pesos, el IVA que debes pagar sería de $160 pesos (16% de $1,000 pesos). El monto total a cobrar al cliente sería de $1,160 pesos (suma del precio del producto más el IVA).',
				'Es importante tener en cuenta que el IVA que se cobra a los clientes es un impuesto trasladado, lo que significa que el contribuyente que lo recauda (el vendedor o prestador de servicios) actúa como un recaudador en nombre del gobierno y debe enterarlo en las autoridades fiscales correspondientes. Además, es posible que el contribuyente pueda deducir el IVA que ha pagado en sus compras y gastos necesarios para llevar a cabo su actividad empresarial, lo que se conoce como crédito fiscal.',
			],
		},
		{
			id: 5,
			que: '¿Cómo sé qué regimen tengo?',
			ans: [
				'Para consultar el régimen fiscal al que estás inscrito en México, puedes seguir los siguientes pasos:',
				'> Ingresar al portal del Servicio de Administración Tributaria (SAT) de México: https://www.sat.gob.mx/',
				'> Iniciar sesión con tu RFC y contraseña.',
				'> Una vez dentro del portal, selecciona la opción "Mi Portal" y luego "Datos Fiscales".',
				'> En esta sección podrás encontrar información sobre tu situación fiscal, incluyendo el régimen fiscal al que estás inscrito.',
				'> También puedes revisar los comprobantes fiscales que hayas emitido o recibido, ya que en ellos se indica el régimen fiscal al que pertenece cada una de las partes.',
				'Es importante tener en cuenta que el régimen fiscal al que estás inscrito dependerá de la actividad económica que realices y del tipo de persona que seas (persona física o persona moral), por lo que es recomendable revisar la normatividad correspondiente y asesorarse con un experto en impuestos para cumplir adecuadamente con las obligaciones fiscales correspondientes.',
			],
		},
		{
			id: 6,
			que: '¿Puedo declararme en ceros aunque mi cálculo dé otra cantidad?',
			ans: [
				'Si tienes ingresos en México, estás obligado a presentar tu declaración anual de impuestos, incluso si el resultado de la misma es cero. Por lo tanto, es importante que presentes tu declaración aunque no tengas impuestos por pagar.',
				'Además, presentar tu declaración de impuestos en ceros te permitirá cumplir con tus obligaciones fiscales y evitar sanciones o multas por parte del Servicio de Administración Tributaria (SAT) de México.',
				'Para presentar tu declaración de impuestos en ceros, debes contar conla información necesaria sobre tus ingresos y gastos, y asegurarte de cumplir con los requisitos y plazos establecidos por las autoridades fiscales.',
			],
		},
		{
			id: 7,
			que: '¿Cuánto es el interes moratorio por pagar mis impuestos tarde?',
			ans: [
				'En México, el interés moratorio por pagar impuestos tarde se calcula en base a la tasa de recargos establecida por el Servicio de Administración Tributaria (SAT), que se actualiza de manera mensual y se aplica sobre el monto del adeudo actualizado.',
				'La tasa de recargos se establece a partir de la tasa de interés interbancaria de equilibrio (TIIE) a 28 días, más un margen de recargos que varía dependiendo del mes en que se realice el pago.',
				'Por ejemplo, en abril de 2023, la tasa de recargos es del 1.25%, lo que significa que el interés moratorio por pagar impuestos tarde sería del 1.25% mensual sobre el monto del adeudo actualizado.',
				'Es importante destacar que el SAT también puede imponer multas y sanciones por el incumplimiento en el pago de impuestos, lo que aumentaría el monto total a pagar. Por lo tanto, es recomendable cumplir con las obligaciones fiscales dentro de los plazos establecidos para evitar cargos adicionales.',
			],
		},
	];

	return (
		<section id='Questions'>
			<div>
				<div className='pt-[20px]'>
					<div className=''>
						<div>
							{dataOdd?.map((item) => {
								return (
									<div key={item.id} className='mb-[10px]'>
										<div
											className='flex items-center'
											onClick={() => trogle(item.id)}
										>
											<h2 className='md:text-[24px] text-[16px]  font-[400] cursor-pointer m-0 mt-0 py-5'>
												{item.que}
											</h2>

											<button className='text-[24px]'>
												{select === item.id ? (
													<IoIosArrowDown />
												) : (
													<IoIosArrowUp />
												)}
											</button>
										</div>
										<div className=''>
											<div
												className={`md:text-[20px] text-[14px] font-normal text-[#123a3280] pb-1 mb-[20px] duration-300 ${
													select === item.id ? '' : 'hidden'
												} `}
											>
												{item.ans.map((p, index) => (
													<p className='py-2' key={index}>
														{p} {'\n'}
													</p>
												))}
											</div>
										</div>
										<div className='border-brand-light-gray border border-b-2 '></div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Accordion;
