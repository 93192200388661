import React from 'react';
import classes from '../css/DetailItem.module.css';

const DetailHeaderDetalles = () => {
	return (
		<div className={classes.detail_header_wrapper}>
			<h2 className={classes.detail_header_element}>Detalle</h2>
			<h2 className={classes.detail_header_element}>Total</h2>
			<h2 className={classes.detail_header_element}>Creado</h2>
			<h2 className={classes.detail_header_element}>Aprobado</h2>
			<h2 className={classes.detail_header_element}>Estatus</h2>
		</div>
	);
};

export default DetailHeaderDetalles;
