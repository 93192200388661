import React from 'react';
import HomeNav from '../components/layout/navbar/HomeNav';
import Terminos from '../components/layout/views/Client/Privacidad/Terminos';
import FooterHome from '../components/layout/footer/FooterHome';

const TerminosPage = () => {
	return (
		<div>
			<HomeNav />
			<div className='mx-[150px] pt-[120px]'>
				<p className='md:text-[24px] text-[14px] font-normal'>
					<Terminos title='Términos y Condiciones' />
				</p>
			</div>
			<FooterHome />
		</div>
	);
};

export default TerminosPage;
