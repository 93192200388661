import React, { useRef, useEffect } from 'react';
import classes from './Common.module.css';

export default function ClickOutsideReact(props) {
	const wrapperRef = useRef(null);
	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				props?.onClickOutside((prevState) => !prevState);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef, props]);

	return <div ref={wrapperRef}>{props.children}</div>;
}
// className={classes.clickOutsideWrapper}
