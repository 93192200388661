import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../../../store/profile';
import { useNavigate } from 'react-router-dom';
import { persistor } from '../../../store/index';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const ProfileAdminLeft = () => {
	const { userprofiledata } = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(true);

	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
	};
	const Logout = () => {
		persistor.purge();
		navigate('/login');
	};

	return (
		<div className='fixed mt-[30px]'>
			<div className='flex flex-col gap-4 items-start text-[24px]'>
				<button
					onClick={() => setProfileData('profile')}
					className={`${userprofiledata === 'profile' ? ' font-bold' : ''}`}
				>
					Dashboard
				</button>

				<button
					onClick={() => {
						isAdminMenuOpen
							? setIsAdminMenuOpen(false)
							: setIsAdminMenuOpen(true);
					}}
					className={`${
						userprofiledata === 'Declaración' ||
						userprofiledata === 'Presentar' ||
						userprofiledata === 'Historial' ||
						userprofiledata === 'Facturas'
							? ' font-bold'
							: ''
					} administrador`}
				>
					Administrador{' '}
					{isAdminMenuOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
				</button>
				{isAdminMenuOpen && (
					<>
						<button
							onClick={() => {
								setProfileData('Declaración');
							}}
							className={`${
								userprofiledata === 'Declaración' ? ' font-bold' : ''
							} administradorSubtitle`}
						>
							Detalle Previo
						</button>
						<button
							onClick={() => {
								setProfileData('Presentar');
							}}
							className={`${
								userprofiledata === 'Presentar' ? ' font-bold' : ''
							} administradorSubtitle`}
						>
							Presentar Declaración
						</button>
						<button
							onClick={() => {
								setProfileData('Facturas');
							}}
							className={`${
								userprofiledata === 'Facturas' ? ' font-bold' : ''
							} administradorSubtitle`}
						>
							Facturas
						</button>
						<button
							onClick={() => {
								setProfileData('Usuarios');
							}}
							className={`${
								userprofiledata === 'Usuarios' ? ' font-bold' : ''
							} administradorSubtitle`}
						>
							Usuarios
						</button>
					</>
				)}

				<button onClick={() => Logout()}>Cerrar sesión</button>
			</div>
		</div>
	);
};

export default ProfileAdminLeft;
