export const extractDate = (str) => {
	if (str) {
		const indexOfT = str.indexOf('T');

		if (indexOfT !== -1) {
			return str.substring(0, indexOfT);
		} else {
			return str;
		}
	} else {
		return '';
	}
};

export const roundToTwoDecimals = (numericString) => {
	const numericValue = parseFloat(numericString);

	if (!isNaN(numericValue)) {
		const roundedValue = Math.round(numericValue * 100) / 100;
		const [integerPart, decimalPart] = roundedValue.toFixed(2).split('.');
		const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${integerWithCommas}.${decimalPart}`;
	} else {
		return numericString;
	}
};

export const spanishMonthOrder = {
	Enero: 0,
	Febrero: 1,
	Marzo: 2,
	Abril: 3,
	Mayo: 4,
	Junio: 5,
	Julio: 6,
	Agosto: 7,
	Septiembre: 8,
	Octubre: 9,
	Noviembre: 10,
	Diciembre: 11,
};
