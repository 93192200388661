import React, { useEffect, useState } from 'react';
import classes from './css/Presentar.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../common/Clickoutside';
import { satDeclarationActions } from '../../../../../store/satDeclaration';
import { useDispatch } from 'react-redux';

const PresentarMoreInfo = ({ setMoreInfo, setModal, moreInfo }) => {
	const dispatch = useDispatch();

	let modalTitle = '';
	let modalButtonText = '';
	if (moreInfo.userData.type === 'satDeclaration') {
		modalTitle = moreInfo.userData.reactivate
			? 'Solicitud de reactivación'
			: 'Declaración presentada';
		modalButtonText = moreInfo.userData.reactivate
			? 'Presentar declaración'
			: 'Editar declaración';
	} else {
		modalTitle = 'Detalle Previo';
		modalButtonText = 'Presentar declaración';
	}

	const handleClose = () => {
		setMoreInfo({ state: false });
	};

	const handlePresentar = () => {
		setMoreInfo({ state: false });
		setModal(true);
		dispatch(
			satDeclarationActions.setPresentSatDeclaration({
				declarationMonth:
					moreInfo.userData.mesRegistro || moreInfo.userData.declarationMonth,
				declarationYear:
					moreInfo.userData['añoRegistro'] || moreInfo.userData.declarationYear,
				declarationType:
					moreInfo.userData.clientStatusType ||
					moreInfo.userData.declarationType,
				declarationDueDate:
					moreInfo.userData.fechalimite || moreInfo.userData.effectiveDate,
				payment: moreInfo.userData.total || moreInfo.userData.payment,
				userRFC: moreInfo.userData.userRFC,
				userId: moreInfo.userData.clientId || moreInfo.userData.userId,
				subscription: moreInfo.userData.userSubscription,
				anualPlan: moreInfo.userData.userAnualPlan,
			})
		);
	};

	const [firstColumn, setFirstColumn] = useState([]);
	const [secondColumn, setSecondColumn] = useState([]);

	useEffect(() => {
		setFirstColumn(Object.keys(moreInfo.document));
		setSecondColumn(Object.values(moreInfo.document));
	}, [moreInfo]);

	return (
		<div className={classes.modal_overlay}>
			<ClickOutsideReact onClickOutside={handleClose}>
				<div className={classes.modal_container}>
					<button className={classes.icon_close_wrapper} onClick={handleClose}>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>{modalTitle}</h1>
					<h2 className={classes.modal_subtitle}>Desglose de información</h2>
					<div className={classes.modal_info_container}>
						<div className={classes.modal_info_element}>
							{firstColumn.length > 0 &&
								firstColumn.map((x) => (
									<h1 key={x} className={classes.modal_info_element_title}>
										{x}
									</h1>
								))}
						</div>
						<div className={classes.modal_info_element}>
							{secondColumn.length > 0 &&
								secondColumn.map((x) => (
									<h1 key={x} className={classes.modal_info_element_subtitle}>
										{x}
									</h1>
								))}
						</div>
					</div>
					<div className={classes.modal_wrapper_button}>
						<button className={classes.modal_button} onClick={handlePresentar}>
							{modalButtonText}
						</button>
					</div>
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default PresentarMoreInfo;
