import React, { useState } from 'react';
import classes from '../css/Filters.module.css';
import { Autocomplete, TextField } from '@mui/material';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { facturaActions } from '../../../../../../store/factura';

const FacturasFilters = ({ users, totalPendings, totalPresented }) => {
	const dispatch = useDispatch();
	const { filterUserRFC, pending, presented } = useSelector(
		(state) => state.factura
	);
	const [filterOptions, setFilterOptions] = useState({
		pending: pending,
		presented: presented,
		filterUserRFC: filterUserRFC,
	});

	const handleChange = (newValue) => {
		setFilterOptions((prevState) => ({
			...prevState,
			filterUserRFC: newValue,
		}));
		handleFiltering({ filterUserRFC: newValue });
	};

	const handleCheckboxPresented = () => {
		setFilterOptions((prevState) => ({
			...prevState,
			pending: !filterOptions.pending,
			presented: filterOptions.pending,
		}));
		handleFiltering({
			pending: !filterOptions.pending,
			presented: filterOptions.pending,
		});
	};

	const handleCheckboxPending = () => {
		setFilterOptions((prevState) => ({
			...prevState,
			pending: filterOptions.presented,
			presented: !filterOptions.presented,
		}));
		handleFiltering({
			pending: filterOptions.presented,
			presented: !filterOptions.presented,
		});
	};

	const handleFiltering = (newValue) => {
		dispatch(
			facturaActions.setFilterOptions({ ...filterOptions, ...newValue })
		);
	};

	return (
		<div>
			<div className={classes.filters_subtitle_wrapper}>
				<h2 className={classes.filters_subtitle}>Filtrar por usuario</h2>{' '}
				<span
					title=''
					className={classes.question_icon}
					data-tooltip-id='my-tooltip'
					data-tooltip-html={
						'<p>La opción default obtiene resultados de Todos los usuarios.</p>'
					}
				></span>
				<Tooltip
					style={{
						maxWidth: '200px',
						fontSize: '1rem',
						zIndex: 1000,
					}}
					id='my-tooltip'
					place='top'
					effect='solid'
					openOnClick={false}
					clickable={true}
				/>
			</div>
			<div className={classes.filters_container}>
				<Autocomplete
					key={'RFC'}
					disablePortal
					options={['Todos los usuarios', ...users]}
					sx={{ width: 300 }}
					value={filterOptions.filterUserRFC}
					renderInput={(params) => (
						<TextField
							className={classes.filters_input_box}
							{...params}
							label='Todos los usuarios'
						/>
					)}
					onChange={(e, newValue) => handleChange(newValue)}
					blurOnSelect={true}
				/>
				<div className='flex items-center gap-[50px]'>
					<div className='flex items-center gap-[10px]'>
						<button
							className={
								filterOptions.pending
									? `${classes.filters_options_active}`
									: `${classes.filters_options}`
							}
							onClick={handleCheckboxPending}
						>
							<p>Pendientes</p>
							{totalPendings !== '' && (
								<span className={classes.total_pendings_icon}>
									{totalPendings.length}
								</span>
							)}
						</button>
						<span
							title=''
							className={classes.question_icon}
							data-tooltip-id='my-tooltip'
							data-tooltip-html={
								'<p>Obtiene solicitudes de emisión y cancelación de facturas.</p>'
							}
						></span>
						<Tooltip
							style={{
								maxWidth: '200px',
								fontSize: '1rem',
								zIndex: 1000,
							}}
							id='my-tooltip'
							place='top'
							effect='solid'
							openOnClick={false}
							clickable={true}
						/>
					</div>
					<div className='flex items-center gap-[10px]'>
						<button
							className={
								filterOptions.presented
									? `${classes.filters_options_active}`
									: `${classes.filters_options}`
							}
							onClick={handleCheckboxPresented}
						>
							<p>Presentadas</p>
							{totalPresented !== '' && (
								<span className={classes.total_pendings_icon}>
									{totalPresented.length > 99 ? '99+' : totalPresented.length}
								</span>
							)}
						</button>

						<span
							title=''
							className={classes.question_icon}
							data-tooltip-id='my-tooltip'
							data-tooltip-html={
								'<p>Obtiene todas las facturas emitidas o canceladas.</p>'
							}
						></span>
						<Tooltip
							style={{
								maxWidth: '200px',
								fontSize: '1rem',
								zIndex: 1000,
							}}
							id='my-tooltip'
							place='top'
							effect='solid'
							openOnClick={false}
							clickable={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FacturasFilters;
