export const monthStatusInitial = [
	{ month: 'Anual', amount: 0, currentStatus: '' },
	{ month: 'Enero', amount: 0, currentStatus: '' },
	{ month: 'Febrero', amount: 0, currentStatus: '' },
	{ month: 'Marzo', amount: 0, currentStatus: '' },
	{ month: 'Abril', amount: 0, currentStatus: '' },
	{ month: 'Mayo', amount: 0, currentStatus: '' },
	{ month: 'Junio', amount: 0, currentStatus: '' },
	{ month: 'Julio', amount: 0, currentStatus: '' },
	{ month: 'Agosto', amount: 0, currentStatus: '' },
	{ month: 'Septiembre', amount: 0, currentStatus: '' },
	{ month: 'Octubre', amount: 0, currentStatus: '' },
	{ month: 'Noviembre', amount: 0, currentStatus: '' },
	{ month: 'Diciembre', amount: 0, currentStatus: '' },
];
