import React from 'react';
import classes from './Información.module.css';
import checked from '../../../../../assets/images/checked.png';
import { useDispatch } from 'react-redux';
import { profileActions } from '../../../../../store/profile';

export const InfoNotifications = ({
	TOKEN,
	USERID,
	profileComplete,
	planComplete,
}) => {
	// Dispatch
	const dispatch = useDispatch();
	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
	};

	return (
		<div className='my-[35px] flex items-center max-w-[700px]'>
			{TOKEN && USERID && (
				<>
					{!profileComplete && (
						<p className='absolute md:text-[24px] text-[14px] font-normal text-[#DB6363]'>
							Completa los campos marcados en rojo.
						</p>
					)}
					{profileComplete && !planComplete && (
						<p className='absolute md:text-[24px] text-[14px] font-normal'>
							¡Tu perfil está completo! Ahora elige tu plan Ciberconta{' '}
							<span
								onClick={() => setProfileData('Mi')}
								style={{ cursor: 'pointer' }}
								className='md:text-[24px] text-[14px] font-normal text-[#DB6363]'
							>
								aquí.
							</span>
						</p>
					)}
					{profileComplete && planComplete && (
						<p className='flex items-center gap-[15px] absolute md:text-[24px] text-[14px] font-normal'>
							<img
								src={checked}
								className='w-[35px]'
								alt='Successful Operation'
							></img>
							¡Tu perfil está completo!
						</p>
					)}
				</>
			)}

			{(!TOKEN || !USERID) && (
				<p className={classes.error_Notification}>
					Ha ocurrido un error en el servidor. Favor de intentar más tarde.
				</p>
			)}
		</div>
	);
};

export default InfoNotifications;
