import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import heroImg from '../../../assets/images/HeroImg.png';
import { useNavigate } from 'react-router-dom';
import googlePlayBadge from '../../../assets/images/google-play-badge.png';
import appStoreBadge from '../../../assets/images/Download_on_the_App_Store_Badge_ES_RGB_wht_100217.svg';
import classes from './Home.module.css';

const Hero = () => {
	const navigate = useNavigate();
	const setEmail = () => {
		navigate('/signup');
	};
	const appleStoreLink =
		'https://apps.apple.com/us/app/ciberconta/id6462903867';
	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=com.nainkhaskheli.Ciberconta';
	return (
		<div className='xl:pt-[150px] lg:pt-[10px] pt-[90px] bg-[#4285F4] xl:h-[900px] lg:h-[760px] flex items-center'>
			<div className='container'>
				<div className='flex items-center flex-col lg:flex-row justify-between gap-5'>
					<div className='flex-1'>
						<h2 className='md:text-[64px] md:leading-[75px] text-[32px] leading-[35px]  font-bold text-white'>
							Tu contabilidad fácil, segura y online
						</h2>
						<p className='md:text-[24px] text-[14px] py-5 text-white'>
							Regístrate y descarga la app para empezar
						</p>
						<div className={classes.insignia_footer}>
							<div className='flex'>
								<a
									className='flex w-[155px]'
									href={googlePlayLink}
									target='_blank'
								>
									<img
										className={classes.insignia_footer_img}
										src={googlePlayBadge}
										alt='Google Play'
									/>
								</a>
							</div>
							<div className='flex'>
								<a
									className='flex w-[145px]'
									href={appleStoreLink}
									target='_blank'
								>
									<img
										className={classes.insignia_footer_img}
										src={appStoreBadge}
										alt='App Store'
									/>
								</a>
							</div>
						</div>
						<div className=' bg-white md:w-[450px] w-auto shadow-md rounded-md flex items-center justify-between'>
							<input
								type='text'
								className='md:text-[24px] text-[14px] px-2 md:w-[70%] w-[60%] outline-none'
								name=''
								id=''
								placeholder='Correo electrónico'
							/>
							<button
								onClick={setEmail}
								className='py-3 md:text-[25px] bg-[#CAD8EF] flex items-center gap-2 md:w-[30%] w-[40%] rounded-r-md px-5'
							>
								Iniciar <BsArrowRight className='text-[25px] md:mt-2' />
							</button>
						</div>
					</div>
					<div className='flex-1 lg:relative h-[710px]'>
						<div className=' lg:absolute left-0 -bottom-[20px]'>
							<img
								src={heroImg}
								alt=''
								className='w-[100%] h-[100%] max-w-[650px]'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
