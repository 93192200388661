import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profileActions } from '../../../../../store/profile';
import QR from '../../../../../assets/images/CibercontaMEQR.png';
import classes from './Profile.module.css';
import styles from './Notifications.module.css';
import generals from '../../../../../css/Generals.module.css';
import declarationsIcon from '../../../../../assets/images/dashboard/invoice.png';
import facturasIcon from '../../../../../assets/images/dashboard/receipt.png';
import timeoutIcon from '../../../../../assets/images/dashboard/clock.png';
import walletIcon from '../../../../../assets/images/dashboard/wallet.png';
import userIcon from '../../../../../assets/images/dashboard/user.png';
import Loading from '../../../../common/Loading';
import { getAllNotifications } from '../../../../api/notifications';
import { FaApple } from 'react-icons/fa';
import { IoLogoAndroid } from 'react-icons/io';
import emptyBox from '../../../../../assets/images/messages/box.png';
import text from '../../../../../css/Text.module.css';
import { formatMexicoTz } from '../../../../../utils/format';

const ProfileAdmin = () => {
	const dispatch = useDispatch();
	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const { userFullName } = useSelector((state) => state.clientStatus);

	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [notifications, setNotifications] = useState();
	const [loading, setLoading] = useState(false);
	const appleStoreLink =
		'https://apps.apple.com/us/app/ciberconta/id6462903867';
	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=com.nainkhaskheli.Ciberconta';

	useEffect(() => {
		setLoading(true);
		const fetchNotifications = async () => {
			try {
				const notifications = await getAllNotifications(TOKEN);
				setNotifications(notifications);
				setLoading(false);
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		};
		fetchNotifications();
	}, []);

	const getRedirectButton = (type, event) => {
		let buttonText = '';
		let profileSection = '';
		switch (type) {
			case 'Documento': {
				if (event?.toLowerCase().includes('satdeclaration')) {
					buttonText = 'Módulo declaraciones ';
					profileSection = 'Presentar';
				} else if (event?.toLowerCase().includes('clientstatus')) {
					buttonText = 'Módulo detalles ';
					profileSection = 'Declaración';
				} else if (event?.toLowerCase().includes('faq')) {
					buttonText = 'Módulo facturas ';
					profileSection = 'Facturas';
				}
				break;
			}

			case 'Stripe':
			case 'Usuario': {
				buttonText = 'Módulo usuarios ';
				profileSection = 'Usuarios';
				break;
			}

			default: {
				buttonText = 'Módulo declaraciones ';
				profileSection = 'Presentar';
				break;
			}
		}
		return (
			<button
				onClick={() => setProfileData(profileSection)}
				className={`${generals.button_white} ${styles.button_white_no_border}`}
			>
				{buttonText} &rarr;
			</button>
		);
	};

	const getIcon = (type) => {
		switch (type) {
			case 'Tienes una declaración pendiente por aprobar.':
				return userIcon;
			case 'Declaración previa por pagar':
			case 'Tienes un pago de impuestos por realizar':
				return walletIcon;
			case 'Hoy vence tu línea de captura':
			case 'Mañana vence tu línea de captura':
				return timeoutIcon;
			default:
				return userIcon;
		}
	};

	return (
		<div>
			<div className={classes.welcome_wrapper}>
				<h2 className='md:text-[44px] text-[32px] mb-3 font-bold'>
					Hola 👋, {userFullName || ''}
				</h2>
				<h3 className='md:text-[24px] text-[20px] mb-3 font-bold'>
					¡Bienvenid@ a tu portal de ciberconta!
				</h3>
			</div>

			<h3 className={classes.main_title}>Operaciones</h3>
			<div className={classes.main_grid}>
				<div className={`${classes.main_wrapper} col-12 `}>
					<h3 className={classes.main_card_title}>Declaraciones</h3>
					<div className={classes.main_card_wrapper}>
						<div className={classes.main_card_img_wrapper}>
							<img
								src={declarationsIcon}
								className={classes.main_card_img}
								alt='Declaraciones'
							/>
						</div>
						<h2 className={classes.main_card_subtitle}>
							Presentar declaraciones pendientes y consulta de historial.
						</h2>
					</div>
					<button
						onClick={() => setProfileData('Presentar')}
						className={generals.button_blue}
					>
						Módulo declaraciones
					</button>
				</div>
				<div className={classes.main_wrapper}>
					<h3 className={classes.main_card_title}>Facturas</h3>
					<div className={classes.main_card_wrapper}>
						<div className={classes.main_card_img_wrapper}>
							<img
								src={facturasIcon}
								className={classes.main_card_img}
								alt='Facturas'
							/>
						</div>
						<h2 className={classes.main_card_subtitle}>
							Gestionar solicitudes de facturas y sus respectivos estatus.
						</h2>
					</div>
					<button
						onClick={() => setProfileData('Facturas')}
						className={`${generals.button_blue} ${classes.button_blue}`}
					>
						Módulo facturas
					</button>
				</div>
			</div>
			<h3 className={classes.main_title}>Mis Notificaciones</h3>
			<div className={classes.main_grid}>
				<div className={styles.notifications_wrapper}>
					{!loading &&
						notifications?.length > 0 &&
						notifications.map((notification) => {
							return (
								<div
									key={notification.id}
									className={
										notification.viewed
											? `mx-[15px] mb-[7px] ${styles.notifications_item_viewed}`
											: `mx-[15px] mb-[7px] ${styles.notifications_item}`
									}
								>
									<div className={`${styles.notifications_cta_wrapper}`}>
										{getRedirectButton(notification.type, notification.event)}
									</div>
									<div className={`${styles.notifications_item_icon_wrapper}`}>
										<img
											className={`${styles.notifications_item_icon_img}`}
											src={getIcon(notification.title)}
											alt=''
										/>
									</div>
									<div className={`${styles.notifications_item_body_wrapper}`}>
										<div className={`${styles.notifications_item_body_header}`}>
											<p className={`${styles.notifications_item_body_title}`}>
												{notification.title}
											</p>
											<p className={`${styles.notifications_item_body_date}`}>
												{formatMexicoTz(notification.createdAt)}
											</p>
										</div>
										<p className={`${styles.notifications_item_body_text}`}>
											{notification.description}
										</p>
									</div>
									{notification.viewed && (
										<p className={`${styles.notifications_item_viewed_text}`}>
											Leída
										</p>
									)}
								</div>
							);
						})}

					{!loading && notifications?.length === 0 && (
						<div className={styles.notifications_body_empty}>
							<img
								className={styles.notifications_icon_box}
								src={emptyBox}
								alt='Sin notificaciones'
							/>
							<h1 className={text.text_title_blue_1}>¡Bandeja vacía!</h1>
							<p className={text.text_description_black_1}>
								No tienes notificaciones por el momento.
							</p>
						</div>
					)}
					{loading && (
						<div className={styles.loading_container}>
							<Loading loading={loading} />
						</div>
					)}
				</div>
			</div>

			<h3 className={`${classes.main_title} ${classes.main_title_profile}`}>
				<button
					className={classes.main_title_button}
					onClick={() => window.open(appleStoreLink)}
				>
					Descarga nuestra app
				</button>
			</h3>
			<div className={classes.main_grid}>
				<div className={classes.main_wrapper_profile}>
					<div className={classes.main_card_img_wrapper_profile}>
						<img
							src={QR}
							className={classes.main_card_img}
							alt='Descarga nuestra app'
						/>
					</div>
					<div className={classes.main_card_wrapper_profile}>
						<h2 className={classes.main_card_subtitle}>
							Tu contabilidad en la palma de la mano.
						</h2>
						<h3 className={classes.main_card_text}>
							Lleva a Ciberconta en tu bolsillo: solicita facturas y maneja tu
							historial de declaraciones de manera fácil y segura desde tu
							celular.
						</h3>
						<div className={classes.main_title_button_wrapper}>
							<button
								onClick={() => window.open(appleStoreLink)}
								className={`${generals.button_white} ${classes.button_white}`}
							>
								<FaApple className='text-[35px] mb-[5px]' color='#4285f4' />{' '}
								Ciberconta iOS
							</button>
							<button
								onClick={() => window.open(googlePlayLink)}
								className={`${generals.button_white} ${classes.button_white}`}
							>
								<IoLogoAndroid
									className='text-[35px] mb-[5px]'
									color='#4285f4'
								/>{' '}
								Ciberconta Android
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileAdmin;
