import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import RegisterNav from '../components/layout/navbar/RegisterNav';
import { useDispatch } from 'react-redux';
import { clientStatusActions } from '../store/clientStatus';
import { authActions } from '../store/auth';
import {
	validateEmail,
	validatePassword,
	validatePhoneNumber,
	validateRFC,
	capitalizeFirstLetter,
} from '../utils/validation';
import commonStyles from '../components/common/Common.module.css';
import { signUp } from '../components/api/users';
import Loading from '../components/common/Loading';
import { Tooltip } from 'react-tooltip';
import classes from '../../src/components/layout/views/Client/Información/Información.module.css';
import FooterHome from '../components/layout/footer/FooterHome';

const RegisterPage = () => {
	// Loading
	const [loading, setLoading] = useState(false);
	// Email
	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(true);
	// RFC
	const [RFC, setRFC] = useState('');
	const [isRFCValid, setIsRFCValid] = useState(true);
	// Phone number
	const [phone, setPhone] = useState('');
	const [isPhoneValid, setIsPhoneValid] = useState(true);
	// Name
	const [name, setName] = useState('');
	// LastName
	const [lastname, setLastName] = useState('');
	// Password
	const [password, setpassword] = useState('');
	const [isPasswordValid, setIsPasswordValid] = useState(true);
	// TermsChecked
	const [isTermsChecked, setTermsChecked] = useState(false);
	// Submitted
	const [isSubmitted, setIsSubmitted] = useState(false);
	// State
	const [state1, setState1] = useState(false);
	const [error, setError] = useState({ state: false, message: '' });
	// Navigate
	const navigate = useNavigate();
	// Dispatch
	const dispatch = useDispatch();

	useEffect(() => {
		// Clean state in first load
		setIsPasswordValid(true);
		setIsPhoneValid(true);
		setIsEmailValid(true);
		setIsRFCValid(true);
		setIsSubmitted(false);
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let validPassword = validatePassword(password);
		let validPhone = validatePhoneNumber(phone);
		let validEmail = validateEmail(email);
		let validRFC = validateRFC(RFC);

		// Validation
		setIsPasswordValid(validPassword);
		setIsPhoneValid(validPhone);
		setIsEmailValid(validEmail);
		setIsRFCValid(validRFC);
		setIsSubmitted(true);

		let isNameValid = name;
		let isLastNameValid = lastname;
		let fullName =
			capitalizeFirstLetter(name) + ' ' + capitalizeFirstLetter(lastname);

		if (
			validPassword &&
			validRFC &&
			validPhone &&
			validEmail &&
			isTermsChecked &&
			isNameValid &&
			isLastNameValid
		) {
			try {
				// Loading
				setError({ state: false, message: '' });
				setLoading(true);
				// Register User
				const response = await signUp(RFC, email, password, fullName, phone);
				switch (response.status) {
					case 200: {
						setLoading(false);
						const result = await response.json();
						dispatch(authActions.setTokenId(result.data.token));
						dispatch(authActions.setUserId(result.data.id));
						dispatch(
							clientStatusActions.setClientData({
								userEmail: result.data.email,
								userName: result.data.rfc,
								userFullName: result.data.name,
								userRFC: result.data.rfc,
								userCIEC: result.data.clave_ciec,
								userPhone: result.data.phone,
								userRole: result.data.role,
								userStripeId: result.data.stripeId,
							})
						);
						if (result) {
							navigate('/profile');
						} else {
							navigate('/login');
						}
						break;
					}
					case 422: {
						setLoading(false);
						setError({
							state: true,
							message:
								'Parece ser que ya existe un usuario con dicha información. Favor de revisar e intentar de nuevo.',
						});
						break;
					}
					default: {
						setLoading(false);
						setError({ state: true, message: 'Error interno en el servidor.' });
					}
				}
			} catch (error) {
				setLoading(false);
				setError({
					state: true,
					message: 'Algo salió mal. Favor de volver a intentar.',
				});
				console.error('Error:', error);
			}
		}
	};

	const trogalButton1 = (e) => {
		e.preventDefault();
		setState1((prevState) => !prevState);
	};

	return (
		<>
			<RegisterNav />
			<div className='pt-[120px]'>
				<div className='container'>
					<div>
						<div className='headding mb-8'>
							<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
								¡Felicidades! Estás un paso más cerca de terminar tu registro
							</h2>
						</div>

						<div className='max-w-[1100px] m-auto'>
							<div className='flex justify-center'>
								<Loading loading={loading} />
							</div>

							{error.state && (
								<p
									style={{
										fontSize: '1.4rem',
										color: '#DB6363',
										textAlign: 'center',
										paddingTop: '25px',
										paddingBottom: '25px',
									}}
								>
									{error.message}
								</p>
							)}
							<form action='' onSubmit={handleSubmit}>
								<div className='flex items-center flex-col md:flex-row gap-0  md:gap-[100px] w-full'>
									<div className='w-full'>
										<div className='py-4'>
											<label htmlFor='Correo' className='ml-5 text-[20px]'>
												Correo
											</label>
											<input
												type='text'
												id='Correo'
												className={
													isEmailValid
														? 'input_box text-[20px] w-full py-2 px-2'
														: 'input_box_error text-[20px] w-full py-2 px-2'
												}
												value={email}
												onChange={(e) => setEmail(e.target.value.trim())}
												onFocus={() => {
													setIsEmailValid(true);
													setError({ state: false, message: '' });
												}}
											/>
										</div>
										<div className='py-4'>
											<label htmlFor='Nombres' className='ml-5 text-[20px]'>
												Nombres
											</label>
											<input
												type='text'
												id='Nombres'
												className={
													isSubmitted && !name
														? 'input_box_error text-[20px] w-full py-2 px-2'
														: 'input_box text-[20px] w-full py-2 px-2'
												}
												value={name}
												onChange={(e) => {
													setName(e.target.value);
												}}
												onFocus={() => setError({ state: false, message: '' })}
											/>
										</div>
										<div className='py-4'>
											<label htmlFor='phone' className='ml-5 text-[20px]'>
												Teléfono celular
											</label>
											<input
												type='text'
												id='phone'
												className={
													isPhoneValid
														? 'input_box text-[20px] w-full py-2 px-2'
														: 'input_box_error text-[20px] w-full py-2 px-2'
												}
												value={phone}
												onChange={(e) => setPhone(e.target.value.trim())}
												onFocus={() => {
													setError({ state: false, message: '' });
													setIsPhoneValid(true);
												}}
											/>
										</div>
									</div>
									<div className='w-full'>
										<div className='py-4'>
											<label htmlFor='RFC' className='ml-5 text-[20px]'>
												RFC
											</label>
											<input
												type='text'
												id='RFC'
												className={
													isRFCValid
														? 'input_box text-[20px] w-full py-2 px-2'
														: 'input_box_error text-[20px] w-full py-2 px-2'
												}
												value={RFC}
												onChange={(e) =>
													setRFC(e.target.value.toUpperCase().trim())
												}
												onFocus={() => {
													setError({ state: false, message: '' });
													setIsRFCValid(true);
												}}
											/>
										</div>
										<div className='py-4'>
											<label htmlFor='Apellidos' className='ml-5 text-[20px]'>
												Apellidos
											</label>
											<input
												type='text'
												id='apellidos'
												className={
													isSubmitted && !lastname
														? 'input_box_error text-[20px] w-full py-2 px-2'
														: 'input_box text-[20px] w-full py-2 px-2'
												}
												value={lastname}
												onChange={(e) => setLastName(e.target.value)}
												onFocus={() => setError({ state: false, message: '' })}
											/>
										</div>
										<div className='py-4 '>
											<label
												htmlFor='Contraseña'
												className='ml-5 text-[20px] flex items-center gap-2'
											>
												Contraseña
												<span
													title=''
													className={classes.question_icon}
													data-tooltip-id='my-tooltip'
													data-tooltip-html={
														'<p>La contraseña debe de tener al menos 8 caracteres, una letra y un número.</p>'
													}
												></span>
												<Tooltip
													style={{
														maxWidth: '200px',
														fontSize: '1rem',
														zIndex: 1000,
													}}
													id='my-tooltip'
													place='top'
													effect='solid'
													openOnClick={false}
													clickable={true}
												/>
											</label>
											<div className=' relative'>
												<input
													type={`${state1 ? 'text' : 'password'}`}
													id='Contraseña'
													className={
														isPasswordValid
															? 'input_box text-[20px] w-full py-2 px-2'
															: 'input_box_error text-[20px] w-full py-2 px-2'
													}
													value={password}
													onChange={(e) => setpassword(e.target.value.trim())}
													onFocus={() => {
														setError({ state: false, message: '' });
														setIsPasswordValid(true);
													}}
												/>

												<button
													className='text-[24px] absolute right-[10px] top-[30%]'
													onClick={trogalButton1}
												>
													{state1 ? (
														<AiOutlineEye />
													) : (
														<AiOutlineEyeInvisible />
													)}
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className='flex items-start flex-col md:flex-row gap-2 justify-between mt-7'>
									<div>
										<div className='flex items-center gap-5'>
											<input
												style={{ cursor: 'pointer' }}
												type='checkbox'
												id='chke'
												className={
													!isTermsChecked && isSubmitted
														? 'chakbox_Error'
														: 'chakbox'
												}
												checked={isTermsChecked}
												onChange={(e) => {
													setError({ state: false, message: '' });
													setTermsChecked(e.target.checked);
												}}
											/>

											<label
												className='md:text-[24px] w-[80%] lg:w-full text-[14px] font-normal'
												htmlFor='chke'
											>
												He leído el{' '}
												<Link
													className='  border-b border-gray-400'
													to={'/aviso'}
													target='_blank'
												>
													aviso de privacidad
												</Link>{' '}
												y acepto los{' '}
												<Link
													className='  border-b border-gray-400'
													to={'/terminos'}
													target='_blank'
												>
													términos y condiciones
												</Link>
											</label>
										</div>
										<p className='md:text-[24px] text-[14px] font-normal text-center mt-5'>
											¿Ya tienes cuenta?{' '}
											<Link
												className='  border-b border-gray-400'
												to={'/login'}
											>
												Iniciar sesión
											</Link>
										</p>
									</div>
									<div>
										<button
											type='submit'
											className={`${commonStyles.button} py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]`}
										>
											Regístrate
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<FooterHome />
		</>
	);
};

export default RegisterPage;
