import React, { useEffect, useState } from 'react';
import classes from './css/DetailItem.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useDispatch, useSelector } from 'react-redux';
import { satDeclarationActions } from '../../../../../store/satDeclaration';
import { extractDate, roundToTwoDecimals } from './utils/utils';
import { getUserFiles } from '../../../../api/userfiles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { formatMexicoTz } from '../../../../../utils/format';

const DetailPendings = ({ userData, setMoreInfo, setModal }) => {
	const dispatch = useDispatch();
	const TOKEN = useSelector((state) => state.auth.tokenId);
	let documentType = '';
	let documentTypeInfoText = '';

	if (userData.type === 'satDeclaration') {
		documentType = userData.reactivate ? 'Reactivación' : 'Declaración';
		documentTypeInfoText = userData.reactivate
			? 'Solicitud de reactivación'
			: 'Declaración presentada';
	} else {
		documentType = 'Detalle';
		documentTypeInfoText = 'Detalle previo aprobado';
	}

	const retrieveFiles = async () => {
		let fileNameConvention = '';
		if (userData.type === 'satDeclaration') {
			fileNameConvention =
				userData.declarationType === 'anual'
					? '_DECLARACIÓN_ANUAL_'
					: '_DECLARACIÓN_';
		} else {
			fileNameConvention =
				userData.clientStatusType === 'anual' ? '_DETALLE_ANUAL_' : '_DETALLE_';
		}

		const value =
			userData.userRFC +
			fileNameConvention +
			(userData.mesRegistro || userData.declarationMonth) +
			(userData['añoRegistro'] || userData.declarationYear) +
			'.pdf';
		const userFile = await getUserFiles(TOKEN, value);
		window.open(userFile.data);
	};

	const getStatusClassName = (status) => {
		switch (status) {
			case 'aprobado':
			case 'pagado': {
				return `${classes.detail_status} ${classes.approved}`;
			}
			case 'presentado':
			case 'vigente': {
				return `${classes.detail_status}`;
			}
			case 'expirado': {
				return `${classes.detail_status} ${classes.pending}`;
			}
			default: {
				return `${classes.detail_status} ${classes.no_status}`;
			}
		}
	};

	const handleOpenPresentModal = () => {
		setModal(true);
		dispatch(
			satDeclarationActions.setPresentSatDeclaration({
				declarationMonth: userData.mesRegistro || userData.declarationMonth,
				declarationYear: userData['añoRegistro'] || userData.declarationYear,
				declarationType: userData.clientStatusType || userData.declarationType,
				declarationDueDate: userData.fechalimite || userData.effectiveDate,
				payment: userData.total || userData.payment,
				userRFC: userData.userRFC,
				userId: userData.clientId || userData.userId,
				subscription: userData.userSubscription,
				anualPlan: userData.userAnualPlan,
			})
		);
	};

	const handleOpenMoreInfoModal = () => {
		if (userData.type === 'clientStatus') {
			setMoreInfo({
				state: true,
				userData: userData,
				document: {
					Tipo: documentTypeInfoText,
					'Usuario RFC': userData.userRFC,
					'Fecha de aprobación': formatMexicoTz(userData.fechaAprobado),
					'Fecha límite de pago': userData.fechalimite,
					Periodo:
						userData.clientStatusType === 'anual'
							? `Anual ${userData['añoRegistro']}`
							: `${userData.mesRegistro} ${userData['añoRegistro']}`,
					'Ingresos ISR': `$${roundToTwoDecimals(userData.Ingresos_ISR)}`,
					'Gastos ISR': `$${roundToTwoDecimals(userData.gastos_ISR)}`,
					'Utilidad ISR': `$${roundToTwoDecimals(userData.utilidad_ISR)}`,
					'Retención ISR': `$${roundToTwoDecimals(userData.retencion_ISR)}`,
					'Porcentaje ISR': `${roundToTwoDecimals(userData.porcentaje_ISR)}%`,
					'Por pagar IVA': `$${roundToTwoDecimals(userData.porPagar_IVA)}`,
					'Acreditable IVA': `$${roundToTwoDecimals(userData.acreditable_IVA)}`,
					'Retención IVA': `$${roundToTwoDecimals(userData.retencion_IVA)}`,
					Total: `$${roundToTwoDecimals(userData.total)}`,
				},
			});
		} else {
			setMoreInfo({
				state: true,
				userData: userData,
				document: userData.reactivate
					? {
							Tipo: documentTypeInfoText,
							'Usuario RFC': userData.userRFC,
							'Fecha de solicitud': formatMexicoTz(userData.updatedAt),
							Periodo:
								userData.declarationType === 'anual'
									? `Anual ${userData.declarationYear}`
									: `${userData.declarationMonth} ${userData.declarationYear}`,
							Total: `$${roundToTwoDecimals(userData.payment)}`,
					  }
					: {
							Tipo: documentTypeInfoText,
							'Usuario RFC': userData.userRFC,
							'Fecha de presentación': formatMexicoTz(
								userData.updatedAt || userData.createdAt
							),
							Periodo:
								userData.declarationType === 'anual'
									? `Anual ${userData.declarationYear}`
									: `${userData.declarationMonth} ${userData.declarationYear}`,
							Total: `$${roundToTwoDecimals(userData.payment)}`,
					  },
			});
		}
	};

	return (
		<div className={classes.detail_wrapper}>
			<button
				onClick={handleOpenPresentModal}
				className={classes.detail_element_seemore}
			>
				<EditNoteIcon sx={{ fontSize: 30 }} />
			</button>
			<button
				onClick={handleOpenPresentModal}
				className={classes.detail_element_download}
			>
				<h2 className={`${classes.detail_status} ${classes.no_status}`}>
					{documentType}
				</h2>
			</button>
			<button
				onClick={handleOpenPresentModal}
				className={classes.detail_element}
			>
				{userData.userRFC || '-'}
			</button>
			<button
				onClick={handleOpenPresentModal}
				className={classes.detail_element}
			>
				{userData.clientStatusType === 'anual' ||
				userData.declarationType === 'anual'
					? `Anual ${userData['añoRegistro'] || userData.declarationYear}`
					: `${userData.mesRegistro || userData.declarationMonth} ${
							userData['añoRegistro'] || userData.declarationYear
					  }`}
			</button>
			<button
				onClick={handleOpenPresentModal}
				className={classes.detail_element}
			>
				{formatMexicoTz(userData.fechaAprobado || userData.updatedAt)}
			</button>
			<button
				onClick={handleOpenPresentModal}
				className={classes.detail_element_download}
			>
				<h2
					className={getStatusClassName(
						userData.estatus || userData.currentStatus
					)}
				>
					{userData.estatus || userData.currentStatus || 'sin estatus'}
				</h2>
			</button>
			<button
				onClick={retrieveFiles}
				className={classes.detail_element_seemore}
			>
				<FileDownloadOutlinedIcon sx={{ fontSize: 25 }} />
			</button>
			<button
				onClick={handleOpenMoreInfoModal}
				className={classes.detail_element_seemore}
			>
				<MoreVertIcon sx={{ fontSize: 25 }} />
			</button>
		</div>
	);
};

export default DetailPendings;
