import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import mobileLogo from '../../../assets/images/mobileLogo.png';

const HomeNav = ({ scrollSection, projectRef, aboutRef, reviewsRef }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const isPrecios = location.pathname === '/precios';
	const isHomePage = location.pathname === '/';

	useEffect(() => {
		if (isPrecios) scrollSection(aboutRef);
	}, []);

	const navigateHome = () => {
		navigate('/');
	};

	return (
		<div className=' shadow-lg bg-white lg:h-[100px] h-[66px] fixed w-[100%] z-20 lg:flex hidden items-center'>
			<div className='container'>
				<div className='flex items-center justify-between'>
					<div className='logo -mt-2'>
						<Link className=' hidden lg:flex' to={'/'}>
							<img src={logo} alt='' />
						</Link>
						<Link className=' lg:hidden' to={'/'}>
							<img src={mobileLogo} alt='' />
						</Link>
					</div>
					<ul className='lg:flex items-center gap-[70px] p-0 hidden'>
						<li
							onClick={() =>
								isHomePage ? scrollSection(projectRef) : navigateHome()
							}
							className='text-[20px] cursor-pointer font-normal'
						>
							Cómo funciona
						</li>
						<li
							onClick={() =>
								isHomePage ? scrollSection(aboutRef) : navigateHome()
							}
							className='text-[20px] cursor-pointer font-normal'
						>
							Precios
						</li>
						<li
							onClick={() =>
								isHomePage ? scrollSection(reviewsRef) : navigateHome()
							}
							className='text-[20px] cursor-pointer font-normal'
						>
							Dudas
						</li>
						<li className='text-[20px] font-[700] text-[#4285F4] '>
							<Link className='mr-5 button-login' to={'/signup'}>
								Regístrate
							</Link>
							<span>|</span>
							<Link className='ml-5 button-signup' to={'/login'}>
								Inicia sesión
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default HomeNav;
