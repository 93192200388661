import { createSlice } from '@reduxjs/toolkit';

const initialSatDeclarationState = {
	declarationMonth: '',
	declarationYear: '',
	declarationType: '',
	declarationDueDate: '',
	payment: 0,
	userRFC: '',
	userId: '',
	anualPlan: '',
	subscription: '',
	pending: true,
	presented: false,
	filterUserRFC: '',
};

const satDeclarationSlice = createSlice({
	name: 'satDeclaration',
	initialState: initialSatDeclarationState,
	reducers: {
		setPresentSatDeclaration(state, action) {
			state.declarationMonth = action.payload.declarationMonth;
			state.declarationYear = action.payload.declarationYear;
			state.declarationType = action.payload.declarationType;
			state.declarationDueDate = action.payload.declarationDueDate;
			state.payment = action.payload.payment;
			state.userRFC = action.payload.userRFC;
			state.userId = action.payload.userId;
			state.subscription = action.payload.subscription;
			state.anualPlan = action.payload.anualPlan;
		},
		setFilterOptions(state, action) {
			state.pending = action.payload.pending;
			state.presented = action.payload.presented;
			state.filterUserRFC = action.payload.filterUserRFC;
		},
	},
});

export const satDeclarationActions = satDeclarationSlice.actions;
export default satDeclarationSlice.reducer;
