import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './Prices.module.css';
const Prices = ({ projectRef }) => {
	const [monthIsActive, setMonthIsActive] = useState(false);

	return (
		<div
			ref={projectRef}
			className='proces xl:py-[100px] xl:h-[1500px] lg:h-[1500px] h-auto lg:py-[100px] py-[50px] lg:pb-[200px] pb-[30px]'
		>
			<div className={classes.plan__HomePriceContainer}>
				<div className='flex-[2] mb-10'>
					<h2 className='md:text-[64px] md:pr-20 text-[32px] leading-[37px] mb-7 md:leading-[75px] font-bold'>
						Enamórate de nuestro servicio
					</h2>
					<div className='mt-6'>
						<Link
							to='/signup'
							className='py-2 px-4 bg-[#4285F4] rounded-lg text-white md:text-[24px] text-[14px] font-normal'
						>
							Regístrate y Descarga la app
						</Link>
					</div>
				</div>
				<div className={classes.plan__PricesOption_Container}>
					<div className='flex xl:items-start items-center gap-9 xl:flex-row flex-col'>
						<div className={classes.plan__MainContainer}>
							<div className='mx-10 md:mx-20 xl:mx-0 flex justify-center flex-wrap'>
								<div className={`${classes.plan__Container_Year} price_box`}>
									<h2 className={classes.plan__Title}>Plan RescateFiscal</h2>
									<p className='text-center text-[20px] leading-[37px]'>
										¡Ponte al Día con tus Impuestos!
									</p>
									<div className={classes.plan__PriceWrapper}>
										<h2 className='text-center text-[64px] leading-[75px] font-semibold'>
											$3,000
										</h2>
										<p className='text-center text-[24px] leading-[37px]'>
											Por año no declarado
										</p>
									</div>

									<h2 className={classes.plan__Body}>
										¿Te encuentras atrasado en tus declaraciones ante el SAT?
										¡No te preocupes más!
									</h2>
									<button className={classes.plan__SelectPlan__button}>
										<Link to='/signup'>
											Regístrate y contrata Rescate Fiscal
										</Link>
									</button>
								</div>
								<div className={classes.plan__ContainerDescription}>
									<ul className={classes.plan__ContainerDescription__list}>
										<li className={classes.plan__ContainerDescription__item}>
											🔎{' '}
											<span style={{ fontWeight: '700' }}>
												Análisis y Regularización:
											</span>{' '}
											Examinamos tu situación fiscal y te ayudamos a regularizar
											tus declaraciones, sin importar cuántos años no hayas
											declarado.
										</li>
										<li className={classes.plan__ContainerDescription__item}>
											🤝{' '}
											<span style={{ fontWeight: '700' }}>
												Colaboración Confiable:
											</span>{' '}
											Trabajamos codo a codo contigo para resolver cualquier
											situación fiscal pendiente.
										</li>
										<li className={classes.plan__ContainerDescription__item}>
											🚀{' '}
											<span style={{ fontWeight: '700' }}>
												Ponte al día con tus Impuestos:
											</span>{' '}
											Ponemos orden en tus asuntos fiscales y te ayudamos a
											cumplir con tus obligaciones de manera eficiente.
										</li>
										<li className={classes.plan__ContainerDescription__item}>
											🔐{' '}
											<span style={{ fontWeight: '700' }}>
												Solución Integral:
											</span>{' '}
											Deja atrás las preocupaciones y céntrate en lo que más
											importa: hacer crecer tus ingresos.
										</li>
									</ul>
								</div>
							</div>
						</div>
						{monthIsActive ? (
							<div className={classes.plan__MainContainer}>
								<div className='mx-10 md:mx-20 xl:mx-0 mt-20 xl:mt-0 flex justify-center flex-wrap relative '>
									<div className={classes.plan__ContainerOptions__wrapper}>
										<button
											className={
												monthIsActive
													? `${classes.plan__ContainerOptions__itemLeft} ${classes.active}`
													: `${classes.plan__ContainerOptions__itemLeft}`
											}
											onClick={() => setMonthIsActive(true)}
										>
											<h2 className={classes.plan__ContainerOptions__itemText}>
												Mensual
											</h2>
										</button>
										<button
											className={
												monthIsActive
													? `${classes.plan__ContainerOptions__itemRight}`
													: `${classes.plan__ContainerOptions__itemRight} ${classes.active}`
											}
											onClick={() => setMonthIsActive(false)}
										>
											<h2 className={classes.plan__ContainerOptions__itemText}>
												Anual
											</h2>
											{/* <p className={classes.plan__ContainerOptions__promo}>
												Promo fin de año
											</p> */}
										</button>
									</div>

									<div className={`${classes.plan__Container} price_box`}>
										<h2 className={classes.plan__Title}>
											Ciberconta FácilFiscal
										</h2>
										<p className='text-center text-[20px] leading-[37px] '>
											¡Simplifica tus Finanzas con Nosotros!
										</p>
										<div className={classes.plan__PriceWrapper}>
											<h2 className='text-[64px] leading-[75px] font-semibold'>
												$300
											</h2>
											<p className='text-center text-[24px] leading-[37px] '>
												Mensual
											</p>
										</div>
										<h2 className={classes.plan__Body}>
											Por tan solo $300 al mes, olvídate de los líos fiscales y
											abraza la tranquilidad financiera.
										</h2>
										<button className={classes.plan__SelectPlan__button}>
											<Link to='/signup'>
												Regístrate y contrata Fácil Fiscal Mensual
											</Link>
										</button>
									</div>
									<div className={classes.plan__ContainerDescription}>
										<ul className={classes.plan__ContainerDescription__list}>
											<li className={classes.plan__ContainerDescription__item}>
												📊{' '}
												<span style={{ fontWeight: '700' }}>
													Cálculo de Impuestos:
												</span>{' '}
												Simplifica el IVA y el ISR con nuestra herramienta fácil
												y amigable.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												📤{' '}
												<span style={{ fontWeight: '700' }}>
													Presentación de Declaraciones:
												</span>{' '}
												Olvídate del estrés de las declaraciones fiscales. En
												abril, hay un pequeño cargo extra por la declaración
												anual.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												👁️{' '}
												<span style={{ fontWeight: '700' }}>
													Transparencia:
												</span>{' '}
												Revisa y aprueba fácilmente lo que presentaremos en tu
												declaración.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												💡{' '}
												<span style={{ fontWeight: '700' }}>Evita Multas:</span>{' '}
												Nuestras notificaciones te mantienen a salvo de multas
												innecesarias.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												📱{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Accesibilidad:
												</span>{' '}
												Tus comprobantes y líneas de captura siempre al alcance.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												🤝{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Interfaz Amigable:
												</span>{' '}
												Intuitiva y fácil de usar.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												🧾{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Hasta 2 Facturas al Mes:
												</span>{' '}
												Simplifica tu facturación sin complicaciones.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												💰{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Pago Mensual:
												</span>{' '}
												Tranquilidad mensual para tus asuntos fiscales.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												🗣{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Asesoría Personalizada:
												</span>{' '}
												Contadores expertos disponibles por mail, chat y
												WhatsApp. ¡Simplifica tu vida financiera con Ciberconta!
												🌐💼
											</li>
										</ul>
									</div>
								</div>
							</div>
						) : (
							<div className={classes.plan__MainContainer}>
								<div className='mx-10 md:mx-20 xl:mx-0 mt-20 xl:mt-0 flex justify-center flex-wrap relative '>
									<div className={classes.plan__ContainerOptions__wrapper}>
										<button
											className={
												monthIsActive
													? `${classes.plan__ContainerOptions__itemLeft} ${classes.active}`
													: `${classes.plan__ContainerOptions__itemLeft}`
											}
											onClick={() => setMonthIsActive(true)}
										>
											<h2 className={classes.plan__ContainerOptions__itemText}>
												Mensual
											</h2>
										</button>
										<button
											className={
												monthIsActive
													? `${classes.plan__ContainerOptions__itemRight}`
													: `${classes.plan__ContainerOptions__itemRight} ${classes.active}`
											}
											onClick={() => setMonthIsActive(false)}
										>
											<h2 className={classes.plan__ContainerOptions__itemText}>
												Anual
											</h2>
											{/* <p className={classes.plan__ContainerOptions__promo}>
												Promo fin de año
											</p> */}
										</button>
									</div>
									<div className={`${classes.plan__Container} price_box`}>
										<h2 className={classes.plan__Title}>
											Ciberconta FácilFiscal
										</h2>
										<p className='text-center text-[20px] leading-[37px]'>
											¡Simplifica tus Finanzas con Nosotros!
										</p>
										<div className={classes.plan__PriceWrapper}>
											<h2 className='text-[64px] leading-[75px] font-semibold'>
												$2,999
											</h2>
											<p className='text-center text-[24px] leading-[37px]'>
												Anual
											</p>
										</div>
										<h2 className={classes.plan__Body}>
											Explora Ciberconta FácilFiscal anual por solo $2,999 al
											año. ¡Promoción de fin de año!
										</h2>
										<button className={classes.plan__SelectPlan__button}>
											<Link to='/signup'>
												Regístrate y contrata Fácil Fiscal Anual
											</Link>
										</button>
									</div>

									<div className={classes.plan__ContainerDescription}>
										<ul className={classes.plan__ContainerDescription__list}>
											<li className={classes.plan__ContainerDescription__item}>
												📊{' '}
												<span style={{ fontWeight: '700' }}>
													Cálculo de Impuestos:
												</span>{' '}
												Simplifica el IVA y el ISR con nuestra herramienta fácil
												y amigable.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												📤{' '}
												<span style={{ fontWeight: '700' }}>
													Presentación de Declaraciones:
												</span>{' '}
												Olvídate del estrés de las declaraciones fiscales.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												👁️{' '}
												<span style={{ fontWeight: '700' }}>
													Transparencia:
												</span>{' '}
												Revisa y aprueba fácilmente lo que presentaremos en tu
												declaración.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												💡{' '}
												<span style={{ fontWeight: '700' }}>Evita Multas:</span>{' '}
												Nuestras notificaciones te mantienen a salvo de multas
												innecesarias.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												📱{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Accesibilidad:
												</span>{' '}
												Tus comprobantes y líneas de captura siempre al alcance.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												🤝{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Interfaz Amigable:
												</span>{' '}
												Intuitiva y fácil de usar.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												🧾{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Hasta 50 Facturas al Año:
												</span>{' '}
												Simplifica tu facturación sin complicaciones.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												💰{' '}
												<span style={{ fontWeight: '700' }}> Pago Anual:</span>{' '}
												Tranquilidad anual para tus asuntos fiscales.
											</li>
											<li className={classes.plan__ContainerDescription__item}>
												🗣{' '}
												<span style={{ fontWeight: '700' }}>
													{' '}
													Asesoría Personalizada:
												</span>{' '}
												Contadores expertos disponibles por mail, chat y
												WhatsApp. ¡Simplifica tu vida financiera con Ciberconta!
												🌐💼
											</li>
										</ul>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Prices;

{
	/* <div className='flex gap-9 flex-wrap justify-between'>
<div className={classes.plan__MainContainer}>
	<div
		className='flex justify-center flex-wrap'
		onClick={() => navigate('/signup')}
	>
		<div className={`${classes.plan__Container_Year} price_box`}>
			<h2 className={classes.plan__Title}>Plan RescateFiscal</h2>
			<p className='text-center text-[20px]'>
				¡Ponte al Día con tus Impuestos!
			</p>
			<div className={classes.plan__PriceWrapper}>
				<h2 className='text-center text-[42px] font-semibold'>
					$3,000
				</h2>
				<p className='text-center text-[20px]'>
					Por año no declarado
				</p>
			</div>
		</div>
		<div className={classes.plan__ContainerDescription}>
			<ul className={classes.plan__ContainerDescription__list}>
				<li className={classes.plan__ContainerDescription__item}>
					🔎{' '}
					<span style={{ fontWeight: '700' }}>
						Análisis y Regularización:
					</span>{' '}
					Examinamos tu situación fiscal y te ayudamos a regularizar
					tus declaraciones, sin importar cuántos años no hayas
					declarado.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					🤝{' '}
					<span style={{ fontWeight: '700' }}>
						Colaboración Confiable:
					</span>{' '}
					Trabajamos codo a codo contigo para resolver cualquier
					situación fiscal pendiente.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					🚀{' '}
					<span style={{ fontWeight: '700' }}>
						Ponte al día con tus Impuestos:
					</span>{' '}
					Ponemos orden en tus asuntos fiscales y te ayudamos a
					cumplir con tus obligaciones de manera eficiente.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					🔐{' '}
					<span style={{ fontWeight: '700' }}>
						Solución Integral:
					</span>{' '}
					Deja atrás las preocupaciones y céntrate en lo que más
					importa: hacer crecer tus ingresos.
				</li>
			</ul>
		</div>
	</div>
</div>
<div className={classes.plan__MainContainer}>
	<div
		className='flex justify-center flex-wrap'
		onClick={() => navigate('/signup')}
	>
		<div className={`${classes.plan__Container} price_box`}>
			<h2 className={classes.plan__Title}>
				Ciberconta FácilFiscal
			</h2>
			<p className='text-center text-[20px]'>
				¡Simplifica tus Finanzas con Nosotros!
			</p>
			<div className={classes.plan__PriceWrapper}>
				<h2 className='text-center text-[42px] font-semibold'>
					$300
				</h2>
				<p className='text-center text-[20px]'>Mensual</p>
			</div>
		</div>
		<div className={classes.plan__ContainerDescription}>
			<ul className={classes.plan__ContainerDescription__list}>
				<li className={classes.plan__ContainerDescription__item}>
					📊{' '}
					<span style={{ fontWeight: '700' }}>
						Cálculo de Impuestos:
					</span>{' '}
					Calcula con facilidad impuestos como el IVA y el ISR. Deja
					que nuestra herramienta te guíe en cada paso.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					📤{' '}
					<span style={{ fontWeight: '700' }}>
						Presentación de Declaraciones:
					</span>{' '}
					Olvídate del estrés de las declaraciones fiscales. Nuestra
					plataforma lo hace por ti y te muestra todo claramente.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					👁️{' '}
					<span style={{ fontWeight: '700' }}>Transparencia:</span>{' '}
					Mantén un ojo en cada detalle. Ve lo que se va a presentar
					en tu declaración y apruébalo en un paso.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					💡{' '}
					<span style={{ fontWeight: '700' }}>Evita Multas:</span>{' '}
					Nuestros recordatorios y seguimientos de fechas de
					vencimiento te mantienen a salvo de multas innecesarias.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					📱{' '}
					<span style={{ fontWeight: '700' }}> Accesibilidad:</span>{' '}
					Accede a tus comprobantes fiscales y líneas de captura en
					formato digital, todo desde la palma de tu mano.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					🤝{' '}
					<span style={{ fontWeight: '700' }}>
						{' '}
						Interfaz Amigable:
					</span>{' '}
					Nuestra plataforma es fácil de usar y amigable. No
					necesitas ser un experto en contabilidad para utilizarla
					con éxito.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					🧾{' '}
					<span style={{ fontWeight: '700' }}>
						{' '}
						Factura hasta 2 veces al mes sin complicaciones:
					</span>{' '}
					Simplifica la facturación y controla tus ingresos de
					manera efectiva.
				</li>
				<li className={classes.plan__ContainerDescription__item}>
					💰{' '}
					<span style={{ fontWeight: '700' }}> Pago Mensual:</span>{' '}
					Una suscripción mensual te brinda tranquilidad. Mantén tus
					asuntos fiscales en orden sin complicaciones.
				</li>
			</ul>
		</div>
	</div>
</div>
</div> */
}
