import React from 'react';
import { Link } from 'react-router-dom';
import RegisterNavbar from '../components/layout/navbar/RegisterNav';
import { clientStatusActions } from '../store/clientStatus';
import { useDispatch, useSelector } from 'react-redux';
import QR from '../assets/images/CibercontaMEQR.png';
import FooterHome from '../components/layout/footer/FooterHome';

const SuccessPage = () => {
	const dispatch = useDispatch;
	const clientDataState = useSelector((state) => state.clientStatus);
	const setProfileData = (data) => {
		dispatch(
			clientStatusActions.setClientData({
				...clientDataState,
				userSubscription: data,
			})
		);
	};

	return (
		<>
			<RegisterNavbar />
			<div className='md:pt-[130px] pt-[70px]'>
				<div className='flex flex-col justify-center items-center'>
					<div>
						<h2 className='md:text-[44px] text-center text-[32px] font-bold'>
							¡Tu orden ha sido procesada de manera exitosa!
						</h2>
					</div>
					<p className='mt-[15px] md:text-[24px] text-[14px] font-normal'>
						Descarga nuestra app y empieza a disfrutar de Ciberconta.{' '}
					</p>
					<img src={QR} alt='' className='w-[300px] my-[15px]' />
					<div className='mt-[30px] max-w-[550px] text-center m-auto'>
						<Link to={'/profile'}>
							<button
								onClick={() => setProfileData('activa')}
								className='py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]'
							>
								Volver a mi perfil
							</button>
						</Link>
					</div>
				</div>
			</div>
			<FooterHome />
		</>
	);
};

export default SuccessPage;
