import React from 'react';
import classes from './StatusIndicator.module.css';

const StatusIndicator = ({ loading, registro, error, setRegistro }) => {
	return (
		<>
			{loading && (
				<div className={classes.presentar_modalContainer}>
					<div className={classes.presentar_modal}>
						<div className={classes.presentar_loadingContainer}>
							<div className={classes.loader}></div>
							<p className={classes.presentar_loadingMessage}>
								Realizando operación...
							</p>
						</div>
					</div>
				</div>
			)}
			{registro && !error.state && (
				<div
					onClick={() => setRegistro(false)}
					className={classes.presentar_modalContainer}
				>
					<div className={classes.presentar_modal}>
						<div className={classes.presentar_loadingContainer}>
							<p className={classes.presentar_loadingMessage}>
								El registro se realizó de manera exitosa.
							</p>
						</div>
					</div>
				</div>
			)}

			{registro && error.state && (
				<div
					onClick={() => setRegistro(false)}
					className={classes.presentar_modalContainer}
				>
					<div className={classes.presentar_modal}>
						<div className={classes.presentar_loadingContainer}>
							<p className={classes.presentar_warningMessage}>
								Ocurrió un error al realizar el registro.
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default StatusIndicator;
