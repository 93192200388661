import React, { useState } from 'react';
import classes from '../css/FacturaModal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import generals from '../../../../../../css/Generals.module.css';
import {
	editFactura,
	addFactura,
	addFacturaContact,
} from '../../../../../api/facturas';
import { useSelector } from 'react-redux';
import { validateEmail, validateRFC } from '../../../../../../utils/validation';
import Loading from '../../../../../common/Loading';
import FacturaModalNewFacturaForm from './FacturaModalNewFacturaForm';
import FacturaModalNewContactForm from './FacturaModalNewContactForm';
import $ from 'jquery';

const FacturaModalFormContainer = ({
	selectedContact,
	setContactModal,
	setRefetch,
}) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const userId = useSelector((state) => state.auth.userId);
	const { userRFC } = useSelector((state) => state.clientStatus);
	const initialState = {
		userId: userId,
		userRFC: userRFC,
		foreigner:
			selectedContact.extranjero === 'extranjero'
				? selectedContact.extranjero
				: false,
		idfiscal: selectedContact.identificacionfiscal || '',
		country: selectedContact.pais || '',
		clientName: selectedContact.nombrecliente || '',
		clientZipCode: selectedContact.cpcliente || '',
		clientEmail: selectedContact.emailcliente || '',
		clientRFC: selectedContact.rfccliente || '',
		regimenFiscal: selectedContact.regimenfiscal || '',
		description: selectedContact.descripciondetallada || '',
		CFDI: selectedContact.usocfdi || '',
		total: selectedContact.total || '',
		currency: selectedContact.moneda || '',
		paymentForm: selectedContact.formadepago || '',
		paymentMethod: selectedContact.metododepago || '',
		idfactura: selectedContact.idfactura || '',
	};
	const [facturaContact, setFacturaContact] = useState(initialState);
	const [factura, setFactura] = useState(selectedContact.rfccliente);
	const [loading, setLoading] = useState(false);
	const [foreigner, setForeigner] = useState(initialState.foreigner);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isRFCValid, setIsRFCValid] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [error, setError] = useState({ state: false, message: '' });
	const [contactSuccess, setContactSuccess] = useState({
		state: false,
		message: '',
	});
	const [facturaSuccess, setFacturaSuccess] = useState({
		state: false,
		message: '',
	});

	const handleContactSubmit = async (e) => {
		e.preventDefault();

		if (foreigner) {
			if (!facturaContact.idfiscal || !facturaContact.country) {
				setError({
					state: true,
					msg: 'Favor de llenar todos los campos solicitados.',
				});
				setIsSubmitted('contact');
				return;
			}
		}
		let validEmail = false;
		let validRFC = false;

		if (facturaContact.clientEmail) {
			validEmail = validateEmail(facturaContact.clientEmail);
			setIsEmailValid(validEmail);
		} else {
			setError({
				state: true,
				msg: 'Favor de llenar todos los campos solicitados.',
			});
			setIsSubmitted('contact');
			return;
		}

		if (facturaContact.clientRFC) {
			validRFC = validateRFC(facturaContact.clientRFC);
			setIsRFCValid(validRFC);
		} else {
			setError({
				state: true,
				msg: 'Favor de llenar todos los campos solicitados.',
			});
			setIsSubmitted('contact');
			return;
		}

		if (
			validRFC &&
			validEmail &&
			facturaContact.clientName &&
			facturaContact.clientZipCode
		) {
			setLoading(true);
			try {
				const response = await addFacturaContact(TOKEN, facturaContact);
				if (response.status === 200) {
					setIsSubmitted('contact');
					setLoading(false);
					setContactSuccess({ state: true, msg: '' });
					setRefetch((prevState) => !prevState);
				} else {
					setIsSubmitted('contact');
					setLoading(false);
					setError({
						state: true,
						msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
					});
				}
			} catch (error) {
				console.error(error);
				setIsSubmitted('contact');
				setLoading(false);
				setError({
					state: true,
					msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		} else {
			setIsSubmitted('contact');
		}
	};

	const handleFacturaSubmit = async (e) => {
		e.preventDefault();

		if (foreigner) {
			if (!facturaContact.idfiscal || !facturaContact.country) {
				setError({
					state: true,
					msg: 'Favor de llenar todos los campos solicitados.',
				});
				setIsSubmitted('factura');
				return;
			}
		}
		let validEmail = false;
		let validRFC = false;

		if (facturaContact.clientEmail) {
			validEmail = validateEmail(facturaContact.clientEmail);
			setIsEmailValid(validEmail);
		} else {
			setError({
				state: true,
				msg: 'Favor de llenar todos los campos solicitados.',
			});
			setIsSubmitted('factura');
			return;
		}

		if (facturaContact.clientRFC) {
			validRFC = validateRFC(facturaContact.clientRFC);
			setIsRFCValid(validRFC);
		} else {
			setError({
				state: true,
				msg: 'Favor de llenar todos los campos solicitados.',
			});
			setIsSubmitted('factura');
			return;
		}

		if (
			validRFC &&
			validEmail &&
			facturaContact.clientName &&
			facturaContact.clientZipCode &&
			facturaContact.userId &&
			facturaContact.userRFC &&
			facturaContact.description &&
			facturaContact.regimenFiscal &&
			facturaContact.CFDI &&
			facturaContact.paymentForm &&
			facturaContact.paymentMethod &&
			facturaContact.currency &&
			facturaContact.total
		) {
			setLoading(true);
			try {
				let response;
				if (facturaContact.idfactura) {
					response = await editFactura(TOKEN, facturaContact, foreigner);
				} else {
					response = await addFactura(TOKEN, facturaContact, foreigner);
				}

				if (response.status === 200) {
					setIsSubmitted('factura');
					setLoading(false);
					setFacturaSuccess({ state: true, msg: '' });
					setRefetch((prevState) => !prevState);
				} else {
					setIsSubmitted('factura');
					setLoading(false);
					setError({
						state: true,
						msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
					});
				}
			} catch (error) {
				console.error(error);
				setIsSubmitted('factura');
				setLoading(false);
				setError({
					state: true,
					msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		} else {
			setIsSubmitted('factura');
		}
	};

	const handleClose = () => {
		const dialogOpen = document.querySelectorAll('div[role="dialog"]');
		const presentationOpen = document.querySelectorAll('[role="presentation"]');
		if (dialogOpen.length <= 0 && presentationOpen.length <= 0) {
			$('body').css('overflow', '');
			setFacturaContact(initialState);
			setFactura(false);
			setForeigner(false);
			setContactModal(false);
		}
	};

	const handleCheckbox = () => {
		setIsSubmitted(false);
		setFacturaContact((prevState) => ({
			...prevState,
			foreigner: !foreigner,
		}));
		setForeigner((prevState) => !prevState);
	};

	const cleanErr = () => {
		setError({
			state: false,
			message: '',
		});
		setIsSubmitted(false);
	};

	const handleFactura = () => {
		setFactura(true);
	};

	return (
		<div className={classes.modal_overlay}>
			<ClickOutsideReact onClickOutside={handleClose}>
				<div className={classes.modal_container}>
					<button className={classes.icon_close_wrapper} onClick={handleClose}>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>Nuevo cliente</h1>
					<h2 className={classes.modal_subtitle}>
						Llena los campos para crear tu nuevo cliente a facturar
					</h2>
					{!loading && !contactSuccess.state && !factura && (
						<FacturaModalNewContactForm
							loading={loading}
							success={contactSuccess}
							foreigner={foreigner}
							error={error}
							facturaContact={facturaContact}
							setFacturaContact={setFacturaContact}
							isEmailValid={isEmailValid}
							isRFCValid={isRFCValid}
							isSubmitted={isSubmitted}
							handleSubmit={handleContactSubmit}
							handleClose={handleClose}
							handleCheckbox={handleCheckbox}
							cleanErr={cleanErr}
						/>
					)}
					{!loading && factura && (
						<FacturaModalNewFacturaForm
							loading={loading}
							success={facturaSuccess}
							foreigner={foreigner}
							error={error}
							facturaContact={facturaContact}
							setFacturaContact={setFacturaContact}
							isEmailValid={isEmailValid}
							isRFCValid={isRFCValid}
							isSubmitted={isSubmitted}
							handleSubmit={handleFacturaSubmit}
							handleClose={handleClose}
							handleCheckbox={handleCheckbox}
							cleanErr={cleanErr}
						/>
					)}
					{!loading && contactSuccess.state && (
						<div className={classes.modal_info_loading_container}>
							<p className={`mt-5 ${generals.modal_success_text}`}>
								Nuevo cliente creado con éxito.
							</p>
						</div>
					)}
					{loading && (
						<div className={classes.modal_info_loading_container}>
							<Loading loading={loading} />
						</div>
					)}

					{!loading && contactSuccess.state && (
						<>
							<button className={generals.button_blue} onClick={handleFactura}>
								Continuar solicitud
							</button>
							<button
								onClick={handleClose}
								className={`my-4 ${generals.button_white}`}
							>
								Regresar
							</button>
						</>
					)}
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default FacturaModalFormContainer;
