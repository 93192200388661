import { createSlice } from '@reduxjs/toolkit';
const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		userprofiledata: 'profile',
	},
	reducers: {
		adduserprofiledata(state, action) {
			state.userprofiledata = action.payload;
		},
	},
});
export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
