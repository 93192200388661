import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileAdminNav from '../components/layout/navbar/ProfileAdminNav';
import ProfileAdminLeft from '../components/layout/menu/ProfileAdminLeft';
import RegistroDeclaración from '../components/layout/views/Admin/DetallePrevio/RegistroDeclaración';
import PresentarDeclaración from '../components/layout/views/Admin/Presentar/PresentarDeclaración';
import Facturas from '../components/layout/views/Admin/Facturas/Facturas';
import { profileActions } from '../store/profile';
import Usuarios from '../components/layout/views/Admin/Usuarios/Usuarios';
import generals from '../css/Generals.module.css';
import Footer from '../components/layout/footer/Footer';
import ProfileAdmin from '../components/layout/views/Admin/Dashboard/ProfileAdmin';

const ProfileAdminPage = () => {
	const dispatch = useDispatch();
	const { userprofiledata } = useSelector((state) => state.profile);

	if (!userprofiledata) {
		dispatch(profileActions.adduserprofiledata('profile'));
	}

	return (
		<div>
			<ProfileAdminNav />
			<div className='container pt-[100px]'>
				<div className={generals.page_sidemenu}>
					<ProfileAdminLeft />
				</div>
				<div className={generals.page_main_container}>
					{userprofiledata === 'profile' && <ProfileAdmin />}
					{userprofiledata === 'Declaración' && <RegistroDeclaración />}
					{userprofiledata === 'Presentar' && <PresentarDeclaración />}
					{userprofiledata === 'Facturas' && <Facturas />}
					{userprofiledata === 'Usuarios' && <Usuarios />}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ProfileAdminPage;
