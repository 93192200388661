import React from 'react';
import classes from '../css/DetailItem.module.css';

const DetailHeaderFacturas = () => {
	return (
		<div className={classes.detail_header_wrapper}>
			<h2 className={classes.detail_header_element}>Cliente</h2>
			<h2 className={classes.detail_header_element}>RFC Cliente</h2>
			<h2 className={classes.detail_header_element}>Total</h2>
			<h2 className={classes.detail_header_element}>Solicitud</h2>
			<h2 className={classes.detail_header_element}>Estatus</h2>
		</div>
	);
};

export default DetailHeaderFacturas;
