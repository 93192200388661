import React, { useEffect, useState } from 'react';
import classes from '../css/DeclaracionesModal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import generals from '../../../../../../css/Generals.module.css';
import { useSelector } from 'react-redux';
import {
	roundToTwoDecimals,
	capitalizeEveryWord,
} from '../../../../../../utils/format';
import $ from 'jquery';
import { partialUpdateClientStatus } from '../../../../../api/clientStatus';
import Loading from '../../../../../common/Loading';

const DeclaracionesModalClientStatus = ({
	clientStatus,
	setModalClientStatus,
	setRefetch,
}) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [message, setMessage] = useState({ state: false, msg: '' });
	const [loading, setLoading] = useState(false);

	let currentClientStatus = [
		{
			label: 'Estatus',
			value: capitalizeEveryWord(clientStatus.estatus) || 'Sin estatus',
		},
		{
			label: 'Ingresos ISR',
			value: `$${roundToTwoDecimals(clientStatus.Ingresos_ISR)}` || '$0.00',
		},
		{
			label: 'Gastos ISR',
			value: `$${roundToTwoDecimals(clientStatus.gastos_ISR)}` || '$0.00',
		},
		{
			label: 'Utilidad ISR',
			value: `$${roundToTwoDecimals(clientStatus.utilidad_ISR)}` || '$0.00',
		},
		{
			label: 'Porcentaje ISR',
			value: `${roundToTwoDecimals(clientStatus.porcentaje_ISR)}%` || '0.0%',
		},
		{
			label: 'Retención ISR',
			value: `$${roundToTwoDecimals(clientStatus.retencion_ISR)}` || '$0.00',
		},
		{
			label: 'Por pagar IVA',
			value: `$${roundToTwoDecimals(clientStatus.porPagar_IVA)}` || '$0.00',
		},
		{
			label: 'Acreditable IVA',
			value: `$${roundToTwoDecimals(clientStatus.acreditable_IVA)}` || '$0.00',
		},
		{
			label: 'Retención IVA',
			value: `$${roundToTwoDecimals(clientStatus.retencion_IVA)}` || '$0.00',
		},
		{
			label: 'Saldo a favor IVA',
			value: `$${roundToTwoDecimals(clientStatus.saldoFavor_IVA)}` || '$0.00',
		},
		{ label: 'Fecha límite', value: clientStatus.fechalimite || '-' },
		{
			label: 'Total',
			value: `$${roundToTwoDecimals(clientStatus.total)}` || '$0.00',
		},
	];

	const approveClientStatus = async () => {
		try {
			setLoading(true);
			const response = await partialUpdateClientStatus(TOKEN, clientStatus.id);
			if (response.status === 200) {
				setLoading(false);
				setRefetch((prevState) => !prevState);
				setMessage({
					state: true,
					msg: 'El detalle previo ha sido aprobado con éxito.',
				});
			} else {
				setLoading(false);
				setMessage({
					state: true,
					msg: 'Lo sentimos, parece ser que ocurrió un error.',
				});
			}
		} catch (error) {
			console.error(error);
			setLoading(false);
			setMessage({
				state: true,
				msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
			});
		}
	};

	const handleClose = () => {
		const dialogOpen = document.querySelectorAll('div[role="dialog"]');
		const presentationOpen = document.querySelectorAll('[role="presentation"]');
		if (dialogOpen.length <= 0 && presentationOpen.length <= 0) {
			$('body').css('overflow', '');
			setModalClientStatus(false);
		}
	};

	return (
		<div className={classes.modal_overlay}>
			<ClickOutsideReact onClickOutside={handleClose}>
				<div className={classes.modal_container}>
					<button className={classes.icon_close_wrapper} onClick={handleClose}>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>Detalle previo de declaración</h1>
					<h2 className={classes.modal_subtitle}>
						{clientStatus.mesRegistro + ' ' + clientStatus['añoRegistro']}
					</h2>
					<div className={classes.modal_info_container}>
						{!loading && !message.state && currentClientStatus?.length > 0 && (
							<div className={classes.modal_info_element}>
								{currentClientStatus.map((x) => (
									<div
										key={x.label}
										className={classes.modal_info_user_nohover}
									>
										<div className={classes.modal_info_element_title}>
											<h1>{x.label}</h1>
										</div>

										<div className='flex gap-6'>
											<h1 className={classes.modal_info_element_subtitle}>
												{x.value}
											</h1>
										</div>
									</div>
								))}
							</div>
						)}
						{loading && !message.state && <Loading loading={loading} />}
						{!loading && message.state && (
							<div className='h-[300px] flex flex-col gap-2 justify-center items-center'>
								<h1 className={generals.modal_success_text}>{message.msg}</h1>
								<h2 className={`mb-5 ${generals.modal_success_subtitle}`}>
									{message.subtext}
								</h2>
							</div>
						)}
					</div>
					{!loading &&
						!message.state &&
						clientStatus.estatus === 'pendiente' && (
							<div className='flex flex-col gap-2'>
								<button
									onClick={approveClientStatus}
									className={`${generals.button_blue}`}
								>
									Aprobar declaración
								</button>
							</div>
						)}
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default DeclaracionesModalClientStatus;
