import React, { useState } from 'react';
import classes from './Información.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { clientStatusActions } from '../../../../../store/clientStatus';
import checked from '../../../../../assets/images/checked.png';
import { Tooltip } from 'react-tooltip';

export const InfoItem = (props) => {
	// Value State
	const clientDataState = useSelector((state) => state.clientStatus);
	const clientDataValue = useSelector(
		(state) => state.clientStatus[props?.fieldUserValue]
	);
	const [currentValue, setCurrentValue] = useState(clientDataValue);

	//Regex
	const regexPhone = /^\d+$/;
	const regexRFC =
		/^([A-Z&Ñ]{3,4}\d{6}[A-V1-9][0-9A-Z]?)|(([A-Z&Ñ]{3})\d{6}[A-Z\d]{3})\b$/;
	const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	// Redux dispatch
	const dispatch = useDispatch();

	// TOKEN && USERID
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	// Error && field
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [editable, setEditable] = useState({
		state: false,
		field: '',
		clientData: '',
	});

	const { userEfirma, userCIEC, userKEYFile, userCERFile } = useSelector(
		(state) => state.clientStatus
	);
	const profileComplete = userEfirma && userCIEC && userKEYFile && userCERFile;

	const updateClientData = async (fieldName) => {
		setUpdating(true);
		let phoneErrorRegex = regexPhone.test(currentValue);
		let emailErrorRegex = regexEmail.test(currentValue);
		let RFCErrorRegex = regexRFC.test(currentValue);

		let isNewValueValid = true;

		switch (fieldName) {
			case 'rfc':
				setError(!RFCErrorRegex);
				isNewValueValid = RFCErrorRegex;
				break;
			case 'email':
				setError(!emailErrorRegex);
				isNewValueValid = emailErrorRegex;
				break;
			case 'phone':
				setError(!phoneErrorRegex);
				isNewValueValid = phoneErrorRegex;
				break;
		}

		if (isNewValueValid && TOKEN && USERID) {
			let body =
				fieldName === 'rfc'
					? {
							username: currentValue,
							[editable.field]: currentValue,
					  }
					: {
							[editable.field]: currentValue,
					  };
			try {
				const response = await fetch(
					process.env.REACT_APP_API_URL +
						'client/user/partial-update/' +
						USERID,
					{
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
							// prettier-ignore
							'Authorization': 'Bearer ' + TOKEN,
						},
						body: JSON.stringify(body),
					}
				);
				if (response.ok && response.status === 200) {
					setUpdating(false);
					setSuccess(true);
					setTimeout(() => {
						setSuccess(false);
					}, 4000);

					let profileUpdated = {
						...clientDataState,
						[props.fieldUserValue]: currentValue,
					};

					// Notify profile has been completed
					if (
						!profileComplete &&
						profileUpdated.userEfirma &&
						profileUpdated.userCIEC &&
						profileUpdated.userKEYFile &&
						profileUpdated.userCERFile
					) {
						props?.onComplete(true);
					}

					dispatch(clientStatusActions.setClientData(profileUpdated));
					setEditable({ state: false, field: '', clientData: '' });
				}
			} catch (error) {
				handleError();
			}
		} else {
			handleError();
		}
	};

	const handleError = () => {
		setCurrentValue(clientDataValue);
		setTimeout(() => {
			setError(false);
		}, 4000);
		setEditable({ state: false, field: '', clientData: '' });
	};

	const handleBlur = (fieldName, clientData) => {
		setEditable({
			state: false,
			field: fieldName,
			clientData: clientData,
		});
		if (currentValue !== clientDataValue && currentValue !== '') {
			updateClientData(fieldName);
		} else {
			setCurrentValue(clientDataValue);
			setEditable({ state: false, field: '', clientData: '' });
		}
	};

	const handleFocus = (fieldName) => {
		setError(false);
		setEditable((prevState) => ({
			...prevState,
			state: true,
			field: fieldName,
		}));
	};

	const handleChange = (e) => {
		switch (props?.fieldValue) {
			case 'rfc':
				setCurrentValue(e.target.value.toUpperCase().trim());
				break;
			case 'name':
				setCurrentValue(e.target.value);
				break;
			default:
				setCurrentValue(e.target.value.trim());
		}
	};

	const tooltipContent = (fieldName) => {
		switch (fieldName) {
			case 'clave_ciec':
				return "<p>Clave de Identificación Electrónica Confidencial (CIEC), es tu contraseña del SAT. Ingresa <a style='text-decoration: underline' href='https://www.sat.gob.mx/tramites/38517/generacion-y-actualizacion-de-contrasena-para-persona-fisica' target='_blank'>aquí</a> para obtenerla.</p>";
			case 'key':
				return "<p>La contraseña o clave privada de la e.firma (firma electrónica) es una clave secreta definida por el contribuyente. Ingresa <a style='text-decoration: underline' href='https://www.sat.gob.mx/tramites/38517/generacion-y-actualizacion-de-contrasena-para-persona-fisica' target='_blank'>aquí</a> para obtenerla.</p>";
			case 'rfc':
				return "<p> El RFC o Registro Federal de Contribuyentes es una clave única de registro que sirve para identificar a toda aquella persona que realiza una actividad económica y deba contribuir con el gasto público. Ingresa <a style='text-decoration: underline' href=https://www.sat.gob.mx/tramites/82714/realiza-tu-inscripcion-en-el-rfc-persona-fisica#:~:text=Se%20presenta%20en%20cualquier%20oficina,M%C3%B3vil%20o%20Portal%20GOB.MX.&text=Esta%20solicitud%20la%20presenta%20el%20propio%20contribuyente%20con%20mayor%C3%ADa%20de%20edad%20cumplida.&text=Puedes%20iniciarlo%20por%20internet%20y%20concluirlo%20en%20cualquier%20oficina%20del%20SAT'  target='_blank'>aquí</a> para obtenerlo.</p>.";
		}
	};
	const editableField = (fieldName) => {
		if (editable.field === fieldName) {
			return `${classes.smooth_transition} ${classes.input_editable} md:text-[24px] md:leading-[29px] text-[14px] leading-[17px] border-2`;
		} else if (!clientDataValue) {
			return `${classes.smooth_transition} ${classes.input_editable_empty} md:text-[24px] md:leading-[29px] text-[14px] leading-[17px] border-2 border-white`;
		} else {
			return `${classes.smooth_transition} ${classes.input_editable} md:text-[24px] md:leading-[29px] text-[14px] leading-[17px] border-2 border-white`;
		}
	};

	return (
		<div className='py-[15px]'>
			<p className={'flex gap-[10px] items-center md:text-[20px] text-[14px]'}>
				{props?.tooltip && (
					<div className={classes.icon_container}>
						<span
							title='Da clic para saber más'
							className={classes.question_icon}
							data-tooltip-id='my-tooltip-inline'
							data-tooltip-html={tooltipContent(props?.fieldValue)}
						></span>
						<Tooltip
							style={{ maxWidth: '200px', fontSize: '1rem', zIndex: 1000 }}
							id='my-tooltip-inline'
							place='top'
							effect='solid'
							openOnClick={true}
							clickable={true}
						/>
					</div>
				)}
				{props?.title}{' '}
			</p>
			<div className='flex items-center gap-2'>
				<input
					title='Da clic para editar.'
					type={props?.type}
					className={editableField(props?.fieldValue)}
					value={currentValue}
					onChange={(e) => handleChange(e)}
					onFocus={() => handleFocus(props?.fieldValue)}
					onBlur={() => handleBlur(props?.fieldValue, props?.fieldUserValue)}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							event.target.blur();
							handleBlur(props?.fieldValue, props?.fieldUserValue);
						}
					}}
				/>
			</div>
			{error && (
				<p className={classes.input_error}>{props?.title + ' inválido.'}</p>
			)}
			{success && (
				<p className={`flex items-center gap-[10px] ${classes.input_success}`}>
					<img
						src={checked}
						alt='Successful operation'
						className='w-[15px]'
					></img>{' '}
					{props?.title + ' actualizado.'}
				</p>
			)}
			{updating && <p className={classes.input_success}>{'Actualizando...'}</p>}
		</div>
	);
};

export default InfoItem;
