import React, { useEffect, useState } from 'react';
import { getUserById } from '../../../../api/users';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { getAllNotifications } from '../../../../api/notifications';
import Activity from './userInfo/Activity';
import UserInfo from './userInfo/UserInfo';
import Plan from './userInfo/Plan';
import classes from './css/Users.module.css';
import Loading from '../../../../common/Loading';
import DetallesPrevios from './userInfo/DetallesPrevios';
import Declaraciones from './userInfo/Declaraciones';
import Facturas from './userInfo/Facturas';

const User = ({ users, selectedUserFromGenerals }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [selectedUser, setSelectedUser] = useState(
		selectedUserFromGenerals || ''
	);
	const [notifications, setNotifications] = useState([]);
	const [currentPage, setCurrentPage] = useState('Info');
	const [loading, setLoading] = useState(false);
	let userOptions = users.map((x) => x.rfc).filter((rfc) => rfc !== null);

	useEffect(() => {
		if (selectedUserFromGenerals) {
			setSelectedUser(selectedUserFromGenerals);
			handleSubmit(selectedUserFromGenerals);
		}
	}, [selectedUserFromGenerals]);

	const handleSubmit = (user) => {
		const fetchUser = async () => {
			setLoading(true);
			try {
				const notifications = await getAllNotifications(
					TOKEN,
					'admin',
					user.id
				);
				setNotifications(notifications);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		};
		fetchUser();
	};

	const handleChange = (newValue) => {
		let selectedUserById = users.find((x) => newValue === x.rfc);
		setSelectedUser(selectedUserById);
		handleSubmit(selectedUserById);
	};

	return (
		<>
			<div className='py-4 flex flex-col flex-start  items-start' key='userRFC'>
				<h2 className={classes.users_subtitle}>Filtrar por usuario</h2>{' '}
				{userOptions && (
					<Autocomplete
						disablePortal
						options={userOptions}
						value={selectedUser.rfc}
						sx={{ width: 400 }}
						renderInput={(params) => (
							<TextField
								className={`${classes.users_input_box} text-[20px] w-full py-2 px-2`}
								{...params}
								label='Seleccionar RFC de usuario'
							/>
						)}
						onChange={(e, newValue) => handleChange(newValue)}
						blurOnSelect={true}
					/>
				)}
			</div>
			{selectedUser && (
				<div className={classes.users_menu}>
					<button
						className={
							currentPage === 'Info'
								? `${classes.selectedCategory} ${classes.category}`
								: `${classes.category}`
						}
						onClick={() => setCurrentPage('Info')}
					>
						<span
							className={
								selectedUser.pendingSignIn
									? `${classes.users_status} ${classes.users_pending}`
									: `${classes.users_status}`
							}
						/>
						<h2>Datos</h2>
					</button>

					<button
						className={
							currentPage === 'Activity'
								? `${classes.selectedCategory} ${classes.category}`
								: `${classes.category}`
						}
						onClick={() => setCurrentPage('Activity')}
					>
						Actividad
					</button>

					<button
						className={
							currentPage === 'Plan'
								? `${classes.selectedCategory} ${classes.category}`
								: `${classes.category}`
						}
						onClick={() => setCurrentPage('Plan')}
					>
						<span
							className={
								selectedUser.pendingPlan
									? `${classes.users_status} ${classes.users_pending}`
									: `${classes.users_status}`
							}
						/>
						<h2>Plan</h2>
					</button>

					<button
						className={
							currentPage === 'Detalles previos'
								? `${classes.selectedCategory} ${classes.category}`
								: `${classes.category}`
						}
						onClick={() => setCurrentPage('Detalles previos')}
					>
						Detalles
					</button>

					<button
						className={
							currentPage === 'Declaraciones'
								? `${classes.selectedCategory} ${classes.category}`
								: `${classes.category}`
						}
						onClick={() => setCurrentPage('Declaraciones')}
					>
						Declaraciones
					</button>

					<button
						className={
							currentPage === 'Facturas'
								? `${classes.selectedCategory} ${classes.category}`
								: `${classes.category}`
						}
						onClick={() => setCurrentPage('Facturas')}
					>
						Facturas
					</button>
				</div>
			)}
			{loading && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
			{selectedUser && notifications && !loading && (
				<>
					{currentPage === 'Activity' && (
						<Activity notifications={notifications} />
					)}
					{currentPage === 'Info' && <UserInfo userData={selectedUser} />}
					{currentPage === 'Plan' && (
						<Plan userData={selectedUser} notifications={notifications} />
					)}
					{currentPage === 'Detalles previos' && (
						<DetallesPrevios userData={selectedUser} />
					)}
					{currentPage === 'Declaraciones' && (
						<Declaraciones userData={selectedUser} />
					)}
					{currentPage === 'Facturas' && <Facturas userData={selectedUser} />}
				</>
			)}
		</>
	);
};

export default User;
