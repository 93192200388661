import React from 'react';
import classes from '../css/DetailItem.module.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { extractDate, roundToTwoDecimals } from '../utils/utils';
import { getUserFiles } from '../../../../../api/userfiles';
import { useSelector } from 'react-redux';
import { formatMexicoTz } from '../../../../../../utils/format';

const DetailFacturas = ({ userData, currentUser }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);

	const retrieveFiles = async () => {
		if (userData.estatus === 'emitida') {
			const value1 =
				userData.userRFC + '_FACTURA_' + userData.folioFiscal + '.pdf';
			const value2 =
				userData.userRFC + '_FACTURA_' + userData.folioFiscal + '.xml';
			const userFile1 = await getUserFiles(TOKEN, value1);
			const userFile2 = await getUserFiles(TOKEN, value2);
			window.open(userFile1.data);
			window.open(userFile2.data);
		} else {
			const value1 =
				userData.userRFC + '_FACTURACANCELADA_' + userData.folioFiscal + '.pdf';
			const userFile1 = await getUserFiles(TOKEN, value1);
			window.open(userFile1.data);
		}
	};

	const getStatusClassName = (status) => {
		switch (status) {
			case 'emitida': {
				return `${classes.detail_status}`;
			}
			case 'cancelada': {
				return `${classes.detail_status} ${classes.pending}`;
			}
			case 'solicitada': {
				return `${classes.detail_status} ${classes.approved}`;
			}
			default: {
				return `${classes.detail_status} ${classes.no_status}`;
			}
		}
	};

	return (
		<div className={classes.detail_wrapper}>
			<h2 className={classes.detail_element}>{userData.nombrecliente}</h2>
			<h2 className={classes.detail_element}>{userData.rfccliente}</h2>
			<h2 className={classes.detail_element}>
				${roundToTwoDecimals(userData.total)}
			</h2>
			<h2 className={classes.detail_element}>
				{formatMexicoTz(userData.createdAt) || '-'}
			</h2>
			<div className={classes.detail_element_download}>
				<h2 className={getStatusClassName(userData.estatus)}>
					{userData.estatus || 'sin estatus'}
				</h2>
			</div>

			{userData.estatus === 'emitida' ||
			userData.estatus === 'cancelación emitida' ? (
				<button
					onClick={retrieveFiles}
					className={classes.detail_element_seemore}
				>
					<FileDownloadOutlinedIcon sx={{ fontSize: 25 }} />
				</button>
			) : (
				<button
					onClick={retrieveFiles}
					className={classes.detail_element_seemore}
					disabled
				>
					<FileDownloadOutlinedIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
				</button>
			)}
		</div>
	);
};

export default DetailFacturas;
