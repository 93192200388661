import React from 'react';
import styles from '../css/Users.module.css';
import Documento from '../../../../../../assets/images/navbar/hamberger.png';
import Usuario from '../../../../../../assets/images/user.png';
import Wallet from '../../../../../../assets/images/wallet.png';
import { formatMexicoTz } from '../../../../../../utils/format';
const Activity = ({ notifications }) => {
	const getIcon = (type) => {
		switch (type) {
			case 'Documento':
				return Documento;
			case 'Usuario':
				return Usuario;
			case 'Stripe':
				return Wallet;
			default:
				return Usuario;
		}
	};
	return (
		<div>
			{notifications.map((notification) => {
				return (
					<div
						key={notification.id}
						className={
							notification.viewed
								? `mx-[15px] mb-[7px] ${styles.notifications_item_viewed}`
								: `mx-[15px] mb-[7px] ${styles.notifications_item}`
						}
					>
						<div className={`${styles.notifications_item_icon_wrapper}`}>
							<img
								className={`${styles.notifications_item_icon_img}`}
								src={getIcon(notification.type)}
								alt=''
							/>
						</div>
						<div className={`${styles.notifications_item_body_wrapper}`}>
							<div className={`${styles.notifications_item_body_header}`}>
								<p className={`${styles.notifications_item_body_title}`}>
									{notification.title}
								</p>
								<p className={`${styles.notifications_item_body_date}`}>
									{formatMexicoTz(notification.createdAt)}
								</p>
							</div>
							<p className={`${styles.notifications_item_body_text}`}>
								{notification.description}
							</p>
						</div>
						{notification.viewed && (
							<p className={`${styles.notifications_item_viewed_text}`}>
								Leída
							</p>
						)}
					</div>
				);
			})}
			{notifications.length === 0 && (
				<div className={`${styles.notifications_empty_wrapper}`}>
					<p className={`${styles.notifications_empty_text}`}>
						No hay actividad registrada de este usuario.
					</p>
				</div>
			)}
		</div>
	);
};

export default Activity;
