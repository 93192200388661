import moment from 'moment-timezone';

export const formatMexicoTz = (inputDate, withTime = false) => {
	if (inputDate) {
		const date = moment.utc(inputDate).tz('America/Mexico_City');

		const day = date.date().toString().padStart(2, '0');
		const month = (date.month() + 1).toString().padStart(2, '0');
		const year = date.year();

		if (withTime) {
			const time = date.format('h:mm A');
			return `${day}/${month}/${year} ${time}`;
		}

		return `${day}/${month}/${year}`;
	} else {
		return '';
	}
};

export const formatTimeStamp = (timestamp) => {
	if (timestamp) {
		let date = new Date(timestamp * 1000);
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		let formattedDate =
			('0' + day).slice(-2) + '/' + ('0' + month).slice(-2) + '/' + year;

		return formattedDate;
	} else {
		return 'N/A';
	}
};

export const roundToTwoDecimals = (num) => {
	let formattedNumber = '0.00';
	if (num) {
		let roundedNumber = parseFloat(num).toFixed(2);

		formattedNumber = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(roundedNumber);
	}

	return formattedNumber;
};

export const capitalizeEveryWord = (str) => {
	return str.replace(/\b[\wáéíóúü]+\b/g, function (word) {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
};

export const cropWord = (word, maxLength) => {
	if (word.length > maxLength) {
		// Crop the word to maxLength characters and add "..."
		return word.substring(0, maxLength) + '...';
	}
	return word;
};

export const removeCommas = (inputString) => {
	if (
		inputString &&
		typeof inputString === 'string' &&
		inputString.trim() !== ''
	) {
		return inputString.replace(/,/g, '');
	} else {
		return inputString;
	}
};
