import React, { useState } from 'react';
import styles from './Notifications.module.css';
import Documento from '../../../assets/images/navbar/hamberger.png';
import timeoutIcon from '../../../assets/images/dashboard/clock.png';
import walletIcon from '../../../assets/images/dashboard/wallet.png';
import userIcon from '../../../assets/images/dashboard/user.png';
import { formatMexicoTz } from '../../../utils/format';

const NotificationItem = ({ notification, onSetAsViewed }) => {
	const [viewed, setViewed] = useState(notification.viewed);

	const getIcon = (type) => {
		switch (type) {
			case 'Documento':
				return Documento;
			case 'Usuario':
				return userIcon;
			case 'Stripe':
				return walletIcon;
			case 'Tienes una declaración pendiente por aprobar.':
				return userIcon;
			case 'Declaración previa por pagar':
			case 'Tienes un pago de impuestos por realizar':
				return walletIcon;
			case 'Hoy vence tu línea de captura':
			case 'Mañana vence tu línea de captura':
				return timeoutIcon;
			default:
				return userIcon;
		}
	};
	return (
		<div
			key={notification.id}
			className={
				viewed
					? `mx-[15px] mb-[7px] ${styles.notifications_item_viewed}`
					: `mx-[15px] mb-[7px] ${styles.notifications_item}`
			}
			onClick={() => {
				onSetAsViewed(notification.id);
				setViewed(true);
			}}
		>
			<div className={`${styles.notifications_item_icon_wrapper}`}>
				<img
					className={`${styles.notifications_item_icon_img}`}
					src={getIcon(
						notification.recipient === 'admin'
							? notification.type
							: notification.title
					)}
					alt=''
				/>
			</div>
			<div className={`${styles.notifications_item_body_wrapper}`}>
				<div className={`${styles.notifications_item_body_header}`}>
					<p className={`${styles.notifications_item_body_title}`}>
						{notification.title}
					</p>
					<p className={`${styles.notifications_item_body_date}`}>
						{formatMexicoTz(notification.createdAt)}
					</p>
				</div>
				<p className={`${styles.notifications_item_body_text}`}>
					{notification.description}
				</p>
			</div>
			{notification.viewed && (
				<p className={`${styles.notifications_item_viewed_text}`}>Leída</p>
			)}
		</div>
	);
};

export default NotificationItem;
