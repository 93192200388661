import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Change this to 'redux-persist/lib/storage' if you want to use localStorage

import authSlice from './auth';
import errorSlice from './errors';
import clientSlice from './clientStatus';
import profileSlice from './profile';
import satDeclarationSlice from './satDeclaration';
import facturaSlice from './factura';

// Combine all reducers
const rootReducer = combineReducers({
	auth: authSlice,
	errors: errorSlice,
	clientStatus: clientSlice,
	profile: profileSlice,
	satDeclaration: satDeclarationSlice,
	factura: facturaSlice,
});

// Configure the persistence for the root reducer
const persistConfig = {
	key: 'root', // This is the key under which your state will be saved in the storage
	storage, // This determines which storage to use, like localStorage or AsyncStorage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
