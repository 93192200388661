import React from 'react';
import { useNavigate } from 'react-router-dom';
import img from '../../../assets/images/midia.png';

const Mejor = () => {
	const navigate = useNavigate();
	const submit = () => {
		navigate('/signup');
	};
	return (
		<div className='py-[10px] md:pb-[70px] pb-[115px]'>
			<div className='container'>
				<div className='flex items-center flex-col lg:flex-row justify-between'>
					<div className='flex-1'>
						<h2 className='xl:text-[64px] lg:text-[50px] text-[32px] leading-[37px] md:leading-[81px] font-bold'>
							La mejor solución para
							<span className='text-[#4285F4]'> Freelancers </span>, asociados
							de <span className='text-[#4285F4]'>Uber, Rappi</span>, y más
						</h2>
						<button
							onClick={submit}
							className={`py-4 mt-9 px-7 text-[24px] rounded-lg text-white font-medium  bg-[#4285F4]`}
						>
							Regístrate
						</button>
					</div>
					<div className='flex-1'>
						<div className='xl:-mt-[200px] lg:-mt-[100px] '>
							<img src={img} alt='' className='' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Mejor;
