import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../css/Generals.module.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import classes from './css/Facturas.module.css';
import FacturaContainer from './components/FacturaContainer';
import {
	getAllFacturaById,
	getAllFacturaContactsById,
} from '../../../../api/facturas';
import FacturaModal from './components/FacturaModal';
import FacturaModalInfo from './components/FacturaModalInfo';
import FacturaModalClone from './components/FacturaModalClone';
import FacturaFilters from './components/FacturaFilters';
import FacturaDashboard from './components/FacturaDashboard';
import dayjs from 'dayjs';
import $ from 'jquery';

const Facturas = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState({ state: false, msg: '' });
	const [facturas, setFacturas] = useState([]);
	const [filteredFacturas, setFilteredFacturas] = useState([]);
	const [clientRFCs, setClientRFCs] = useState([]);
	const [facturaContacts, setFacturaContacts] = useState([]);
	const [selectedContact, setSelectedContact] = useState('');
	const [modal, setModal] = useState(false);
	const [modalInfo, setModalInfo] = useState({ state: false, factura: '' });
	const [modalClone, setModalClone] = useState({ state: false, factura: '' });
	const [refetch, setRefetch] = useState(false);

	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	let cibercontaInitialDate = dayjs('2020-01-01');
	let cibercontaFinalDate = dayjs().endOf('day');

	const [initialDate, setInitialDate] = useState(cibercontaInitialDate);
	const [finalDate, setFinalDate] = useState(cibercontaFinalDate);
	const [currentClientRFC, setCurrentClientRFC] = useState('Todos');
	const [currentStatus, setCurrentStatus] = useState('');

	useEffect(() => {
		const fetchUserFacturas = async () => {
			try {
				const response = await getAllFacturaById(TOKEN, USERID);
				if (response.status === 200) {
					const result = await response.json();
					const resultData = result?.data?.data;
					setFacturas(resultData);
					setFilteredFacturas(resultData);
					let facturaClients = [
						...new Set(resultData.map((x) => x.rfccliente)),
					];
					setClientRFCs(facturaClients);
					setLoading(false);
				} else {
					setLoading(false);
					setError({
						state: true,
						msg: 'Lo sentimos, parece ser que ocurrió un error.',
					});
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
				setError({
					state: true,
					msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		};
		const fetchUserContacts = async () => {
			try {
				const response = await getAllFacturaContactsById(TOKEN, USERID);
				if (response.status === 200) {
					const result = await response.json();
					const resultData = result?.data?.data;
					setFacturaContacts(resultData);
					setLoading(false);
				} else {
					setLoading(false);
					setError({
						state: true,
						msg: 'Lo sentimos, parece ser que ocurrió un error.',
					});
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
				setError({
					state: true,
					msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		};
		fetchUserFacturas();
		fetchUserContacts();
	}, [refetch]);

	const filterFacturas = (
		status,
		clientRFC,
		initialDateParam,
		finalDateParam
	) => {
		let filteredFacs = [...facturas];
		// Status
		if (status && status !== 'Todos') {
			filteredFacs = filteredFacs.filter((x) => x.estatus === status);
		} else if (
			status !== 'Todos' &&
			currentStatus &&
			currentStatus !== 'Todos'
		) {
			filteredFacs = filteredFacs.filter((x) => x.estatus === currentStatus);
		}
		// Client
		if (clientRFC && clientRFC !== 'Todos') {
			filteredFacs = filteredFacs.filter((x) => x.rfccliente === clientRFC);
		} else if (
			clientRFC !== 'Todos' &&
			currentClientRFC &&
			currentClientRFC !== 'Todos'
		) {
			filteredFacs = filteredFacs.filter(
				(x) => x.rfccliente === currentClientRFC
			);
		}
		// Dates
		const startDate = dayjs(initialDateParam || initialDate).toDate();
		const endDate = dayjs(finalDateParam || finalDate).toDate();

		filteredFacs = filteredFacs.filter((x) => {
			const facDate = new Date(x.updatedAt);
			return facDate > startDate && facDate < endDate;
		});

		setFilteredFacturas(filteredFacs);
	};

	return (
		<div className='relative'>
			<div className='w-full flex gap-[20px] items-center'>
				<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
					Facturas
				</h2>
				<div className={classes.hero_button_wrapper}>
					<button
						onClick={() => {
							$('body').css('overflow', 'hidden');
							setModal(true);
						}}
						className={classes.hero_button}
					>
						<h1 className={classes.hero_button_text}>Solicitar Factura</h1>
						<AddCircleIcon sx={{ fontSize: 24, color: '#4285f4' }} />
					</button>
				</div>
			</div>
			<h3 className={generals.page_subtitle}>
				Módulo para gestión de facturas
			</h3>

			<FacturaDashboard
				facturas={facturas}
				filterFacturas={filterFacturas}
				setCurrentStatus={setCurrentStatus}
			/>

			<FacturaFilters
				clientRFCs={clientRFCs}
				filterFacturas={filterFacturas}
				initialDate={initialDate}
				finalDate={finalDate}
				setInitialDate={setInitialDate}
				setFinalDate={setFinalDate}
				currentClientOption={currentClientRFC}
				setCurrentClientOption={setCurrentClientRFC}
			/>

			<FacturaContainer
				loading={loading}
				error={error}
				facturas={filteredFacturas}
				setModalInfo={setModalInfo}
				setModalClone={setModalClone}
			/>

			{modal && (
				<FacturaModal
					contacts={facturaContacts}
					setModal={setModal}
					selectedContact={selectedContact}
					setSelectedContact={setSelectedContact}
					setRefetch={setRefetch}
				/>
			)}

			{modalInfo.state && (
				<FacturaModalInfo
					setModalInfo={setModalInfo}
					modalInfo={modalInfo}
					setRefetch={setRefetch}
				/>
			)}

			{modalClone.state && (
				<FacturaModalClone
					setModalClone={setModalClone}
					modalClone={modalClone}
					setRefetch={setRefetch}
				/>
			)}
		</div>
	);
};

export default Facturas;
