import React, { useEffect, useState } from 'react';
import Wallet from '../../../../../../assets/images/wallet.png';
import styles from '../css/Users.module.css';
import {
	formatTimeStamp,
	formatMexicoTz,
} from '../../../../../../utils/format';
const Plan = ({ userData, notifications }) => {
	const [subscription, setSubscription] = useState('');
	const [anualPlan, setAnualPlan] = useState('');
	const [lastPayment, setLastPayment] = useState('');

	const stripe = require('stripe')(process.env.REACT_APP_STRIPE);

	useEffect(() => {
		const fetchCustomerData = async () => {
			const customer = await stripe.customers.retrieve(userData.stripeId, {
				expand: ['subscriptions'],
			});
			const subscriptions = customer.subscriptions.data;

			if (subscriptions.length > 0) {
				for (let item of subscriptions) {
					if (
						item.plan.product === process.env.REACT_APP_STRIPE_RESCATEFISCAL
					) {
						// RescateFiscal Anual
						setAnualPlan(item);
					} else if (
						item.plan.product === process.env.REACT_APP_STRIPE_FACILFISCAL
					) {
						// FacilFiscal
						setSubscription(item);
					}
				}
			}
			const lastPay = await notifications.find((x) =>
				x?.event?.includes('invoice.payment_succeeded_')
			);

			let lastIndex = lastPay?.event.lastIndexOf('_');
			if (lastIndex && lastIndex !== -1) {
				lastPay.amount = lastPay?.event.substring(lastIndex + 1);
				setLastPayment(lastPay);
			}
		};

		fetchCustomerData();
	}, [notifications, userData]);

	return (
		<div className={`${styles.plan_item}`}>
			<div className={`${styles.plan_item_icon_wrapper}`}>
				<img className={`${styles.plan_item_icon_img}`} src={Wallet} alt='' />
			</div>
			<div className={`${styles.plan_item_body_wrapper}`}>
				<div className={`${styles.plan_item_body_header}`}>
					<p className={`${styles.plan_item_body_title}`}>{userData.name}</p>
				</div>
				<div className={`${styles.plan_item_body_section}`}>
					<p className={`${styles.plan_item_body_text}`}>
						Suscripción: {subscription ? subscription.plan.nickname : 'N/A'}
					</p>

					{subscription && (
						<p className={`${styles.plan_item_body_pay}`}>
							Fecha de inicio: {formatTimeStamp(subscription.start_date)}
						</p>
					)}
				</div>
				<div className={`${styles.plan_item_body_section}`}>
					<p className={`${styles.plan_item_body_text}`}>
						Análisis y regularización fiscal:{' '}
						{anualPlan ? ' Contratado' : ' No contratado'}
					</p>
					{anualPlan && (
						<p className={`${styles.plan_item_body_pay}`}>
							Años contratados: {anualPlan.quantity}
						</p>
					)}
				</div>
				{lastPayment && (
					<div className={`${styles.plan_item_body_section} mt-8`}>
						<p className={`${styles.plan_item_body_pay}`}>
							Último Pago: ${lastPayment.amount}
						</p>
						<p className={`${styles.plan_item_body_pay}`}>
							Fecha de pago: {formatMexicoTz(lastPayment?.createdAt)}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default Plan;
