export const firstColumn = [
	{
		title: 'Nombre completo',
		type: 'text',
		fieldValue: 'name',
		fieldUserValue: 'userFullName',
	},
	{
		title: 'Correo electrónico',
		type: 'text',
		fieldValue: 'email',
		fieldUserValue: 'userEmail',
	},
	{
		title: 'Contraseña CIEC',
		type: 'text',
		fieldValue: 'clave_ciec',
		fieldUserValue: 'userCIEC',
		tooltip: true,
	},
];

export const secondColumn = [
	{
		title: 'Número celular',
		type: 'text',
		fieldValue: 'phone',
		fieldUserValue: 'userPhone',
	},
	{
		title: 'RFC',
		type: 'text',
		fieldValue: 'rfc',
		fieldUserValue: 'userRFC',
		tooltip: true,
	},
	{
		title: 'Contraseña de E firma',
		type: 'text',
		fieldValue: 'key',
		fieldUserValue: 'userEfirma',
		tooltip: true,
	},
];
