import React from 'react';
import Accordion from '../components/layout/home/Accordion';
import HomeNav from '../components/layout/navbar/HomeNav';
import FooterHome from '../components/layout/footer/FooterHome';

const PreguntasPage = () => {
	return (
		<div>
			<HomeNav />
			<div className='mx-[150px] pt-[120px]'>
				<h2 className='md:text-[64px] md:leading-[81px] text-[32px] mb-5 font-bold leading-[37px]'>
					Preguntas Frecuentes
				</h2>
				<p className='md:text-[24px] text-[14px] font-normal'>
					<Accordion />
				</p>
			</div>
			<FooterHome />
		</div>
	);
};

export default PreguntasPage;
