import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from '../../../assets/images/mobile-1.png';
import img2 from '../../../assets/images/mobile-2.png';
import img3 from '../../../assets/images/mobile-3.png';
import img4 from '../../../assets/images/mobile-4.png';

const Declarar = ({ projectRef }) => {
	const [show, setShow] = useState(0);
	const [scrollber, setScrollber] = useState();
	const ref1 = useRef();
	const data = [img1, img2, img3, img4];
	const navigate = useNavigate();

	const onScroll = () => {
		const winScrol = document.documentElement.scrollTop;
		const height =
			document.documentElement.scrollHeight -
			document.documentElement.clientHeight;

		const scrolled = (winScrol / height) * 100;
		setScrollber(scrolled);
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [scrollber]);

	useEffect(() => {
		if (scrollber >= 18 && scrollber <= 20) {
			setShow(1);
		} else if (scrollber >= 20 && scrollber <= 24) {
			setShow(2);
		} else if (scrollber >= 24 && scrollber <= 30) {
			setShow(3);
		} else {
			setShow(0);
		}
	}, [scrollber]);

	return (
		<div className='lg:py-[100px] py-[35px]' ref={projectRef}>
			<div className='container'>
				<div>
					<div className='headding'>
						<h2 className='md:text-[64px] text-[32px] leading-[37px] md:leading-[81px] font-bold'>
							<span className='text-[#4285F4]'>Declarar </span> nunca había sido
							tan <span className='text-[#4285F4]'>sencillo</span>, solo sigue{' '}
							<span className='text-[#4285F4]'>3 simples pasos</span>:
						</h2>
					</div>
					<div className='md:flex hidden items-start justify-between gap-10 mt-8'>
						<div className='flex-[2]'>
							<div className='h-[638px]'>
								<img src={data[show]} alt='' />
							</div>
						</div>
						<div className='flex-[3] flex items-start lg:gap-[70px] gap-[20px]'>
							<div className='w-[10px] h-[600px] bg-[#CAD8EF] rounded-md'>
								<div
									className={`w-full bg-[#4285F4] rounded-md ${
										show === 0 && 'h-[150px]'
									} ${show === 1 && 'h-[300px]'} ${show === 2 && 'h-[450px]'} ${
										show === 3 && 'h-[600px]'
									}`}
								></div>
							</div>
							<div>
								<div
									className='py-[40px] flex items-start gap-[30px]'
									ref={ref1}
								>
									<span className='text-[64px] leading-[50px] font-bold text-[#4285F4]'>
										1
									</span>
									<h3 className='text-[24px] leading-[35px] font-normal text-[#000]'>
										Regístrate y contrata <br />
										nuestro servicio
									</h3>
								</div>
								<div className='py-[40px] flex items-start gap-[30px]'>
									<span
										className={`text-[64px] leading-[50px] font-bold ${
											show === 1 || show === 2 || show === 3
												? 'text-[#4285F4]'
												: 'text-[#DCE5F5]'
										}`}
									>
										2
									</span>
									<h3
										className={`text-[24px] leading-[35px] font-normal ${
											show === 1 || show === 2 || show === 3
												? 'text-[#000]'
												: 'text-[#DCE5F5]'
										}`}
									>
										Factura todos tus gastos a <br /> donde vayas
									</h3>
								</div>
								<div className='py-[40px] flex items-start gap-[30px]'>
									<span
										className={`text-[64px] leading-[50px] font-bold ${
											show === 2 || show === 3
												? 'text-[#4285F4]'
												: 'text-[#DCE5F5]'
										}`}
									>
										3
									</span>
									<h3
										className={`text-[24px] leading-[35px] font-normal ${
											show === 2 || show === 3
												? 'text-[#000]'
												: 'text-[#DCE5F5]'
										}`}
									>
										Aprueba tu declaración en la app
									</h3>
								</div>
								<div className='py-[40px] flex items-start gap-[30px]'>
									<div></div>
									<button
										onClick={() => navigate('signup')}
										className={`py-4 px-7 text-[24px] rounded-lg text-white font-medium ${
											show === 3 ? 'bg-[#4285F4]' : 'bg-[#DCE5F5]'
										}`}
									>
										Regístrate
									</button>
								</div>
							</div>
						</div>
					</div>

					{/* mobile Devises */}

					<div className='md:hidden mt-8  sm:w-auto  m-auto'>
						<div className='flex items-center gap-10 overflow-x-scroll'>
							<div className='px-10'>
								<div className='flex items-start gap-[10px] mb-3'>
									<span className='text-[32px] leading-[20px] font-bold text-[#4285F4]'>
										1
									</span>
									<h3 className='text-[14px] leading-[17px] font-normal text-[#000]'>
										Regístrate y contrata <br />
										nuestro servicio
									</h3>
								</div>
								<div className='h-[436px] w-[230px]'>
									<img src={img1} alt='' className='w-full h-full' />
								</div>
							</div>
							<div className='px-10'>
								<div className='flex items-start gap-[10px] mb-3'>
									<span className='text-[32px] leading-[20px] font-bold text-[#4285F4]'>
										2
									</span>
									<h3 className='text-[14px] leading-[17px] font-normal text-[#000]'>
										Factura todos tus gastos a <br /> donde vayas
									</h3>
								</div>
								<div className='h-[436px] w-[230px]'>
									<img src={img2} alt='' className='w-full h-full' />
								</div>
							</div>
							<div className='px-10'>
								<div className='flex items-start gap-[10px] mb-3'>
									<span className='text-[32px] leading-[20px] font-bold text-[#4285F4]'>
										3
									</span>
									<h3 className='text-[14px] leading-[17px] font-normal text-[#000]'>
										Aprueba tu declaración en la app
									</h3>
								</div>
								<div className='h-[436px] w-[230px]'>
									<img src={img3} alt='' className='w-full h-full' />
								</div>
							</div>
							<div className=' flex flex-col justify-center items-center gap-2 px-10'>
								<div className='flex items-start gap-[10px]'>
									<div></div>
									<button
										onClick={() => navigate('signup')}
										className={`py-2 px-3 text-[14px] rounded-lg text-white font-medium bg-[#4285F4]`}
									>
										Regístrate
									</button>
								</div>
								<div className='h-[436px] w-[230px]'>
									<img src={img4} alt='' className='w-full h-full' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Declarar;
