import React, { useState, useEffect } from 'react';
import classes from '../css/Facturas.module.css';
import { useSelector } from 'react-redux';
import DetailHeaderPendings from './DetailHeaderPendings';
import DetailPendings from './DetailPendings';
import Loading from '../../../../../common/Loading';

const Pendings = ({
	setMoreInfo,
	totalPendings,
	totalPresented,
	error,
	loading,
}) => {
	const [currentSortingCategory, setCurrentSortingCategory] = useState('');
	const [facturas, setFacturas] = useState([]);
	const { filterUserRFC, pending } = useSelector((state) => state.factura);

	useEffect(() => {
		if (pending) {
			if (filterUserRFC && filterUserRFC !== 'Todos los usuarios') {
				let filteredByUserRFC = totalPendings.filter(
					(x) => x.userRFC === filterUserRFC
				);
				setFacturas(filteredByUserRFC);
			} else {
				setFacturas(totalPendings);
			}
		} else if (filterUserRFC && filterUserRFC !== 'Todos los usuarios') {
			let filteredByUserRFC = totalPresented.filter(
				(x) => x.userRFC === filterUserRFC
			);
			setFacturas(filteredByUserRFC);
		} else {
			setFacturas(totalPresented);
		}
	}, [totalPendings, totalPresented, pending]);

	const sortResults = (sortingOptions) => {
		switch (sortingOptions.category) {
			case 'Tipo': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? facturas.slice().sort((a, b) => b.type.localeCompare(a.type))
						: facturas.slice().sort((a, b) => a.type.localeCompare(b.type));

				setFacturas(sortedArray);
				break;
			}
			case 'Fecha': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? facturas
								.slice()
								.sort(
									(a, b) =>
										new Date(a.fechaSolicitud) - new Date(b.fechaSolicitud)
								)
						: facturas
								.slice()
								.sort(
									(a, b) =>
										new Date(b.fechaSolicitud) - new Date(a.fechaSolicitud)
								);

				setFacturas(sortedArray);
				break;
			}
			case 'RFC': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? facturas
								.slice()
								.sort((a, b) => b.userRFC.localeCompare(a.userRFC))
						: facturas
								.slice()
								.sort((a, b) => a.userRFC.localeCompare(b.userRFC));

				setFacturas(sortedArray);
				break;
			}
			case 'Cliente': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? facturas.slice().sort((a, b) => b.nombre.localeCompare(a.nombre))
						: facturas.slice().sort((a, b) => a.nombre.localeCompare(b.nombre));
				setFacturas(sortedArray);
				break;
			}
			case 'Total': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? facturas.slice().sort((a, b) => b.total - a.total)
						: facturas.slice().sort((a, b) => a.total - b.total);
				setFacturas(sortedArray);
				break;
			}
			case 'Estatus': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? facturas
								.slice()
								.sort((a, b) => b.estatus.localeCompare(a.estatus))
						: facturas
								.slice()
								.sort((a, b) => a.estatus.localeCompare(b.estatus));

				setFacturas(sortedArray);
				break;
			}
		}
	};

	return (
		<div className='mt-4'>
			<DetailHeaderPendings
				sortResults={sortResults}
				currentSortingCategory={currentSortingCategory}
				setCurrentSortingCategory={setCurrentSortingCategory}
			/>
			{!loading && !error.state && facturas.length === 0 && (
				<section className={classes.loading_container}>
					<h1>No se han encontrado registros.</h1>
				</section>
			)}
			{!loading && error.state && (
				<section className={classes.loading_container}>
					<h1>{error.msg}</h1>
				</section>
			)}
			{loading && !error.state && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
			{(totalPendings.length !== 0 || totalPresented.length !== 0) && (
				<div className={classes.details_container}>
					{facturas.map((fac) => (
						<DetailPendings
							key={fac.id}
							userData={fac}
							setMoreInfo={setMoreInfo}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default Pendings;
