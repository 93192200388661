export async function addFacebookPixelEvent(result) {
	async function calculateSHA256(str) {
		// Convert string to an array buffer
		const encoder = new TextEncoder();
		const data = encoder.encode(str);

		// Calculate the SHA-256 hash
		const hashBuffer = await crypto.subtle.digest('SHA-256', data);

		// Convert the hash to a hexadecimal string
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray
			.map((byte) => byte.toString(16).padStart(2, '0'))
			.join('');

		return hashHex;
	}
	const em = await calculateSHA256(result.data.email);
	const ph = await calculateSHA256(result.data.phone);
	const fn = await calculateSHA256(result.data.name);
	await fetch(
		'https://graph.facebook.com/' +
			process.env.REACT_APP_FACEBOOK_API_VERSION +
			'/' +
			process.env.REACT_APP_FACEBOOK_PIXEL_ID +
			'/events?access_token=' +
			process.env.REACT_APP_FACEBOOK_TOKEN,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				data: [
					{
						event_name: 'Login',
						event_time: Math.floor(Date.now() / 1000),
						event_source_url: 'https://ciberconta.com',
						action_source: 'website',
						user_data: {
							em: [em],
							ph: [ph],
							fn: [fn],
						},
					},
				],
			}),
		}
	);
}
