import React, { useRef } from 'react';
import Declarar from '../components/layout/home/Declarar';
import Empezar from '../components/layout/home/Empezar';
import Hero from '../components/layout/home/Hero';
import Mejor from '../components/layout/home/Mejor';
import Preguntas from '../components/layout/home/Preguntas';
import Prices from '../components/layout/home/Prices';
import HomeNav from '../components/layout/navbar/HomeNav';
import FooterHome from '../components/layout/footer/FooterHome';

const HomePage = () => {
	// Refs for window scroll
	const projectRef = useRef(null);
	const aboutRef = useRef(null);
	const reviewsRef = useRef(null);

	// Scroll to section function
	const scrollSection = (elementRef) => {
		window.scrollTo({
			top: elementRef.current.offsetTop,
			behavior: 'smooth',
		});
	};

	return (
		<div>
			<HomeNav
				scrollSection={scrollSection}
				projectRef={projectRef}
				aboutRef={aboutRef}
				reviewsRef={reviewsRef}
			/>
			<Hero />
			<Declarar projectRef={projectRef} />
			<Prices projectRef={aboutRef} />
			<Mejor />
			<Empezar />
			<Preguntas reviewsRef={reviewsRef} />
			<FooterHome
				scrollSection={scrollSection}
				projectRef={projectRef}
				aboutRef={aboutRef}
				reviewsRef={reviewsRef}
			/>
		</div>
	);
};

export default HomePage;
