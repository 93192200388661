import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../../css/Generals.module.css';
import classes from '../css/Facturas.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Autocomplete, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const FacturaFilters = ({
	clientRFCs,
	filterFacturas,
	initialDate,
	finalDate,
	setInitialDate,
	setFinalDate,
	currentClientOption,
	setCurrentClientOption,
}) => {
	let cibercontaInitialDate = dayjs('2020-01-01');
	let cibercontaFinalDate = dayjs().endOf('day');

	const [initialDateActive, setInitialDateActive] = useState(false);
	const [finalDateActive, setFinalDateActive] = useState(false);

	const clientOption = ['Todos', ...clientRFCs];
	const [clientOptionActive, setClientOptionActive] = useState(false);

	return (
		<div className='w-full flex justify-between mb-7'>
			<div className='flex gap-[40px]'>
				<div>
					<h2 className={generals.filter_subtitle}>Fecha inicial</h2>{' '}
					<div className='flex items-center'>
						{initialDate && (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={initialDate}
									label='Fecha inicial'
									date={initialDate}
									className={
										initialDateActive
											? `${generals.filter_input_box} ${generals.filter_input_active}`
											: `${generals.filter_input_box}`
									}
									sx={{ width: 200 }}
									onChange={(newValue) => {
										setInitialDateActive(true);
										setInitialDate(newValue);
										filterFacturas(false, false, newValue, finalDate);
									}}
								/>
							</LocalizationProvider>
						)}
						{initialDateActive && (
							<button
								onClick={() => {
									setInitialDateActive(false);
									setInitialDate(cibercontaInitialDate);
									filterFacturas(
										false,
										false,
										cibercontaInitialDate,
										finalDate
									);
								}}
							>
								<CancelIcon
									sx={{ marginLeft: 1, color: '#57585ad2', cursor: 'pointer' }}
								/>
							</button>
						)}
					</div>
				</div>
				<div>
					<h2 className={generals.filter_subtitle}>Fecha final</h2>{' '}
					<div className='flex items-center'>
						{finalDate && (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={finalDate}
									label='Fecha final'
									className={
										finalDateActive
											? `${generals.filter_input_box} ${generals.filter_input_active}`
											: `${generals.filter_input_box}`
									}
									sx={{ width: 200 }}
									onChange={(newValue) => {
										setFinalDateActive(true);
										setFinalDate(newValue);
										filterFacturas(false, false, initialDate, newValue);
									}}
								/>
							</LocalizationProvider>
						)}
						{finalDateActive && (
							<button
								onClick={() => {
									setFinalDateActive(false);
									setFinalDate(cibercontaFinalDate);
									filterFacturas(
										false,
										false,
										initialDate,
										cibercontaFinalDate
									);
								}}
							>
								<CancelIcon
									sx={{ marginLeft: 1, color: '#57585ad2', cursor: 'pointer' }}
								/>
							</button>
						)}
					</div>
				</div>
			</div>
			<div className='flex gap-[40px]'>
				<div>
					<h2 className={generals.filter_subtitle}>Filtrar por cliente</h2>{' '}
					<div className='flex items-center'>
						<Autocomplete
							disablePortal
							options={clientOption}
							value={currentClientOption}
							sx={{ width: 200, height: 45 }}
							renderInput={(params) => (
								<TextField
									className={
										clientOptionActive
											? `${generals.filter_input_box} ${generals.filter_input_active} text-[20px] w-full py-2 px-2`
											: `${generals.filter_input_box} text-[20px] w-full py-2 px-2`
									}
									{...params}
								/>
							)}
							onChange={(e, newValue) => {
								setCurrentClientOption(newValue);
								filterFacturas(false, newValue);
								setClientOptionActive(true);
							}}
							blurOnSelect={true}
						/>
						{clientOptionActive && (
							<button
								onClick={() => {
									setClientOptionActive(false);
									setCurrentClientOption('Todos');
									filterFacturas(false, 'Todos');
								}}
							>
								<CancelIcon
									sx={{ marginLeft: 1, color: '#57585ad2', cursor: 'pointer' }}
								/>
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FacturaFilters;
