import React, { useEffect, useState } from 'react';
import classes from '../css/DeclaracionesDashboard.module.css';
import generals from '../../../../../../css/Generals.module.css';
import $ from 'jquery';
import { capitalizeEveryWord } from '../../../../../../utils/format';

const DeclaracionesDashboard = ({ clienStatus, setModalClientStatus }) => {
	return (
		<div className={classes.generals_container}>
			<div className={classes.generals_container_buttons}>
				<div className={classes.generals_wrapper}>
					<h2 className={classes.generals_subtitle}>
						Última declaración:{' '}
						{clienStatus.mesRegistro + ' ' + clienStatus['añoRegistro']}
					</h2>

					<h2 className={classes.generals_subtitle}>
						Estatus: {capitalizeEveryWord(clienStatus.estatus)}
					</h2>
					<button
						onClick={() => {
							$('body').css('overflow', 'hidden');
							setModalClientStatus(true);
						}}
						className={generals.hero_button}
					>
						<h1 className={generals.hero_button_text}>
							{clienStatus.estatus === 'pendiente'
								? 'Aprobar declaración'
								: 'Ver declaración'}
						</h1>
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeclaracionesDashboard;
