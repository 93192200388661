import React, { useEffect, useState } from 'react';
import DeclaracionesItem from './DeclaracionesItem';
import Loading from '../../../../../common/Loading';
import classes from '../css/Declaraciones.module.css';

const DeclaracionesContainer = ({
	loading,
	error,
	years,
	setModalYear,
	setSelectedYear,
}) => {
	return (
		<div>
			{!loading && !error.state && years.length === 0 && (
				<section className={classes.loading_container}>
					<h1>No se han encontrado registros.</h1>
				</section>
			)}
			{!loading && error.state && (
				<section className={classes.loading_container}>
					<h1>{error.msg}</h1>
				</section>
			)}
			{loading && !error.state && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
			{!loading && !error.state && years.length > 0 && (
				<div className={classes.declaration_container}>
					{years.map((y) => (
						<DeclaracionesItem
							key={y.declarationYear}
							title={y.declarationYear}
							currentStatus={y.currentStatus}
							declaration={y}
							setModalYear={setModalYear}
							setSelectedYear={setSelectedYear}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default DeclaracionesContainer;
