import React from 'react';
import UserInfoItem from '../components/UserInfoItem';

const UserInfo = ({ userData }) => {
	const firstColumn = [
		{ title: 'Nombre', value: userData.name },
		{ title: 'Correo', value: userData.email },
		{ title: 'Teléfono', value: userData.phone },
		{
			title: 'Archivo KEY',
			value: userData.KEYFile || 'No completado',
			downloadItem: true,
		},
	];
	const secondColumn = [
		{ title: 'RFC', value: userData.rfc },
		{ title: 'Clave CIEC', value: userData.clave_ciec || 'No completado' },
		{ title: 'Efirma', value: userData.key || 'No completado' },
		{
			title: 'Archivo CER',
			value: userData.CERFile || 'No completado',
			downloadItem: true,
		},
	];
	return (
		<div>
			<div className='flex items-center justify-around w-full'>
				<div className='w-50 flex flex-col mt-5'>
					{firstColumn.map((x) => (
						<UserInfoItem
							key={x.title}
							value={x.value}
							title={x.title}
							downloadItem={x?.downloadItem}
						/>
					))}
				</div>
				<div className='w-50 flex flex-col mt-5'>
					{secondColumn.map((x) => (
						<UserInfoItem
							key={x.title}
							value={x.value}
							title={x.title}
							downloadItem={x?.downloadItem}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default UserInfo;
