import React from 'react';
import classes from './Loading.module.css';
const Loading = ({ loading, children }) => {
	if (loading) {
		return <div className={classes.loader}></div>;
	}

	return children;
};

export default Loading;
