import React, { useState } from 'react';
import classes from './css/DetailItem.module.css';
import SubjectIcon from '@mui/icons-material/Subject';
import ContentPasteSearch from '@mui/icons-material/ContentPasteSearch';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import BoyIcon from '@mui/icons-material/Boy';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DetailHeaderItem = ({
	sortResults,
	categoryName,
	currentSortingCategory,
	setCurrentSortingCategory,
}) => {
	const iconStyle = { width: '25px', height: '25px' };
	const [currentSortOrder, setCurrentSortOrder] = useState('');

	const handleCategoryIcon = (category) => {
		switch (category) {
			case 'Tipo': {
				return <ContentPasteSearch sx={iconStyle} />;
			}
			case 'RFC': {
				return <BoyIcon sx={iconStyle} />;
			}
			case 'Periodo': {
				return <SubjectIcon sx={iconStyle} />;
			}
			case 'Fecha': {
				return <EventAvailableRoundedIcon sx={iconStyle} />;
			}
			case 'Estatus': {
				return <ErrorOutlineOutlinedIcon sx={iconStyle} />;
			}
		}
	};

	const handleExpandableIcons = (category) => {
		return (
			<>
				{currentSortingCategory === category &&
					currentSortOrder === 'ascending' && <ExpandLessIcon sx={iconStyle} />}
				{currentSortingCategory === category &&
					currentSortOrder === 'descending' && (
						<ExpandMoreIcon sx={iconStyle} />
					)}
			</>
		);
	};

	const handleExpandableToggle = (category) => {
		let newOrder =
			currentSortOrder === 'descending' ? 'ascending' : 'descending';
		setCurrentSortOrder(newOrder);
		setCurrentSortingCategory(category);
		sortResults({ category: category, order: newOrder });
	};

	return (
		<button
			className={
				currentSortingCategory === categoryName
					? `${classes.detail_header_element} ${classes.active}`
					: `${classes.detail_header_element}`
			}
			onClick={() => handleExpandableToggle(categoryName)}
		>
			{handleCategoryIcon(categoryName)}
			<h2>{categoryName}</h2>
			{handleExpandableIcons(categoryName)}
		</button>
	);
};

export default DetailHeaderItem;
