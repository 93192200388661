import React, { useEffect, useState } from 'react';
import classes from '../css/FacturaDashboard.module.css';

const FacturaDashboard = ({ facturas, filterFacturas, setCurrentStatus }) => {
	const [facturaCount, setFacturaCount] = useState({
		solicitadas: 0,
		emitidas: 0,
		canceladas: 0,
		'solicitud de cancelación': 0,
	});

	const [optionActive, setOptionActive] = useState('');

	useEffect(() => {
		if (facturas?.length > 0) {
			let solicitadas = facturas.filter((x) => x.estatus === 'solicitada');
			let emitidas = facturas.filter((x) => x.estatus === 'emitida');
			let canceladas = facturas.filter(
				(x) => x.estatus === 'cancelación emitida'
			);
			let solicitudCanceladas = facturas.filter(
				(x) => x.estatus === 'cancelada'
			);

			setFacturaCount({
				solicitadas: solicitadas.length,
				emitidas: emitidas.length,
				canceladas: canceladas.length,
				'solicitud de cancelación': solicitudCanceladas.length,
			});
		}
	}, [facturas]);

	const handleClick = (status) => {
		if (optionActive === status) {
			setCurrentStatus('Todos');
			filterFacturas('Todos');
			setOptionActive('');
		} else {
			setCurrentStatus(status);
			filterFacturas(status);
			setOptionActive(status);
		}
	};

	return (
		<div className={classes.users_generals_container}>
			<div className={classes.users_generals_container_buttons}>
				<button
					onClick={() => {
						handleClick('solicitada');
					}}
					className={
						optionActive === 'solicitada'
							? `${classes.users_generals_wrapper} ${classes.users_generals_wrapper_active}`
							: `${classes.users_generals_wrapper}`
					}
				>
					<h2 className={classes.users_generals_subtitle}>
						En trámite de emisión
					</h2>
					<div className={classes.users_generals_count}>
						<h2>{facturaCount.solicitadas}</h2>
					</div>
				</button>
				<button
					onClick={() => handleClick('cancelada')}
					className={
						optionActive === 'cancelada'
							? `${classes.users_generals_wrapper} ${classes.users_generals_wrapper_active}`
							: `${classes.users_generals_wrapper}`
					}
				>
					<h2 className={classes.users_generals_subtitle}>
						En trámite de cancelación
					</h2>
					<div className={classes.users_generals_count}>
						<h2>{facturaCount['solicitud de cancelación']}</h2>
					</div>
				</button>
				<button
					onClick={() => handleClick('emitida')}
					className={
						optionActive === 'emitida'
							? `${classes.users_generals_wrapper} ${classes.users_generals_wrapper_active}`
							: `${classes.users_generals_wrapper}`
					}
				>
					<h2 className={classes.users_generals_subtitle}>Facturas emitidas</h2>
					<div className={classes.users_generals_count}>
						<h2>{facturaCount.emitidas}</h2>
					</div>
				</button>
				<button
					onClick={() => handleClick('cancelación emitida')}
					className={
						optionActive === 'cancelación emitida'
							? `${classes.users_generals_wrapper} ${classes.users_generals_wrapper_active}`
							: `${classes.users_generals_wrapper}`
					}
				>
					<h2 className={classes.users_generals_subtitle}>
						Facturas canceladas
					</h2>
					<div className={classes.users_generals_count}>
						<h2>{facturaCount.canceladas}</h2>
					</div>
				</button>
			</div>
		</div>
	);
};

export default FacturaDashboard;
