export const addClientStatus = async (TOKEN, body) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/clientstatus/create',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify(body),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getAllClientStatusById = async (TOKEN, USERID) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/clientstatus/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { createdAt: 1 },
						where: { clientId: USERID },
					},
					isCountOnly: false,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getAllClientStatusFiltered = async (TOKEN, userRFC) => {
	let whereClause = { estatus: 'aprobado' };
	if (userRFC && userRFC !== 'Todos los usuarios') {
		whereClause.clientId = userRFC.id;
	}

	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/clientstatus/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { createdAt: 1 },
						where: whereClause,
					},
					isCountOnly: false,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const partialUpdateClientStatus = async (TOKEN, ID) => {
	try {
		const currentDate = new Date();
		const response = await fetch(
			process.env.REACT_APP_API_URL +
				'client/api/v1/clientstatus/partial-update/' +
				ID,
			{
				method: 'PUT',
				headers: {
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					estatus: 'aprobado',
					fechaAprobado: currentDate,
				}),
			}
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};
