import React, { useState, useEffect } from 'react';
import classes from '../css/DetallesPrevios.module.css';
import { getAllFacturaById } from '../../../../../api/facturas';
import { useSelector } from 'react-redux';
import Loading from '../../../../../common/Loading';
import DetailFacturas from '../components/DetailFacturas';
import DetailHeaderFacturas from '../components/DetailHeaderFacturas';

const Facturas = ({ userData }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [userFactura, setUserFactura] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ state: false, msg: '' });

	useEffect(() => {
		const fetchUserFactura = async () => {
			setLoading(true);
			try {
				const response = await getAllFacturaById(TOKEN, userData.id);

				if (response.status === 200) {
					const result = await response.json();
					const resultData = result?.data?.data;
					setUserFactura(resultData);
					setLoading(false);
				} else {
					setLoading(false);
					setError({
						state: true,
						msg: 'Lo sentimos, parece ser que ocurrió un error.',
					});
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
				setError({
					state: true,
					msg: 'Lo sentimos, parece ser que ocurrió un error.',
				});
			}
		};
		fetchUserFactura();
	}, []);

	return (
		<div>
			{!loading && !error.state && userFactura.length === 0 && (
				<section className={classes.loading_container}>
					<h1>No hay facturas registradas del usuario.</h1>
				</section>
			)}
			{!loading && error.state && (
				<section className={classes.loading_container}>
					<h1>{error.msg}</h1>
				</section>
			)}
			{loading && !error.state && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
			{!loading && !error.state && userFactura.length > 0 && (
				<>
					<DetailHeaderFacturas />
					<div className={classes.details_container}>
						{userFactura.map((factura) => (
							<DetailFacturas key={factura.idfactura} userData={factura} />
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default Facturas;
