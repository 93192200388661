import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import mobileLogo from '../../../assets/images/mobileLogo.png';

const Navbar = () => {
	return (
		<div className=' shadow-lg bg-white lg:h-[100px] h-[66px] fixed w-[100%] z-20 lg:hidden flex justify-between items-center'>
			<div className='container'>
				<div className='flex items-center justify-between'>
					<div className='logo -mt-2'>
						<Link className=' hidden lg:flex' to={'/'}>
							<img src={logo} alt='' />
						</Link>
						<Link className=' lg:hidden' to={'/'}>
							<img src={mobileLogo} alt='' />
						</Link>
					</div>
					<div className='lg:hidden flex items-center gap-5'>
						<Link to={'/signup'}>
							<button className=' bg-[#4285F4] py-2 px-4 text-white rounded-lg font-bold text-[16px]'>
								Regístrate
							</button>
						</Link>
						<Link to={'/login'}>
							<button className=' bg-[#4285F4] py-2 px-4 text-white rounded-lg font-bold text-[16px]'>
								Iniciar sesión
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
