export const addSatDeclaration = async (TOKEN, body) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/satdeclaration/create',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify(body),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getAllSatDeclarationById = async (TOKEN, USERID) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/satdeclaration/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { createdAt: 1 },
						where: { userId: USERID },
					},
					isCountOnly: false,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const editSatDeclarationStatus = async (TOKEN, ID, updateBody) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL +
				'client/api/v1/satdeclaration/partial-update/' +
				ID,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify(updateBody),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getAllSatDeclarationsFiltered = async (
	TOKEN,
	userRFC,
	pending
) => {
	let whereClause = {};
	if (userRFC && userRFC !== 'Todos los usuarios') {
		whereClause.userId = userRFC.id;
	}

	if (pending) {
		whereClause.currentStatus = 'expirado';
		whereClause.reactivate = 1;
	} else {
		whereClause.currentStatus = ['vigente', 'pagado', 'expirado'];
		whereClause.reactivate = 0;
	}

	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/satdeclaration/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { createdAt: 1 },
						where: whereClause,
					},
					isCountOnly: false,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};
