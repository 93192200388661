import React, { useEffect, useState } from 'react';
import styles from './Notifications.module.css';
import NotificationItem from './NotificationItem';
import { useSelector } from 'react-redux';
import ClickOutsideReact from '../../common/Clickoutside';
import Loading from '../../common/Loading';
import { getAllNotifications } from '../../api/notifications';
import emptyBox from '../../../assets/images/messages/box.png';
import text from '../../../css/Text.module.css';

const Notifications = ({ onRefreshCount, onClose, recipient, userId }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		const fetchNotifications = async () => {
			try {
				const notifications = await getAllNotifications(
					TOKEN,
					recipient,
					userId
				);
				setNotifications(notifications);
				setLoading(false);
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		};
		fetchNotifications();
	}, []);

	const setAsViewed = async (id) => {
		try {
			const response = await fetch(
				process.env.REACT_APP_API_URL +
					'client/api/v1/notification/partial-update/' +
					id,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						// prettier-ignore
						'Authorization': 'Bearer ' + TOKEN,
					},
					body: JSON.stringify({
						viewed: true,
					}),
				}
			);
			if (response.ok) {
				onRefreshCount((prevState) => !prevState);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className={styles.notifications_container}>
			<Loading loading={loading}>
				<ClickOutsideReact onClickOutside={onClose}>
					<div className={`mx-[15px] ${styles.notifications_header}`}>
						<h1 className={text.text_title_blue_1}>Notificaciones</h1>
					</div>
					{!loading && notifications?.length > 0 && (
						<div className={styles.notifications_body}>
							{notifications.map((x) => (
								<NotificationItem
									key={x.id}
									notification={x}
									onSetAsViewed={setAsViewed}
								/>
							))}
						</div>
					)}
					{!loading && notifications?.length === 0 && (
						<div className={styles.notifications_body_empty}>
							<img
								className={styles.notifications_icon_box}
								src={emptyBox}
								alt='Sin notificaciones'
							/>
							<h1 className={text.text_title_blue_1}>¡Bandeja vacía!</h1>
							<p className={text.text_description_black_1}>
								No tienes notificaciones por el momento.
							</p>
						</div>
					)}
				</ClickOutsideReact>
			</Loading>
		</div>
	);
};

export default Notifications;
