import React, { useState, useEffect } from 'react';
import classes from '../css/DetallesPrevios.module.css';
import { getAllClientStatusById } from '../../../../../api/clientStatus';
import { useSelector } from 'react-redux';
import Loading from '../../../../../common/Loading';
import DetailDetalles from '../components/DetailDetalles';
import DetailHeaderDetalles from '../components/DetailHeaderDetalles';

const DetallesPrevios = ({ userData }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [userClientStatus, setUserClientStatus] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ state: false, msg: '' });

	useEffect(() => {
		const fetchUserClientStatus = async () => {
			setLoading(true);
			try {
				const response = await getAllClientStatusById(TOKEN, userData.id);

				if (response.status === 200) {
					const result = await response.json();
					const resultData = result?.data?.data;
					setUserClientStatus(resultData);
					setLoading(false);
				} else {
					setLoading(false);
					setError({
						state: true,
						msg: 'Lo sentimos, parece ser que ocurrió un error.',
					});
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
				setError({
					state: true,
					msg: 'Lo sentimos, parece ser que ocurrió un error.',
				});
			}
		};
		fetchUserClientStatus();
	}, []);

	return (
		<div>
			{!loading && !error.state && userClientStatus.length === 0 && (
				<section className={classes.loading_container}>
					<h1>No se han encontrado registros.</h1>
				</section>
			)}
			{!loading && error.state && (
				<section className={classes.loading_container}>
					<h1>{error.msg}</h1>
				</section>
			)}
			{loading && !error.state && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
			{!loading && !error.state && userClientStatus.length > 0 && (
				<>
					<DetailHeaderDetalles />
					<div className={classes.details_container}>
						{userClientStatus.map((clientStatus) => (
							<DetailDetalles
								key={
									userData.rfc +
									clientStatus.mesRegistro +
									clientStatus['añoRegistro']
								}
								userData={clientStatus}
								currentUser={userData}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default DetallesPrevios;
