import React from 'react';
import googlePlayBadge from '../../../assets/images/google-play-badge.png';
import appStoreBadge from '../../../assets/images/Download_on_the_App_Store_Badge_ES_RGB_wht_100217.svg';
import facebook from '../../../assets/images/facebook.png';
import twitter from '../../../assets/images/twitter.png';
import linkidin from '../../../assets/images/linkidin.png';
import { Link } from 'react-router-dom';
import classes from './Footer.module.css';

const Footer = () => {
	const appleStoreLink =
		'https://apps.apple.com/us/app/ciberconta/id6462903867';
	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=com.nainkhaskheli.Ciberconta';

	const getCurrentYear = () => {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		return currentYear;
	};

	return (
		<div className='md:pt-[70px] py-[20px]'>
			<div className='container'>
				<div className='flex items-start flex-col md:flex-row justify-between flex-wrap'>
					<div className='w-full lg:text-[20px] text-[18px] font-normal flex items-end mb-[10px]'>
						<div className='w-full justify-between flex flex-col md:flex-row items-center gap-5 py-[10px] text-[#4285f4]'>
							<div
								className={`${classes.insignia_footer} flex-col md:flex-row justify-center items-center`}
							>
								<div className='flex items-center justify-center'>
									<a
										className={`${classes.insignia_footer_img} flex w-[145px]`}
										href={googlePlayLink}
										target='_blank'
									>
										<img src={googlePlayBadge} alt='Google Play' />
									</a>
								</div>

								<div
									className={`${classes.insignia_footer_img} w-full flex items-center justify-center relative`}
								>
									<a
										className='flex w-[175px] md:w-[165px]'
										href={appleStoreLink}
										target='_blank'
									>
										<img
											className='w-full'
											src={appStoreBadge}
											alt='App Store'
										/>
									</a>
								</div>
							</div>
							<Link className='pt-10 md:pt-0' to={'/aviso'} target='_blank'>
								{' '}
								<h1>Aviso de privacidad</h1>
							</Link>
							<Link to={'/terminos'} target='_blank'>
								{' '}
								<h1>Términos y condiciones</h1>
							</Link>
							<Link to={'/'}>
								{' '}
								<h1>
									Ciberconta<sup>®</sup> {getCurrentYear()}
								</h1>
							</Link>
						</div>
					</div>
					<div
						className={`md:hidden flex w-full justify-center items-center gap-4 py-10`}
					>
						<a
							href='https://www.linkedin.com/company/ciberconta/'
							target='_blank'
						>
							<img
								className={`${classes.insignia_footer_icon}`}
								style={{ width: '25px' }}
								src={linkidin}
								alt='LinkedIn Ciberconta'
							/>
						</a>
						<a href='https://twitter.com/cibercontamx/' target='_blank'>
							<img
								className={`${classes.insignia_footer_icon}`}
								style={{ width: '25px' }}
								src={twitter}
								alt='Twitter Ciberconta'
							/>
						</a>
						<a href='https://www.facebook.com/cibercontamx/' target='_blank'>
							{' '}
							<img
								className={`${classes.insignia_footer_icon}`}
								style={{ width: '25px' }}
								src={facebook}
								alt='Facebook Ciberconta'
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
