import React from 'react';
import classes from '../css/FacturaModal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import generals from '../../../../../../css/Generals.module.css';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FacturaModalNewContactForm = ({
	loading,
	success,
	foreigner,
	error,
	facturaContact,
	setFacturaContact,
	isEmailValid,
	isRFCValid,
	isSubmitted,
	handleSubmit,
	handleClose,
	handleCheckbox,
	cleanErr,
}) => {
	return (
		<div className={classes.modal_overlay}>
			<div className={classes.modal_container}>
				<button className={classes.icon_close_wrapper} onClick={handleClose}>
					<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
				</button>
				<h1 className={classes.modal_title}>Nuevo cliente</h1>
				<h2 className={classes.modal_subtitle}>
					Llena los campos para crear tu nuevo cliente a facturar
				</h2>
				{!loading && !success.state && (
					<div className={classes.modal_form}>
						<div className={classes.modal_checkbox_wrapper}>
							<h2 className={generals.text_input_subtitle}>
								¿Tu cliente es extranjero?
							</h2>

							<FormControlLabel
								control={
									<Checkbox checked={foreigner} onChange={handleCheckbox} />
								}
								label='Sí'
							/>
							<FormControlLabel
								control={
									<Checkbox checked={!foreigner} onChange={handleCheckbox} />
								}
								label='No'
							/>
						</div>
						<div className={classes.modal_form_wrapper_column}>
							<div className={classes.modal_form_column}>
								{foreigner && (
									<>
										<h2 className={generals.text_input_subtitle}>País</h2>
										<TextField
											required
											sx={{ width: 400 }}
											className={
												isSubmitted === 'contact' && !facturaContact.country
													? `${generals.text_input} ${generals.text_input_error}`
													: generals.text_input
											}
											id='client-country'
											variant='outlined'
											placeholder='País'
											value={facturaContact.country || ''}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													country: e.target.value || '',
												}));
												if (error.state) cleanErr();
											}}
										/>
									</>
								)}
								<h2 className={generals.text_input_subtitle}>
									Nombre o razón social de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'contact' && !facturaContact.clientName
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-nombre'
									variant='outlined'
									placeholder='Razón social'
									value={facturaContact.clientName || ''}
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientName: e.target.value || '',
										}));
										if (error.state) cleanErr();
									}}
								/>
								<h2 className={generals.text_input_subtitle}>
									Código postal de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'contact' && !facturaContact.clientZipCode
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-CP'
									variant='outlined'
									placeholder='C.P.'
									value={facturaContact.clientZipCode || ''}
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientZipCode: e.target.value.trim() || '',
										}));
										cleanErr();
									}}
								/>
							</div>
							<div className={classes.modal_form_column}>
								{foreigner && (
									<>
										<h2 className={generals.text_input_subtitle}>
											Identificación fiscal
										</h2>
										<TextField
											required
											sx={{ width: 400 }}
											className={
												isSubmitted === 'contact' && !facturaContact.idfiscal
													? `${generals.text_input} ${generals.text_input_error}`
													: generals.text_input
											}
											id='cliente-idfiscal'
											variant='outlined'
											placeholder='ID Fiscal'
											value={facturaContact.idfiscal || ''}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													idfiscal: e.target.value.trim() || '',
												}));
												cleanErr();
											}}
										/>
									</>
								)}
								<h2 className={generals.text_input_subtitle}>
									Correo de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'contact' &&
										(!isEmailValid || !facturaContact.clientEmail)
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-email'
									variant='outlined'
									placeholder='Correo electrónico'
									value={facturaContact.clientEmail || ''}
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientEmail: e.target.value.trim() || '',
										}));
										cleanErr();
									}}
								/>
								<h2 className={generals.text_input_subtitle}>
									RFC de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'contact' &&
										(!isRFCValid || !facturaContact.clientRFC)
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-rfc'
									value={facturaContact.clientRFC || ''}
									placeholder='RFC'
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientRFC: e.target.value.toUpperCase().trim() || '',
										}));
										cleanErr();
									}}
									variant='outlined'
								/>
							</div>
						</div>
					</div>
				)}
				<button className={generals.button_blue} onClick={handleSubmit}>
					Crear nuevo contacto
				</button>
				<button
					onClick={handleClose}
					className={`my-4 ${generals.button_white}`}
				>
					Regresar
				</button>
			</div>
		</div>
	);
};
export default FacturaModalNewContactForm;
