import React, { useState } from 'react';
import classes from '../css/FacturaModal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import generals from '../../../../../../css/Generals.module.css';
import Loading from '../../../../../common/Loading';
import {
	FormControl,
	OutlinedInput,
	Select,
	MenuItem,
	FormControlLabel,
	InputLabel,
	TextField,
	Checkbox,
	InputAdornment,
} from '@mui/material';
import {
	monedas,
	paymentMethods,
	paymentForms,
	CFDI,
	regimenFiscal,
} from '../constant/constant';

const FacturaModalNewFacturaForm = ({
	loading,
	success,
	foreigner,
	error,
	facturaContact,
	setFacturaContact,
	isEmailValid,
	isRFCValid,
	isSubmitted,
	handleSubmit,
	handleClose,
	handleCheckbox,
	cleanErr,
}) => {
	const inputLabelStyles = {
		top: 14,
		left: 12.5,
		color: '#57585AD2',
		opacity: 0.4,
	};

	return (
		<div className={classes.modal_overlay}>
			<div className={classes.modal_container}>
				<button className={classes.icon_close_wrapper} onClick={handleClose}>
					<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
				</button>
				<h1 className={classes.modal_title}>Nuevo cliente</h1>
				<h2 className={classes.modal_subtitle}>
					Llena los campos para crear tu nuevo cliente a facturar
				</h2>
				{!loading && !success.state && (
					<div className={classes.modal_form}>
						<div className={classes.modal_checkbox_wrapper}>
							<h2 className={generals.text_input_subtitle}>
								¿Tu cliente es extranjero?
							</h2>

							<FormControlLabel
								control={
									<Checkbox checked={foreigner} onChange={handleCheckbox} />
								}
								label='Sí'
							/>
							<FormControlLabel
								control={
									<Checkbox checked={!foreigner} onChange={handleCheckbox} />
								}
								label='No'
							/>
						</div>
						<div className={classes.modal_form_wrapper_column}>
							<div className={classes.modal_form_column}>
								{foreigner && (
									<>
										<h2 className={generals.text_input_subtitle}>País</h2>
										<TextField
											required
											sx={{ width: 400 }}
											className={
												isSubmitted === 'factura' && !facturaContact.country
													? `${generals.text_input} ${generals.text_input_error}`
													: generals.text_input
											}
											id='client-country'
											variant='outlined'
											placeholder='País'
											value={facturaContact.country || ''}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													country: e.target.value || '',
												}));
												if (error.state) cleanErr();
											}}
										/>
									</>
								)}
								<h2 className={generals.text_input_subtitle}>
									Nombre o razón social de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'factura' && !facturaContact.clientName
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-nombre'
									variant='outlined'
									placeholder='Razón social'
									value={facturaContact.clientName || ''}
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientName: e.target.value || '',
										}));
										if (error.state) cleanErr();
									}}
								/>
								<h2 className={generals.text_input_subtitle}>
									Código postal de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'factura' && !facturaContact.clientZipCode
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-CP'
									variant='outlined'
									placeholder='C.P.'
									value={facturaContact.clientZipCode || ''}
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientZipCode: e.target.value.trim() || '',
										}));
										cleanErr();
									}}
								/>
								<h2 className={generals.text_input_subtitle}>Regimen Fiscal</h2>
								<FormControl>
									<InputLabel htmlFor='regimen-fiscal' sx={inputLabelStyles}>
										Regimen Fiscal
									</InputLabel>
									<Select
										id='regimen-fiscal'
										sx={{ width: 400, height: 65 }}
										className={
											isSubmitted === 'factura' && !facturaContact.regimenFiscal
												? `${generals.select_input} ${generals.select_input_error}`
												: generals.select_input
										}
										value={facturaContact.regimenFiscal}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												regimenFiscal: e.target.value || '',
											}));
											cleanErr();
										}}
									>
										{regimenFiscal.map((reg) => (
											<MenuItem key={reg} value={reg}>
												{reg}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<h2 className={generals.text_input_subtitle}>
									Descripción del servicio a facturar
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'factura' && !facturaContact.description
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='descripcion-detallada'
									variant='outlined'
									placeholder='Servicio a facturar'
									value={facturaContact.description || ''}
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											description: e.target.value || '',
										}));
										if (error.state) cleanErr();
									}}
								/>
								<h2 className={generals.text_input_subtitle}>Uso de CFDI</h2>
								<FormControl>
									<InputLabel htmlFor='uso-cfdi' sx={inputLabelStyles}>
										CFDI
									</InputLabel>
									<Select
										id='uso-cfdi'
										sx={{ width: 400, height: 65 }}
										className={
											isSubmitted === 'factura' && !facturaContact.CFDI
												? `${generals.select_input} ${generals.select_input_error}`
												: generals.select_input
										}
										value={facturaContact.CFDI}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												CFDI: e.target.value || '',
											}));
											cleanErr();
										}}
										input={<OutlinedInput />}
									>
										{CFDI.map((cfdi) => (
											<MenuItem key={cfdi} value={cfdi}>
												{cfdi}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<h2 className={generals.text_input_subtitle}>
									Total a facturar (IVA incluido)
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'factura' && !facturaContact.total
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>$</InputAdornment>
										),
									}}
									id='total'
									variant='outlined'
									placeholder='Total'
									value={facturaContact.total || ''}
									onChange={(e) => {
										let formatted = e.target.value
											.replace(/[^0-9.]/g, '')
											.replace(/\./g, '');
										const dotPosition = formatted.length - 1;
										let money = formatted;
										if (formatted.length > 2) {
											money =
												formatted.substring(0, dotPosition - 1) +
												'.' +
												formatted.substring(dotPosition - 1);
										}
										setFacturaContact((prevState) => ({
											...prevState,
											total: money,
										}));
										cleanErr();
									}}
								/>
							</div>
							<div className={classes.modal_form_column}>
								{foreigner && (
									<>
										<h2 className={generals.text_input_subtitle}>
											Identificación fiscal
										</h2>
										<TextField
											required
											sx={{ width: 400 }}
											className={
												isSubmitted === 'factura' && !facturaContact.idfiscal
													? `${generals.text_input} ${generals.text_input_error}`
													: generals.text_input
											}
											id='cliente-idfiscal'
											variant='outlined'
											placeholder='ID Fiscal'
											value={facturaContact.idfiscal || ''}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													idfiscal: e.target.value || '',
												}));
												cleanErr();
											}}
										/>
									</>
								)}
								<h2 className={generals.text_input_subtitle}>
									Correo de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'factura' &&
										(!isEmailValid || !facturaContact.clientEmail)
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-email'
									variant='outlined'
									placeholder='Correo electrónico'
									value={facturaContact.clientEmail || ''}
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientEmail: e.target.value.trim() || '',
										}));
										cleanErr();
									}}
								/>
								<h2 className={generals.text_input_subtitle}>
									RFC de tu cliente
								</h2>
								<TextField
									required
									sx={{ width: 400 }}
									className={
										isSubmitted === 'factura' &&
										(!isRFCValid || !facturaContact.clientRFC)
											? `${generals.text_input} ${generals.text_input_error}`
											: generals.text_input
									}
									id='cliente-rfc'
									value={facturaContact.clientRFC || ''}
									placeholder='RFC'
									onChange={(e) => {
										setFacturaContact((prevState) => ({
											...prevState,
											clientRFC: e.target.value.toUpperCase().trim() || '',
										}));
										cleanErr();
									}}
									variant='outlined'
								/>
								<h2 className={generals.text_input_subtitle}>Moneda</h2>
								<FormControl>
									<InputLabel htmlFor='moneda' sx={inputLabelStyles}>
										Moneda
									</InputLabel>
									<Select
										id='moneda'
										sx={{ width: 400, height: 65 }}
										className={
											isSubmitted === 'factura' && !facturaContact.currency
												? `${generals.select_input} ${generals.select_input_error}`
												: generals.select_input
										}
										value={facturaContact.currency}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												currency: e.target.value || '',
											}));
											cleanErr();
										}}
									>
										{monedas.map((moneda) => (
											<MenuItem key={moneda} value={moneda}>
												{moneda}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<h2 className={generals.text_input_subtitle}>Forma de pago</h2>
								<FormControl>
									<InputLabel htmlFor='forma-de-pago' sx={inputLabelStyles}>
										Forma de pago
									</InputLabel>
									<Select
										id='forma-de-pago'
										sx={{ width: 400, height: 65 }}
										className={
											isSubmitted === 'factura' && !facturaContact.paymentForm
												? `${generals.select_input} ${generals.select_input_error}`
												: generals.select_input
										}
										value={facturaContact.paymentForm}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												paymentForm: e.target.value || '',
											}));
											cleanErr();
										}}
									>
										{paymentForms.map((form) => (
											<MenuItem key={form} value={form}>
												{form}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<h2 className={generals.text_input_subtitle}>Método de pago</h2>
								<FormControl>
									<InputLabel htmlFor='metodo-de-pago' sx={inputLabelStyles}>
										Método de pago
									</InputLabel>
									<Select
										id='metodo-de-pago'
										sx={{ width: 400, height: 65 }}
										className={
											isSubmitted === 'factura' && !facturaContact.paymentMethod
												? `${generals.select_input} ${generals.select_input_error}`
												: generals.select_input
										}
										value={facturaContact.paymentMethod}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												paymentMethod: e.target.value || '',
											}));
											cleanErr();
										}}
									>
										{paymentMethods.map((method) => (
											<MenuItem key={method} value={method}>
												{method}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
				)}
				{!loading && success.state && (
					<div className={classes.modal_info_loading_container}>
						<p className={`mt-5 ${generals.modal_success_text}`}>
							Factura solicitada con éxito.
						</p>
					</div>
				)}
				{loading && <Loading loading={loading} />}
				{!success.state && (
					<button className={generals.button_blue} onClick={handleSubmit}>
						Solicitar factura
					</button>
				)}
				<button
					onClick={handleClose}
					className={`my-4 ${generals.button_white}`}
				>
					Regresar
				</button>
			</div>
		</div>
	);
};
export default FacturaModalNewFacturaForm;
