import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import LoginNav from '../components/layout/navbar/LoginNav';
import { useDispatch } from 'react-redux';
import { clientStatusActions } from '../store/clientStatus';
import { profileActions } from '../store/profile';
import { authActions } from '../store/auth';
import { socket } from '../socket';
import commonStyles from '../components/common/Common.module.css';
import { addFacebookPixelEvent } from '../utils/facebookAPI';
import { login } from '../components/api/users';
import Loading from '../components/common/Loading';

const LoginPage = () => {
	// Variables
	const [RFC, setRFC] = useState('');
	const [password, setpassword] = useState('');
	const [state, setState] = useState(false);
	const [error, setError] = useState({ state: false, message: '' });
	const [loading, setLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	// Navigate
	const navigate = useNavigate();
	// Dispatch
	const dispatch = useDispatch();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitted(true);
		if (RFC && password) {
			try {
				setLoading(true);
				const response = await login(RFC, password);
				switch (response?.status) {
					case 200: {
						const result = await response.json();
						if (result) {
							await addFacebookPixelEvent(result);
						}
						setError({ state: false, message: '' });
						dispatch(authActions.setUserId(result.data.id));
						dispatch(authActions.setTokenId(result.data.token));
						if (result.data.role == process.env.REACT_APP_ADMIN) {
							dispatch(profileActions.adduserprofiledata('profile'));
							navigate('/admin');
							socket.connect();
						} else {
							dispatch(profileActions.adduserprofiledata('profile'));
							navigate('/profile');
						}
						dispatch(
							clientStatusActions.setClientData({
								userEmail: result.data.email,
								userName: result.data.rfc,
								userFullName: result.data.name,
								userRFC: result.data.rfc,
								userCIEC: result.data.clave_ciec,
								userPhone: result.data.phone,
								userRole: result.data.role,
								userEfirma: result.data.key,
								userStripeId: result.data.stripeId,
								userCERFile: result.data.CERFile,
								userKEYFile: result.data.KEYFile,
								userSubscription: result.data.subscription,
								userAnualPlan: result.data.anualPlan,
							})
						);

						break;
					}
					case 400:
						setLoading(false);
						setError({
							state: true,
							message: 'El nombre de usuario o contraseña son incorrectos.',
						});
						break;
					case 500:
						setLoading(false);
						setError({
							state: true,
							message: 'Error interno en el servidor.',
						});
						break;
					default:
						setLoading(false);
						setError({
							state: true,
							message: 'Algo salió mal. Favor de volver a intentar.',
						});
						break;
				}
			} catch (error) {
				setLoading(false);
				setError({
					state: true,
					message: 'Algo salió mal. Favor de volver a intentar.',
				});
				console.error('Error:', error);
			}
		} else {
			setLoading(false);
			setError({
				state: true,
				message: 'Favor de llenar todos los campos solicitados.',
			});
		}
	};
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const trogalButton = (e) => {
		e.preventDefault();
		setState((prevState) => !prevState);
	};

	return (
		<>
			<LoginNav />
			<div className='md:pt-[120px] pt-[90px] login-container'>
				<div className='container'>
					<div className='max-w-[500px] m-auto'>
						<div className='headding mb-4'>
							<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
								Inicia sesión
							</h2>
							<p className='md:text-[24px] text-[14px] mt-1'>
								Bienvenid@ de vuelta{' '}
							</p>
						</div>
						<div className='flex justify-center'>
							<Loading loading={loading} />
						</div>

						<form action='' onSubmit={handleSubmit}>
							<div className='flex items-center flex-col md:flex-row gap-0  md:gap-[100px] w-full'>
								<div className='w-full'>
									<div className='py-2'>
										<label htmlFor='phone' className='ml-5 text-[20px]'>
											RFC
										</label>
										<input
											type='text'
											id='phone'
											className={
												isSubmitted && !RFC
													? 'input_box_error text-[20px] w-full py-2 px-2'
													: 'input_box text-[20px] w-full py-2 px-2'
											}
											value={RFC}
											onChange={(e) =>
												setRFC(e.target.value.toUpperCase().trim())
											}
											onFocus={() => {
												setError({
													state: false,
													message: '',
												});
												setIsSubmitted(false);
											}}
										/>
									</div>

									<div className='py-2'>
										<label htmlFor='Contraseña' className='ml-5 text-[20px]'>
											Contraseña
										</label>
										<div className=' relative'>
											<input
												type={`${state ? 'text' : 'password'}`}
												id='Contraseña'
												className={
													isSubmitted && !password
														? 'input_box_error text-[20px] w-full py-2 px-2'
														: 'input_box text-[20px] w-full py-2 px-2'
												}
												value={password}
												onChange={(e) => setpassword(e.target.value.trim())}
												onFocus={() => {
													setError({
														state: false,
														message: '',
													});
													setIsSubmitted(false);
												}}
											/>
											<button
												className='text-[24px] absolute right-[10px] top-[30%]'
												onClick={trogalButton}
											>
												{state ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
											</button>
										</div>
									</div>
									<div className=' text-right'>
										<Link
											className='  border-b border-gray-400 text-right'
											to={'/forget-password'}
										>
											Olvidé mi contraseña
										</Link>
									</div>
									{error.state && (
										<p
											style={{
												fontSize: '1.2rem',
												color: '#DB6363',
												textAlign: 'center',
												paddingTop: '25px',
												paddingBottom: '25px',
											}}
										>
											{error.message}
										</p>
									)}
								</div>
							</div>
							<div>
								<button
									type='submit'
									className={`${commonStyles.button} py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]`}
								>
									Iniciar sesión
								</button>
								<p className='md:text-[24px] text-[14px] font-normal mt-5'>
									¿Aún no tienes una cuenta?{' '}
									<Link className='  border-b border-gray-400' to={'/signup'}>
										Regístrate aquí
									</Link>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginPage;
