export const getAllUsers = async (TOKEN) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/user/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 1000,
						select: [
							'id',
							'rfc',
							'subscription',
							'anualPlan',
							'createdAt',
							'clave_ciec',
							'key',
							'CERFile',
							'KEYFile',
							'name',
							'phone',
							'email',
							'stripeId',
						],
					},
					isCountOnly: false,
				}),
			}
		);
		const result = await response.json();
		const resultData = result?.data?.data;

		return resultData;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getUserById = async (TOKEN, id) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/user/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 1000,
						select: [
							'id',
							'name',
							'email',
							'phone',
							'rfc',
							'clave_ciec',
							'key',
							'CERFile',
							'KEYFile',
							'subscription',
							'anualPlan',
						],
						where: { id: id },
					},
					isCountOnly: false,
				}),
			}
		);
		const result = await response.json();
		const resultData = result?.data?.data;

		return resultData;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const login = async (RFC, password) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/auth/login',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					username: RFC,
					password: password,
				}),
			}
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const signUp = async (RFC, email, password, fullName, phone) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/auth/register',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					username: RFC,
					email: email,
					rfc: RFC,
					password: password,
					name: fullName,
					phone: phone,
					role: 1,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error(error);
	}
};
