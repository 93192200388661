import React, { useState, useEffect } from 'react';
import classes from '../css/DetailItem.module.css';
import { getUserFiles } from '../../../../../api/userfiles';
import { useSelector } from 'react-redux';
import { extractDate, roundToTwoDecimals } from '../utils/utils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatMexicoTz } from '../../../../../../utils/format';

const DetailDetalles = ({ userData, currentUser }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);

	const retrieveFiles = async () => {
		// E.i. MOMM770119FA_DETALLE_Agosto2023.pdf
		const value =
			userData.rfc +
			'_DETALLE_' +
			userData.mesRegistro +
			userData['añoRegistro'] +
			'.pdf';
		const userFile = await getUserFiles(TOKEN, value);
		window.open(userFile.data);
	};

	const getStatusClassName = (status) => {
		switch (status) {
			case 'presentado': {
				return `${classes.detail_status}`;
			}
			case 'pendiente': {
				return `${classes.detail_status} ${classes.pending}`;
			}
			case 'aprobado': {
				return `${classes.detail_status} ${classes.approved}`;
			}
			default: {
				return `${classes.detail_status} ${classes.no_status}`;
			}
		}
	};

	return (
		<div className={classes.detail_wrapper}>
			<h2 className={classes.detail_element}>
				{userData.mesRegistro} {userData['añoRegistro']}
			</h2>
			<h2 className={classes.detail_element}>
				${roundToTwoDecimals(userData.total)}
			</h2>
			<h2 className={classes.detail_element}>
				{formatMexicoTz(userData.createdAt) || '-'}
			</h2>
			<h2 className={classes.detail_element}>
				{formatMexicoTz(userData.fechaAprobado) || '-'}
			</h2>
			<div className={classes.detail_element_download}>
				<h2 className={getStatusClassName(userData.estatus)}>
					{userData.estatus || 'sin estatus'}
				</h2>
			</div>
			<button
				onClick={retrieveFiles}
				className={classes.detail_element_seemore}
			>
				<FileDownloadOutlinedIcon sx={{ fontSize: 25 }} />
			</button>
		</div>
	);
};

export default DetailDetalles;
