export const getAllNotifications = async (TOKEN, recipient, userId) => {
	let whereClause = { recipient: 'admin' };
	if (recipient && userId) {
		whereClause = { recipient: recipient, userId: userId };
	}
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/notification/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { id: -1 },
						where: whereClause,
					},
					isCountOnly: false,
				}),
			}
		);
		const result = await response.json();
		const resultData = result?.data?.data;
		return resultData;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const countPendingNotifications = async (TOKEN, recipient, userId) => {
	let whereClause = { viewed: false, recipient: 'admin' };
	if (recipient && userId) {
		whereClause = { viewed: false, recipient: 'client', userId: userId };
	}
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/notification/count',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					where: whereClause,
				}),
			}
		);
		const result = await response.json();
		const resultData = result?.data?.totalRecords;
		return resultData;
	} catch (error) {
		console.error('Error:', error);
	}
};
