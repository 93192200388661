import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsXLg } from 'react-icons/bs';
import classes from './FileUploader.module.css';
import folder from '../../assets/images/file-folder.png';
import '../../style.css';

const FileUploader = ({
	userId,
	onSetFormData,
	validExtensions,
	nameConvention,
	includeDate,
	year,
	month,
	buttonOnly,
}) => {
	const [stateFiles, setStateFiles] = useState([]);
	const [error, setError] = useState({ state: false, message: '' });

	const validateFileType = (fileName) => {
		const validExtensionsFromProps = validExtensions;
		const fileExtension = fileName.split('.').pop().toLowerCase();
		return validExtensionsFromProps.includes(fileExtension);
	};

	const onDrop = async (acceptedFiles) => {
		if (!acceptedFiles || acceptedFiles.length === 0) {
			console.log('No files selected');
			return;
		}
		const addedfiles = Array.isArray(acceptedFiles)
			? acceptedFiles
			: [acceptedFiles];
		const files = [...stateFiles, ...addedfiles];

		try {
			const formData = new FormData();

			for (let selectedFile of files) {
				if (validateFileType(selectedFile.name)) {
					const fileDetailName = includeDate
						? userId +
						  nameConvention +
						  month +
						  year +
						  '.' +
						  selectedFile.name.split('.').pop().toLowerCase()
						: userId +
						  nameConvention +
						  '.' +
						  selectedFile.name.split('.').pop().toLowerCase();
					const file = new File([selectedFile], fileDetailName, {
						type: selectedFile.type,
					});
					formData.append('file[]', file);
					setStateFiles(files);
					onSetFormData(formData);
				} else {
					setError({
						state: true,
						message: 'Tipo de archivo inválido.',
					});
					setTimeout(() => {
						setError({ state: false, message: '' });
					}, 3000);
					console.log(`Invalid file type: ${selectedFile.name}`);
					return;
				}
			}
		} catch (error) {
			console.error('Error uploading files:', error);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div className='fileBoxWrapper'>
			{buttonOnly ? (
				<div
					className={`${classes.uploadFile_buttonWrapper}`}
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					{stateFiles?.length === 0 && (
						<div className='flex flex-wrap justify-center'>
							<div className='w-full flex justify-center mb-[20px]'>
								<img
									className='w-[45px]'
									src={folder}
									alt='Seleccionar Archivo'
								/>
							</div>
							<button
								className={`${classes.uploadFile_selectButton} py-4 px-5 rounded-md font-medium md:text-[24px]`}
							>
								Seleccionar
							</button>
						</div>
					)}
				</div>
			) : (
				<div className='fileBox' {...getRootProps()}>
					<div onClick={() => setError({ state: false, message: '' })}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<div>
								<p>Archivos aquí...</p>
							</div>
						) : (
							<p>
								Arrastra y suelta o haz{' '}
								<span className={classes.button_ClickUpload}>clic</span> para
								seleccionar archivos{' '}
								{validExtensions?.map((extension) => (
									<strong key={extension}>{extension} </strong>
								))}
							</p>
						)}
					</div>
				</div>
			)}
			{error.state && (
				<p className={classes.fileUploader_error}>{error.message}</p>
			)}
			<div className={classes.fileNameBoxWrapper}>
				{stateFiles?.map((file) => {
					return (
						<div className={classes.fileNameBox} key={file.name}>
							{file.name}
							<BsXLg
								onClick={() => {
									setStateFiles([]);
									onSetFormData(null);
									setError({ state: false, message: '' });
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default FileUploader;
