import React from 'react';

const Terminos = (props) => {
	return (
		<div>
			<div>
				<h2 className='md:text-[64px] md:leading-[81px] text-[32px] mb-5 font-bold leading-[37px]'>
					{props.title}
				</h2>
				<p className='md:text-[24px] text-[14px] font-normal'>
					CIBERCONTA, S.A.P.I. DE C.V., (En lo sucesivo la “Empresa”) le da la
					bienvenida a ciberconta.com, Ciberconta.mx o cualquiera de sus
					plataformas digitales (En lo sucesivo el “Sitio Web” o “Aplicación”),
					la cual tiene sus oficinas principales en calle Miguel Hidalgo Y
					Costilla, número 310, Col. La Fama, C.P., 66100, Santa Catarina, nuevo
					León, y se extienden los presentes acuerdos de términos de servicio,
					los cuales comprenden sus derechos y obligaciones legales, al entrar y
					hacer uso del sitio web usted acepta todos y cada uno de los acuerdos
					contenidos dentro de la presente, si usted no acepta alguno de los
					términos y condiciones aquí establecidos deberá cesar el uso del Sitio
					Web/Aplicación inmediatamente.
					<br />
					<br />
					Todas las marcas, nombres comerciales, avisos comerciales y sistemas
					registrados y no registrados utilizados en este Sitio Web/Aplicación
					son propiedad de la Empresa o están siento utilizadas con la
					autorización de sus respectivos titulares. Revise estos términos y
					condiciones periódicamente para enterarse de los cambios. El uso
					continuo de este sitio después de la publicación de algún cambio en
					estos términos y condiciones indica que el Usuario acepta dichos
					cambios.
					<br />
					<br />
					<strong>
						LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS
						SERVICIOS.
					</strong>
					<br />
					<br />
					En caso Usted desee acceder y/o suscribirse y/o utilizar el Sitio
					Web/Aplicación a través de este, podrá hacerlo únicamente aceptando
					los Términos y Condiciones y el aviso de privacidad y tratamiento de
					datos personales, las mismas que se encuentran a su alcance en el
					Portal (En lo sucesivo el “Aviso de Privacidad”).
					<br />
					<br />
					En consecuencia, en caso Usted haga uso del Portal y/o de cualquiera
					de los servicios ofrecidos por la Empresa, indicará que ha leído,
					entendido y aceptado todas y cada una de las condiciones establecidas
					en los presentes Términos y Condiciones, así como las condiciones
					indicadas en el Aviso de Privacidad. Asimismo, Usted reconoce que
					todas las transacciones que Usted realice en el Portal y/o utilizando
					los servicios se acogerán a la regulación establecida en los presentes
					Términos y Condiciones, y a la legislación aplicable de los Estados
					Unidos Mexicanos.
					<br />
					<br />
					Mediante su acceso y uso de los Servicios usted acuerda vincularse
					jurídicamente por estas Condiciones, que establecen una relación
					contractual entre usted y CIBERCONTA. Si usted no acepta estas
					Condiciones, no podrá acceder o usar los Servicios. Estas Condiciones
					sustituyen expresamente los acuerdos o compromisos previos con usted.
					En caso de un incumplimiento a los presentes Términos y Condiciones
					por parte de usted, CIBERCONTA, podrá denegarle el acceso a su cuenta
					y/o a los Servicios. Por otro lado, CIBERCONTA, podrá modificar o
					poner fin a estos Términos y Condiciones o a cualquiera de los
					Servicios.
					<br />
					<br />
					<strong>OBJETO</strong>
					<br />
					<br />
					CIBERCONTA es una empresa mexicana enfocada a ofrecer servicios de
					contabilidad a personas físicas y morales por medio de una aplicación
					móvil o cualquier otro medio electrónico disponible, así como la
					prestación y contratación de servicios profesionales tales como
					asesoría, consultoría y análisis, relacionados con los ramos contable
					y fiscal. El objeto es regular el acceso y utilización del contenido,
					productos y/o servicios a disposición del público en general en el
					dominio ciberconta.com, Ciberconta.mx. El titular se reserva el
					derecho de realizar cualquier tipo de modificación en el Sitio
					Web/Aplicación en cualquier momento y sin previo aviso, el usuario
					acepta dichas modificaciones.
					<br />
					<br />
					El acceso al Sitio Web/Aplicación por parte del usuario es libre y
					gratuito, la utilización del contenido, productos y/o servicios
					implica un costo de suscripción para el usuario. El Sitio
					Web/Aplicación solo admite el acceso a personas mayores de edad y no
					se hace responsable por el incumplimiento de esto. Está dirigido a
					usuarios residentes en México y cumple con la legislación establecida
					en dicho país, si el usuario reside en otro país y decide acceder al
					sitio web lo hará bajo su responsabilidad.
					<br />
					<br />
					<strong>SERVICIOS</strong>
					<br />
					<br />
					En CIBERCONTA encontraras dos servicios identificados y descritos de
					la siguiente manera:
					<br />
					<br />
					Servicio de declaración Anual: Plan Rescate Fiscal, el cual consiste
					en el análisis y regulación fiscal En caso de que el Usuario tenga
					obligaciones fiscales pendientes o atrasos en sus declaraciones
					presentadas, se revisan las declaraciones pendientes, y se informa al
					Usuario de las implicaciones correspondientes. Si se determina
					impuestos a pago se le pasa el reporte de pago y una vez hecho el pago
					por parte del Usuario, se emite nuevamente el reporte de opinión para
					entregar al Usuario el resultado positivo sin adeudos.
					<br />
					<br />
					Servicio de declaración Mensual: Plan Fácil Fiscal, cada mes se
					realiza el cortejo de los ingresos y gastos del Usuario con los gastos
					facturados para determinar los impuestos para la declaración del
					usuario.
					<br />
					<br />
					<strong>PAGO Y SUSCRIPCIÓN</strong>
					<br />
					<br />
					Los pagos por lo cual el Usuario deberá de realizar a CIBERCONTA podrá
					variar según el servicio elegido.
					<br />
					<br />
					El Usuario tiene que elegir un programa de servicios y pagar
					previamente las cantidades señaladas en el servicio elegido, cuya
					suscripción de servicios se te proporcionará mensualmente. El cobro se
					realiza de forma domiciliada por medio de pago con tarjeta al momento
					de elegir un Servicio.
					<br />
					<br />
					Cada mes se realiza un cobro el día 1 de cada mes. El servicio será
					prestado únicamente cuando la empresa haya confirmado la realización
					exitosa del pago.
					<br />
					<br />
					<strong>REEMBOLSOS</strong>
					<br />
					<br />
					Al aceptar estos términos y condiciones reconoces que una vez
					realizado el pago o iniciada tu suscripción, éste será por anticipado
					a la prestación de servicios y no podrá ser devuelto por ningún
					motivo.
					<br />
					<br />
					<strong>CANCELACIÓN</strong>
					<br />
					<br />
					En cualquier momento el usuario puede solicitar la cancelación de su
					cuenta en CIBERCONTA.
					<br />
					<br />
					<strong>USO</strong>
					<br />
					<br />
					Al usar, acceder, descargar, instalar, obtener o brindar información
					desde y hacia este Sitio Web/Aplicación, se considerará que los
					Usuarios han leído y aceptado estos Términos y Condiciones (incluyendo
					nuestro Aviso de Privacidad), que se incorpora al presente documento
					en virtud de esta referencia. Asimismo, los Usuarios aceptan ser
					Mayores de Edad por lo que cuentan con todas las facultades y
					capacidad para aceptar estos Términos y Condiciones, la Empresa no se
					hace responsable de la información falsa que brinde el Usuario, ya que
					el Sitio Web/Aplicación es exclusiva para mayores de edad. De la misma
					manera el usuario acepta que cuenta con la mayoría de edad.
					<br />
					<br />
					La Empresa no se hace responsable de la relación del Usuario con
					cualquier tercero sin importar que la Empresa medie servicios entre
					ellos y/o la relación entre el Usuario y el Tercero hayas sido
					concretada por el Sitio Web/Aplicación, incluyendo Sociedades de
					Información Crediticia, Comisionistas, Cobradores, Instituciones
					Financieras o de Crédito o Prestadores de Servicios. El Pago realizado
					por el Usuario únicamente podrá ser utilizado para por los medios que
					indique la Empresa.
					<br />
					<br />
					El material en este Sitio Web/Aplicación es para fines informativos de
					los servicios ofrecidos. Estas Reglas de Uso rigen la utilización del
					sitio de CIBERCONTA y su acceso a éste constituye un contrato
					vinculante o “Acuerdo” por y entre CIBERCONTA, una empresa mexicana
					con sede principal en Monterrey, Nuevo León y el Usuario. Lea este
					Aviso Legal detenidamente. El Aviso Legal tiene carácter obligatorio y
					vinculante para Todos los usuarios. En caso de que el Usuario no esté
					de acuerdo con el Aviso Legal en su totalidad deberá abstenerse de
					utilizar, operar, visitar el Portal y/o de contratar los servicios
					prestados por CIBERCONTA.
					<br />
					<br />
					Al usar este Sitio y/o al inducir el uso de éste electrónicamente o a
					través de otros medios, el Usuario indica que acepta el Acuerdo y que:
					<br />
					<br />
					<p style={{ marginLeft: '35px' }}>
						- Reconoce y acepta que CIBERCONTA específicamente se exime de toda
						responsabilidad, pérdida, daño o riesgo que resulte como
						consecuencia directa o indirecta del Portal o de los Servicios.
						<br />
						<br />- Reconoce y acepta que el uso del Portal está sujeto a los
						términos del Aviso Legal, según se modifiquen periódicamente.
						<br />
						<br />
						<strong>
							- Que sólo utilizará los materiales contenidos en el Portal para
							fines personales, no comerciales e informativos, y que no
							modificará ninguno de los materiales contenidos en el mismo.
						</strong>
					</p>
					<br />
					<br />
					<strong>MAYORÍA DE EDAD</strong>
					<br />
					<br />
					El presente sitio web no está destinado de ninguna forma a menores de
					edad, por consiguiente, el Sitio Web solicitara que establezca la
					mayoría de edad, considerando que se solicitara como promedio la
					mayoría de edad los 18 (dieciocho) años, si en la región en que opere
					el presente Sitio Web la mayoría de edad es más alta y usted no cuenta
					con dicha edad deberá de cerrar el sitio inmediatamente. La compañía
					prohíbe a todas las personas que no cumplan con este requisito acceder
					al Sitio Web/Aplicación.
					<br />
					<br />
					En consecuencia, usted manifiesta lo siguiente al hacer uso y acceder
					al presente Sitio Web/Aplicación.
					<br />
					<br />
					<p style={{ marginLeft: '35px' }}>
						1.Tiene al menos 18 (dieciocho) años, ha alcanzado la mayoría de
						edad en el lugar donde vive y tiene la capacidad legal para celebrar
						este acuerdo.
						<br />
						<br />
						2.No está accediendo al sitio web/aplicación desde un lugar, país o
						ubicación en el que hacerlo podría considerarse una violación de la
						ley. Los Usuarios deben suspender el uso de este Sitio
						Web/Aplicación inmediatamente si no son Mayores de Edad, no están de
						acuerdo o no aceptan todos estos Términos y Condiciones. La Empresa
						se reserva el derecho de eliminar o prohibir a cualquier Usuario la
						utilización de este Sitio Web/Aplicación a su sola discreción.
					</p>
					<br />
					<br />
					<strong>CONDUCTA DEL USUARIO</strong>
					<br />
					<br />
					El Usuario acepta cumplir todas las leyes locales, estatales,
					nacionales e internacionales pertinentes, reglamentos, ordenanzas,
					normas y reglas y es el único responsable por todas las acciones u
					omisiones que ocurran como consecuencia del uso o acceso al Portal
					incluyendo y sin limitaciones, el contenido de sus transmisiones a
					través de o hacia los sitios web de CIBERCONTA.
					<br />
					<br />
					Queda expresamente prohibido utilizar el Portal y los servicios para
					propósitos ilegales, inmorales, obscenos o prohibidos por la costumbre
					y la legislación aplicable y/o por el presente Aviso Legal, provocar
					modificaciones, alteraciones y/o supresiones, realizados por medios
					electrónicos o de cualquier otra forma, empleando recursos lícitos o
					ilícitos, que puedan interferir en la administración u operación del
					Portal y/o en los Servicios prestados por CIBERCONTA.
					<br />
					<br />
					El Usuario será responsable de los daños y perjuicios que se causen a
					CIBERCONTA derivados de sus actos o de aquellos que provengan de sus
					funcionarios, empleados, factores, dependientes y/o persona física o
					moral asociada, subsidiaria y/o filial. El Usuario es el único
					responsable de la información que proporciona a CIBERCONTA, CIBERCONTA
					no se hace responsable que los datos e información que provea el
					Usuario sean falsos, anómalos o inconsistentes, o que por ignorancia o
					desconocimiento de las disposiciones o normatividad jurídica
					tributaria emita documentos fiscales que presenten datos errados,
					inexactos o alterados y traiga como consecuencia un procesamiento de
					datos incorrecto de las obligaciones tributarias del Usuario, EL
					USUARIO EXPRESAMENTE, LIBERA DE TODA RESPONSABILIDAD CIVIL,
					CONTRACTUAL, PENAL Y/O SOLIDARIA A KONTA, RESPECTO DE CUALQUIER DAÑO O
					PERJUICIO QUE DICHOS ACTOS PUDIERAN CAUSARLE.
					<br />
					<br />
					<strong>CUENTAS DE USUARIO</strong>
					<br />
					<br />
					La Empresa puede, a su sola discreción, brindar acceso a los Usuarios
					a porciones restringidas de este Sitio Web/Aplicación, incluyendo,
					entre otros, una o más cuentas de Usuario donde pueda brindarse y/u
					obtenerse la información y servicios específicos de los clientes.
					<br />
					<br />
					Los Usuarios que entren a dichas ubicaciones podrían estar sujetos a
					Términos y Condiciones adicionales según se especifique en relación
					con los servicios proporcionados. Los Usuarios con cuentas de servicio
					son responsables exclusivos de preservar la confidencialidad de toda
					información de acceso, la información de la cuenta del Usuario y todas
					las acciones u omisiones vinculadas con dicha cuenta por lo que, en
					todo momento, el Usuario es el responsable único y final de mantener
					en secreto sus claves de acceso con la cual tenga acceso a el Sitio
					Web/Aplicación.
					<br />
					<br />
					El Usuario podrá contratar un determinado número de unidades, las
					cuales se señalan en la caratula del presente contrato.
					<br />
					<br />
					<strong>AVISO DE PRIVACIDAD Y PROTECCIÓN DE DATOS</strong>
					<br />
					<br />
					Conforme a lo establecido en la Ley Federal de Protección de Datos
					Personales en Posesión de Particulares, el titular debe comprometer a
					tomar las medidas necesarias que garanticen la seguridad del usuario,
					evitando que se haga uso indebido de los datos personales que el
					usuario proporcione en el sitio web.
					<br />
					<br />
					El titular corroborará que los datos personales contenidos en las
					bases de datos sean correctos, verídicos y actuales, así como que se
					utilicen únicamente con el fin con el que fueron recabados.
					<br />
					<br />
					El Aviso de privacidad en relación con cualquier información obtenida
					por la Empresa a través de este Sitio Web/Aplicación puede consultarse
					en la sección Aviso de Privacidad del Sitio Web de la Empresa. Pueden
					tener validez algunas reglas adicionales en materia de privacidad
					según se establece en las funciones de este Sitio Web/Aplicación
					restringidas para servicios específicos del Usuario.
					<br />
					<br />
					<strong>PUBLICIDAD</strong>
					<br />
					<br />
					La Empresa no recomienda, patrocina ni promociona negocios, servicios
					o productos de terceros, a excepción de manifestaciones de
					recomendación o patrocinio que la Empresa realice de manera expresa,
					si las hubiere, en este Sitio Web/Aplicación. Si el Sitio
					Web/Aplicación proporciona información sobre terceros o proporciona
					contenido de terceros, e incluso enlaces a sitios web de terceros, la
					Empresa no será responsable de ningún daño o perjuicio relacionado con
					cualquier información de terceros, aunque ésta contenga errores o
					equivocaciones. Asimismo, la Empresa no será responsable ni estará
					sujeta a acción legal por los servicios o productos de terceros.
					Consulte también la sección en materia de enlaces de nuestro Aviso de
					Privacidad, incluido el descargo de responsabilidad por los
					contendidos de terceros.
					<br />
					<br />
					<strong>COOKIES</strong>
					<br />
					<br />
					Con el fin de ofrecerle un servicio más personalizado y responsable,
					almacenamos información sobre cómo utiliza este sitio web. Esto se
					hace mediante un pequeño archivo de texto llamado cookie. Las cookies
					contienen pequeñas cantidades de información y se descargan en su
					ordenador u otro dispositivo por un servidor de este sitio web. Su
					navegador envía estas cookies de nuevo a este sitio web en cada visita
					posterior para que pueda reconocerle y recordar cosas como las
					preferencias de usuario.
					<br />
					<br />
					Cada vez que se utiliza este sitio web, la información puede ser
					almacenada mediante el uso de cookies y otras tecnologías. Al utilizar
					este sitio web, usted acepta el uso de cookies, como se describe en
					este aviso de privacidad y en nuestros términos y condiciones, así
					como para el uso de cookies en otro país, región o sitios web
					específicos contenidos en sitio web, tal como se describe en el
					presente aviso de privacidad.
					<br />
					<br />
					<strong>POLÍTICA DE PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>
					<br />
					<br />
					El titular manifiesta tener los derechos de propiedad intelectual e
					industrial del sitio web/aplicación incluyendo imágenes, archivos de
					audio o video, logotipos, marcas, colores, estructuras, tipografías,
					diseños y demás elementos que lo distinguen, protegidos por la
					legislación mexicana e internacional en materia de propiedad
					intelectual e industrial.
					<br />
					<br />
					El usuario se compromete a respetar los derechos de propiedad
					industrial e intelectual del titular pudiendo visualizar los elementos
					del sitio web, almacenarlos, copiarlos e imprimirlos exclusivamente
					para uso personal.
					<br />
					<br />
					<strong>LEGISLACIÓN Y JURISDICCIÓN APLICABLE</strong>
					<br />
					<br />
					Este acuerdo y todos los asuntos que se originen a partir de este
					acuerdo o que estén relacionados con el mismo, deberán regirse por las
					leyes de México, a pesar de su selección de normas legales. Las partes
					aceptan que toda acción legal o demanda relacionada con este acuerdo
					debe levantarse y presentarse en los tribunales de CIBERCONTA y las
					partes voluntariamente se someten a la jurisdicción de dichos
					tribunales para tales propósitos.
				</p>
			</div>
		</div>
	);
};

export default Terminos;
