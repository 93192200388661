import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FileUploader from '../../../../common/FileUploader';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import {
	monthNames,
	getCurrentAndLastFiveYears,
} from '../../../../../constants/constants';
import {
	changeKeysToLabel,
	getClientStatusId,
} from '../../../../../utils/validation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getAllUsers } from '../../../../api/users';
import { addClientStatus } from '../../../../api/clientStatus';
import classes from './DetallePrevio.module.css';
import StatusIndicator from '../Common/StatusIndicator';
import { uploadFiles } from '../../../../api/userfiles';
import generals from '../../../../../css/Generals.module.css';
import CloseIcon from '@mui/icons-material/Close';

const RegistroDeclaración = () => {
	const [declaracion, setDeclaracion] = useState({});
	const [formData, setFormData] = useState(null);
	const [users, setUsers] = useState([]);
	const [registro, setRegistro] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ state: false, message: '' });
	const [showAnimation, setShowAnimation] = useState(false);
	const [warningDialog, setWarningDialog] = useState(false);
	const types = ['normal', 'complementaria', 'anual', 'otro'];
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const years = getCurrentAndLastFiveYears();

	const firstColumn = [
		{ label: 'Ingresos ISR', id: 'Ingresos_ISR' },
		{ label: 'Gastos ISR', id: 'gastos_ISR' },
		{ label: 'Utilidad ISR', id: 'utilidad_ISR' },
		{ label: 'Porcentaje ISR', id: 'porcentaje_ISR' },
		{ label: 'Retención ISR', id: 'retencion_ISR' },
	];
	const secondColumn = [
		{ label: 'Por Pagar IVA', id: 'porPagar_IVA' },
		{ label: 'Acreditable IVA', id: 'acreditable_IVA' },
		{ label: 'Retención IVA', id: 'retencion_IVA' },
		{ label: 'Saldo a Favor IVA', id: 'saldoFavor_IVA' },
		{ label: 'Total', id: 'total' },
	];

	// Clear State
	useEffect(() => {
		setLoading(false);
		setDeclaracion({});
		setRegistro(false);
		setError({ state: false, message: '' });

		async function fetchUsers() {
			const users = await getAllUsers(TOKEN);
			if (users) {
				const filteredUsers = await changeKeysToLabel(users);
				setUsers(filteredUsers);
				setShowAnimation(true);
			}
		}

		fetchUsers();
	}, [TOKEN]);

	const handleDeclarationBody = async () => {
		let response = {};
		const formattedClientStatusId = await getClientStatusId(
			declaracion['clientId'],
			declaracion['mesRegistro'],
			declaracion['añoRegistro']
		);
		for (let elem of firstColumn) {
			response[elem.id] = parseFloat(declaracion[elem.id]) || 0;
		}
		for (let elem of secondColumn) {
			response[elem.id] = parseFloat(declaracion[elem.id]) || 0;
		}
		response['clientStatusId'] = formattedClientStatusId || '';
		response['clientId'] = declaracion['clientId'] || '';
		response['mesRegistro'] = declaracion['mesRegistro'] || '';
		response['añoRegistro'] = declaracion['añoRegistro'] || '';
		response['fechalimite'] = declaracion['fechalimite'] || '';
		response['createdBy'] = USERID;
		response['estatus'] = 'pendiente';
		response['clientStatusType'] = declaracion['clientStatusType'] || 'normal';

		return response;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const allFieldsCompleted =
			firstColumn.every((item) => declaracion[item.id]) &&
			secondColumn.every((item) => declaracion[item.id]) &&
			declaracion['mesRegistro'] &&
			declaracion['añoRegistro'];

		if (allFieldsCompleted && formData) {
			setLoading(true);

			const response = await uploadFiles(TOKEN, formData);
			if (response?.ok) {
				const body = await handleDeclarationBody();
				if (body) {
					try {
						const response = await addClientStatus(TOKEN, body);
						if (response.status === 200) {
							setError({ state: false, message: '' });
							setLoading(false);
						} else {
							setLoading(false);
							errorTryAgain();
						}
						cleanUp();
						window.scrollTo({ top: 0, left: 0 });
					} catch (error) {
						cleanUp();
						errorTryAgain();
						console.error('Error:', error);
					}
				}
			} else {
				const errorResponse = await response.json();
				console.error('Error uploading files:', errorResponse);
				cleanUp();
				errorTryAgain();
			}
		} else {
			setLoading(false);
			setDeclaracion({});
			setError({
				state: true,
				message:
					'Algo salió mal. Revise que todos los campos estén completos. ',
			});
		}
	};

	const cleanUp = () => {
		setLoading(false);
		setDeclaracion({});
		setRegistro(true);
	};

	const errorTryAgain = () => {
		setError({
			state: true,
			message: 'Algo salió mal. Favor de volver a intentar.',
		});
	};

	const handleCloseWarning = () => {
		setWarningDialog(false);
	};

	return (
		<>
			<div className='relative'>
				<div className='w-full flex gap-[20px] items-center'>
					<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
						Detalles previos
					</h2>
				</div>
				<h3 className={generals.page_subtitle}>
					Módulo para registro de detalles previos de declaración{' '}
				</h3>
				<StatusIndicator
					loading={loading}
					registro={registro}
					error={error}
					setRegistro={setRegistro}
				/>
				<form action='' onSubmit={handleSubmit}>
					<div className={classes.columns_input_wrapper} key='userRFC'>
						<Autocomplete
							key={registro}
							disablePortal
							options={users}
							className={classes.columns_input_element}
							getOptionSelected={(option, value) => option.id === value.id}
							getOptionLabel={(option) => option.label}
							renderInput={(params) => (
								<TextField
									required
									className={classes.filters_input_box}
									{...params}
									label='Seleccionar RFC'
								/>
							)}
							onChange={(e, newValue) => {
								setDeclaracion((prevState) => ({
									...prevState,
									clientId: newValue?.id || '',
									rfc: newValue?.label || '',
									subscription: newValue?.subscription || '',
									anualPlan: newValue?.anualPlan || '',
								}));
								if (
									newValue?.subscription !== 'activa' &&
									!newValue?.anualPlan &&
									newValue?.id
								) {
									setWarningDialog(true);
								} else {
									setWarningDialog(false);
								}
							}}
						/>
					</div>
					<div className={classes.columns_input_wrapper} key='mesRegistro'>
						<Autocomplete
							key={registro}
							disablePortal
							options={monthNames}
							className={classes.columns_input_element}
							renderInput={(params) => (
								<TextField
									required
									className={classes.filters_input_box}
									{...params}
									label='Selecciona mes de la declaración'
								/>
							)}
							onChange={(e, newValue) =>
								setDeclaracion((prevState) => ({
									...prevState,
									mesRegistro: newValue,
								}))
							}
						/>
					</div>
					<div className={classes.columns_input_wrapper} key='añoRegistro'>
						<Autocomplete
							key={registro}
							disablePortal
							options={years}
							className={classes.columns_input_element}
							renderInput={(params) => (
								<TextField
									required
									className={classes.filters_input_box}
									{...params}
									label='Selecciona año de la declaración'
								/>
							)}
							onChange={(e, newValue) =>
								setDeclaracion((prevState) => ({
									...prevState,
									añoRegistro: newValue,
								}))
							}
						/>
					</div>
					<div className={classes.columns_input_wrapper} key='clientStatusType'>
						<Autocomplete
							key={registro}
							disablePortal
							options={types}
							className={classes.columns_input_element}
							renderInput={(params) => (
								<TextField
									required
									className={classes.filters_input_box}
									{...params}
									label='Selecciona el tipo de declaración'
								/>
							)}
							onChange={(e, newValue) =>
								setDeclaracion((prevState) => ({
									...prevState,
									clientStatusType: newValue,
								}))
							}
						/>
					</div>
					<div className={classes.columns_wrapper}>
						<div className='w-full'>
							{firstColumn.map((item) => (
								<div className={classes.columns_input_wrapper} key={item.id}>
									<h2 className={classes.filters_subtitle}>{item.label}</h2>
									<TextField
										key={registro}
										required
										className={classes.filters_input_box}
										id={item.id}
										placeholder={item.label}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													{item.id === 'porcentaje_ISR' ? '%' : '$'}
												</InputAdornment>
											),
										}}
										value={declaracion[item.id] || ''}
										onChange={(e) => {
											let formatted = e.target.value
												.replace(/[^0-9.]/g, '')
												.replace(/\./g, '');
											const dotPosition = formatted.length - 1;
											let money = formatted;
											if (formatted.length > 2) {
												money =
													formatted.substring(0, dotPosition - 1) +
													'.' +
													formatted.substring(dotPosition - 1);
											}
											setDeclaracion((prevState) => ({
												...prevState,
												// prettier-ignore
												[item.id]:  money,
											}));
										}}
										variant='outlined'
									/>
								</div>
							))}
						</div>
						<div className='w-full'>
							{secondColumn.map((item) => (
								<div className={classes.columns_input_wrapper} key={item.id}>
									<h2 className={classes.filters_subtitle}>{item.label}</h2>
									<TextField
										key={registro}
										required
										placeholder={item.label}
										className={classes.filters_input_box}
										id={item.id}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>$</InputAdornment>
											),
										}}
										value={declaracion[item.id] || ''}
										onChange={(e) => {
											let formatted = e.target.value
												.replace(/[^0-9.]/g, '')
												.replace(/\./g, '');
											const dotPosition = formatted.length - 1;
											let money = formatted;
											if (formatted.length > 2) {
												money =
													formatted.substring(0, dotPosition - 1) +
													'.' +
													formatted.substring(dotPosition - 1);
											}
											setDeclaracion((prevState) => ({
												...prevState,
												// prettier-ignore
												[item.id]:  money.toLocaleString(),
											}));
										}}
										variant='outlined'
									/>
								</div>
							))}
							<div className={classes.columns_input_wrapper}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<h2 className={classes.filters_subtitle}>Fecha límite</h2>
									<DatePicker
										key={registro}
										placeholder=''
										className={classes.filters_input_box}
										onChange={(newValue) => {
											const date = new Date(newValue);
											const day = String(date.getUTCDate()).padStart(2, '0');
											const month = String(date.getUTCMonth() + 1).padStart(
												2,
												'0'
											);
											const year = String(date.getUTCFullYear()).slice(-2);
											const fechaLimite = `${day}-${month}-${year}`;

											setDeclaracion((prevState) => ({
												...prevState,
												fechalimite: fechaLimite,
											}));
										}}
									/>
								</LocalizationProvider>
							</div>
						</div>
					</div>
					{declaracion['rfc'] && (
						<div className={classes.fileuploader_wrapper}>
							<FileUploader
								userId={declaracion['rfc']}
								onSetFormData={setFormData}
								validExtensions={['pdf']}
								nameConvention={
									declaracion['clientStatusType'] === 'anual'
										? '_DETALLE_ANUAL_'
										: '_DETALLE_'
								}
								includeDate={true}
								month={declaracion['mesRegistro']}
								year={declaracion['añoRegistro']}
							/>
						</div>
					)}
					<div className='flex items-center flex-col md:flex-row gap-2 justify-between mt-7'>
						<button
							type='submit'
							className='py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]'
						>
							Registrar Declaración
						</button>
						{error.state && (
							<p
								style={{
									fontSize: '1.2rem',
									color: '#DB6363',
									marginTop: '1.2rem',
								}}
							>
								{error.message}
							</p>
						)}
					</div>
				</form>
			</div>
			{warningDialog && (
				<div
					id='noSubscription'
					className={
						showAnimation
							? `${generals.slide_up} ${generals.slide_up_show} ${generals.modal_error_wrapper}`
							: `${generals.slide_up}  ${generals.modal_error_wrapper}`
					}
				>
					<div className={generals.modal_error}>
						<p>Este usuario no está subscrito a ningún plan de ciberconta.</p>
						<button onClick={handleCloseWarning}>
							<CloseIcon sx={{ fontSize: 25, color: '#fff' }} />
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default RegistroDeclaración;
