import React from 'react';
import styles from '../css/DeclaracionesItem.module.css';
import EastIcon from '@mui/icons-material/East';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import $ from 'jquery';

const DeclaracionesItem = ({
	title,
	currentStatus,
	declaration,
	setModalYear,
	setSelectedYear,
}) => {
	const handleOpen = () => {
		$('body').css('overflow', 'hidden');
		setSelectedYear(declaration.declarationYear);
		setModalYear(true);
	};
	return (
		<button onClick={handleOpen} className={`mb-[7px] ${styles.item}`}>
			<div className={`${styles.item_status_wrapper}`}>
				<h2
					className={
						currentStatus == 'vigente' || currentStatus == 'expirado'
							? `${styles.detail_status_error}`
							: `${styles.detail_status}`
					}
				>
					{currentStatus == 'vigente' || currentStatus == 'expirado'
						? 'Pagos pendientes'
						: 'Al corriente'}
				</h2>
			</div>
			<div className={`${styles.item_body_wrapper}`}>
				<div className={`${styles.item_text_wrapper}`}>
					<FolderOpenIcon
						className={styles.item_icon}
						sx={{
							fontSize: 25,
							color: '#4285f4',
						}}
					/>
					<p className={`${styles.item_body_text}`}>{title}</p>
				</div>
				<div className={styles.item_icon_wrapper}>
					<div className={styles.detail_element_seemore}>
						<EastIcon
							sx={{
								fontSize: 25,
								color: '#fff',
							}}
						/>
					</div>
				</div>
			</div>
		</button>
	);
};

export default DeclaracionesItem;
