import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsXLg } from 'react-icons/bs';
import classes from '../css/FacturasUploader.module.css';
import '../../../../../../style.css';

const FacturasCancelUploader = ({
	userRFC,
	onSetFormData,
	validExtensions,
	nameConvention,
	folioFiscal,
}) => {
	const [stateFiles, setStateFiles] = useState([]);
	const [error, setError] = useState({ state: false, message: '' });

	const validateFileType = (fileName) => {
		const validExtensionsFromProps = validExtensions;
		const fileExtension = fileName.split('.').pop().toLowerCase();
		return validExtensionsFromProps.includes(fileExtension);
	};

	const onDrop = async (acceptedFiles) => {
		if (!acceptedFiles || acceptedFiles.length === 0) {
			console.log('No files selected');
			return;
		}

		const addedfiles = Array.isArray(acceptedFiles)
			? acceptedFiles
			: [acceptedFiles];

		try {
			const formData = new FormData();
			for (let selectedFile of addedfiles) {
				if (validateFileType(selectedFile.name)) {
					const fileDetailName =
						userRFC +
						nameConvention +
						folioFiscal.toUpperCase() +
						'.' +
						selectedFile.name.split('.').pop().toLowerCase();

					const file = new File([selectedFile], fileDetailName, {
						type: selectedFile.type,
					});

					formData.append('file[]', file);
					const files = [...stateFiles, ...addedfiles];
					setStateFiles(files);
					onSetFormData(formData);
				} else {
					setError({
						state: true,
						message: 'Tipo de archivo inválido.',
					});
					setTimeout(() => {
						setError({ state: false, message: '' });
					}, 3000);
					return;
				}
			}
		} catch (error) {
			console.error('Error uploading files:', error);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div className={classes.fileBoxWrapper}>
			<div className={classes.fileBox} {...getRootProps()}>
				<button onClick={() => setError({ state: false, message: '' })}>
					<input {...getInputProps()} />
					{isDragActive ? (
						<div>
							<p>Archivos aquí...</p>
						</div>
					) : (
						<p>
							Arrastra y suelta o haz{' '}
							<span className={classes.button_ClickUpload}>clic</span> para
							seleccionar archivos{' '}
							{validExtensions?.map((extension) => (
								<strong key={extension}>{extension} </strong>
							))}
						</p>
					)}
				</button>
			</div>

			{error.state && (
				<p className={classes.fileUploader_error}>{error.message}</p>
			)}
			<div className={classes.fileNameBoxWrapper}>
				{stateFiles?.map((file) => {
					return (
						<div className={classes.fileNameBox} key={file.name}>
							{file.name}
							<BsXLg
								onClick={() => {
									setStateFiles([]);
									onSetFormData(null);
									setError({ state: false, message: '' });
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default FacturasCancelUploader;
