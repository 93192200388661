import React, { useEffect, useState } from 'react';
import classes from '../css/DeclaracionesModal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import generals from '../../../../../../css/Generals.module.css';
import { useSelector } from 'react-redux';
import { monthStatusInitial } from '../constant/constant';
import $ from 'jquery';
import {
	getExistingUserFiles,
	getUserFiles,
} from '../../../../../api/userfiles';
import { editSatDeclarationStatus } from '../../../../../api/satDeclarations';
import Loading from '../../../../../common/Loading';
import {
	formatMexicoTz,
	roundToTwoDecimals,
} from '../../../../../../utils/format';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

const DeclaracionesModalYear = ({
	setModalYear,
	setRefetch,
	selectedYear,
	declarations,
}) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const { userRFC } = useSelector((state) => state.clientStatus);
	const [message, setMessage] = useState({ state: false, msg: '' });
	const [loading, setLoading] = useState(false);
	const [haveYouPaid, setHaveYouPaid] = useState(false);
	const [pdfView, setPdfView] = useState(false);
	const [existingPDF, setExistingPDF] = useState(false);
	const [downloadUrlPDF, setDownloadUrlPDF] = useState('');
	const [existingPNG, setExistingPNG] = useState(false);
	const [downloadUrlPNG, setDownloadUrlPNG] = useState('');
	const [currentDeclaration, setCurrentDeclaration] = useState('');
	const [monthDeclarations, setMonthDeclarations] =
		useState(monthStatusInitial);

	let currentDate = new Date();

	useEffect(() => {
		let newRegister = [...monthStatusInitial];
		for (let key in declarations) {
			const effectiveDate = new Date(declarations[key]['effectiveDate']);

			// Anual declaration
			if (
				selectedYear === declarations[key]['declarationYear'] &&
				declarations[key]['declarationType'] === 'anual'
			) {
				newRegister[0] = {
					title: 'Anual',
					month: declarations[key]['declarationMonth'],
					amount: declarations[key]['payment'],
					currentStatus:
						effectiveDate <= currentDate &&
						declarations[key]['currentStatus'] === 'vigente'
							? 'expirado'
							: declarations[key]['currentStatus'],
					effectiveDate: declarations[key]['effectiveDate'],
					paidDate: declarations[key]['paidDate'],
					id: declarations[key]['id'],
					reactivate: declarations[key]['reactivate'] || false,
				};
			}
			// Month declarations
			let index = monthStatusInitial.findIndex(
				(x) =>
					x.month === declarations[key]['declarationMonth'] &&
					selectedYear === declarations[key]['declarationYear'] &&
					(declarations[key]['declarationType'] === 'normal' ||
						declarations[key]['declarationType'] === 'complementaria')
			);

			if (index !== -1) {
				newRegister[index] = {
					month: declarations[key]['declarationMonth'],
					amount: declarations[key]['payment'],
					currentStatus:
						effectiveDate <= currentDate &&
						declarations[key]['currentStatus'] === 'vigente'
							? 'expirado'
							: declarations[key]['currentStatus'],
					effectiveDate: declarations[key]['effectiveDate'],
					paidDate: declarations[key]['paidDate'],
					id: declarations[key]['id'],
					reactivate: declarations[key]['reactivate'] || false,
				};
			}
		}
		setMonthDeclarations(newRegister);
	}, []);

	const handleUpdateDeclaration = async (id, updateStatus) => {
		let updateBody;
		if (updateStatus === 'pagado') {
			updateBody = {
				currentStatus: updateStatus,
				paidDate: currentDate,
				reactivate: false,
			};
		} else {
			updateBody = {
				currentStatus: updateStatus,
			};
		}
		try {
			setLoading(true);
			const response = await editSatDeclarationStatus(TOKEN, id, updateBody);
			if (response.status === 200) {
				setLoading(false);
				if (updateStatus === 'pagado') {
					let updatedDeclarations = [...monthDeclarations];
					let index = updatedDeclarations.findIndex((x) => x.id === id);
					updatedDeclarations[index].currentStatus = 'pagado';
					setMonthDeclarations(updatedDeclarations);
					setRefetch((prevState) => !prevState);

					setMessage({
						state: 'success',
						msg: 'Gracias por confirmar tu pago.',
						subtext:
							'Mantener registro de estas operaciones nos permite facilitar tu contabilidad fiscal.',
					});
				}
			} else {
				setLoading(false);
				setMessage({
					state: 'error',
					msg: 'Algo salió mal.',
					subtext: 'Favor de volver a intentar más tarde.',
				});
			}
		} catch (error) {
			console.error(error);
			setLoading(false);
			setMessage({
				state: 'error',
				msg: 'Algo salió mal.',
				subtext: 'Favor de volver a intentar más tarde.',
			});
		}
	};

	const handleReactivateDeclaration = async (id) => {
		try {
			setLoading(true);
			const response = await editSatDeclarationStatus(TOKEN, id, {
				reactivate: true,
			});
			if (response.status === 200) {
				setRefetch((prevState) => !prevState);
				let updatedDeclarations = [...monthDeclarations];
				let index = updatedDeclarations.findIndex((x) => x.id === id);
				updatedDeclarations[index].currentStatus = 'expirado';
				updatedDeclarations[index].reactivate = true;
				setMonthDeclarations(updatedDeclarations);
				setLoading(false);
				setMessage({
					state: 'success',
					msg: 'Nuestro equipo ha sido notificado.',
					subtext:
						'Nos pondremos en contacto contigo lo más rápido posible para que puedas realizar el pago pendiente de esta declaración.',
				});
			} else {
				setLoading(false);
				setMessage({
					state: 'error',
					msg: 'Algo salió mal.',
					subtext: 'Favor de volver a intentar más tarde.',
				});
			}
		} catch (error) {
			console.error(error);
			setLoading(false);
			setMessage({
				state: 'error',
				msg: 'Algo salió mal.',
				subtext: 'Favor de volver a intentar más tarde.',
			});
		}
	};

	const handleClose = () => {
		const dialogOpen = document.querySelectorAll('div[role="dialog"]');
		const presentationOpen = document.querySelectorAll('[role="presentation"]');
		if (dialogOpen.length <= 0 && presentationOpen.length <= 0) {
			$('body').css('overflow', '');
			setModalYear(false);
		}
	};

	const downloadFiles = async () => {
		if (downloadUrlPDF) window.open(downloadUrlPDF);
	};

	const retrieveFiles = async (x) => {
		let nomenclature = '_DECLARACIÓN_';
		if (x.title === 'Anual') {
			nomenclature = '_DECLARACIÓN_ANUAL_';
		}

		const fileNamePDF =
			userRFC + nomenclature + x.month + selectedYear + '.pdf';
		const fileNamePNG =
			userRFC + nomenclature + x.month + selectedYear + '.png';

		const fetchPDF = async () => {
			const response = await getUserFiles(TOKEN, fileNamePDF);
			if (response.status === 'SUCCESS') {
				setDownloadUrlPDF(response.data);
			} else {
				setMessage({
					state: 'error',
					msg: 'Algo salió mal.',
					subtext: 'Favor de volver a intentar más tarde.',
				});
			}
		};

		const fetchPNG = async () => {
			const response = await getUserFiles(TOKEN, fileNamePNG);
			if (response.status === 'SUCCESS') {
				setDownloadUrlPNG(response.data);
				setLoading(false);
			} else {
				setLoading(false);
				setMessage({
					state: 'error',
					msg: 'Algo salió mal.',
					subtext: 'Favor de volver a intentar más tarde.',
				});
			}
		};

		const getFiles = async () => {
			try {
				setLoading(true);
				setExistingPDF(false);
				setExistingPNG(false);
				const responsePNG = await getExistingUserFiles(TOKEN, fileNamePNG);
				const resultDataPNG = responsePNG?.data?.data;
				if (resultDataPNG?.length > 0) {
					setExistingPNG(true);
					fetchPNG();
				} else {
					setLoading(false);
				}

				const responsePDF = await getExistingUserFiles(TOKEN, fileNamePDF);
				const resultDataPDF = responsePDF?.data?.data;
				if (resultDataPDF?.length > 0) {
					setExistingPDF(true);
					fetchPDF();
				} else {
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
				setMessage({
					state: 'error',
					msg: 'Algo salió mal.',
					subtext: 'Favor de volver a intentar más tarde.',
				});
			}
		};
		getFiles();
	};

	const buttonReturn = () => {
		return (
			<button
				onClick={() => {
					setHaveYouPaid(false);
					setMessage({ state: false });
					setPdfView(false);
					setCurrentDeclaration('');
				}}
				className={`my-4 ${generals.button_white}`}
			>
				Regresar
			</button>
		);
	};

	return (
		<div className={classes.modal_overlay}>
			<ClickOutsideReact onClickOutside={handleClose}>
				<div className={classes.modal_container}>
					<button className={classes.icon_close_wrapper} onClick={handleClose}>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>{selectedYear}</h1>
					<h2 className={classes.modal_subtitle}>Historial de declaraciones</h2>
					{!pdfView && (
						<div className={classes.modal_info_container}>
							{!haveYouPaid && !message.state && (
								<div className={classes.modal_info_element}>
									{monthDeclarations.map((x) => (
										<div
											onClick={() => {
												setCurrentDeclaration(x);
												retrieveFiles(x);
												setPdfView(true);
											}}
											key={x.title || x.month}
											className={classes.modal_info_user}
										>
											<div className={classes.modal_info_element_title}>
												<h1>{x.title || x.month}</h1>
											</div>

											<div className='flex gap-6 items-center'>
												{(x.currentStatus === 'vigente' ||
													(x.currentStatus === 'expirado' &&
														!x.reactivate)) && (
													<button
														onClick={(e) => {
															e.stopPropagation();
															if (x.currentStatus === 'expirado') {
																handleUpdateDeclaration(x.id, 'expirado');
															}
															setCurrentDeclaration(x);
															setHaveYouPaid(true);
														}}
														className={`${classes.modal_info_warning_payment}`}
													>
														¿Ya pagaste?
													</button>
												)}
												{x.currentStatus === 'expirado' && x.reactivate && (
													<div
														onClick={(e) => {
															e.stopPropagation();
															setMessage({
																state: 'success',
																msg: 'Nuestro equipo ha sido notificado.',
																subtext:
																	'Nos pondremos en contacto contigo lo más rápido posible para que puedas realizar el pago pendiente de esta declaración.',
															});
														}}
														className={`${classes.modal_info_warning_payment}`}
													>
														Reactivación en trámite
													</div>
												)}
												<h1
													className={
														x.currentStatus === 'vigente' ||
														(x.currentStatus === 'expirado' && !x.reactivate)
															? classes.modal_info_element_error
															: classes.modal_info_element_subtitle
													}
												>
													${roundToTwoDecimals(x.amount)}
												</h1>
											</div>
										</div>
									))}
								</div>
							)}
							{loading && !message.state && (
								<section className='h-[300px] flex flex-col gap-2 justify-center items-center'>
									<Loading loading={loading} />
								</section>
							)}
							{!loading && message.state && (
								<div className='h-[300px] flex flex-col gap-2 justify-center items-center'>
									<h1 className={generals.modal_success_text}>{message.msg}</h1>
									<h2 className={`mb-5 ${generals.modal_success_subtitle}`}>
										{message.subtext}
									</h2>
									{buttonReturn()}
								</div>
							)}
							{haveYouPaid &&
								currentDeclaration.currentStatus === 'vigente' &&
								!loading &&
								!message.state && (
									<div className='flex flex-col gap-2'>
										<h1 className={`mb-5 ${generals.modal_success_text}`}>
											¿Ya pagaste la declaración <br></br>{' '}
											{currentDeclaration.month} {selectedYear}?
										</h1>
										<button
											onClick={() =>
												handleUpdateDeclaration(currentDeclaration.id, 'pagado')
											}
											className={`${generals.button_blue}`}
										>
											Ya pagué
										</button>
										{buttonReturn()}
									</div>
								)}
							{haveYouPaid &&
								currentDeclaration.currentStatus === 'expirado' &&
								!loading &&
								!message.state && (
									<div className='flex flex-col gap-2 items-center'>
										<h1 className={`mb-5 ${generals.modal_success_text}`}>
											La línea de captura de esta declaración ha expirado.{' '}
											{'\n'}
										</h1>
										<h2 className={`mb-5 ${generals.modal_success_subtitle}`}>
											Para que puedas pagar, es necesario reactivarla.
											Selecciona la opción "reactivar línea" para notificar a
											nuestro equipo.
										</h2>
										<button
											onClick={() =>
												handleReactivateDeclaration(
													currentDeclaration.id,
													'expirado'
												)
											}
											className={`${generals.button_blue}`}
										>
											Reactivar línea de captura
										</button>

										{buttonReturn()}
									</div>
								)}
						</div>
					)}
					{pdfView && (
						<div className='flex flex-col gap-2 items-center'>
							<div className={classes.modal_info_payment_wrapper}>
								{existingPNG && existingPDF && (
									<>
										{' '}
										<h2>
											Límite de pago:{' '}
											{formatMexicoTz(currentDeclaration.effectiveDate) ||
												'N/A'}
										</h2>
										<h2>
											{currentDeclaration.title || currentDeclaration.month}{' '}
											{selectedYear}{' '}
										</h2>{' '}
									</>
								)}
							</div>

							<div className={classes.modal_info_img_wrapper}>
								{existingPNG && !loading && <img src={downloadUrlPNG} />}

								{!existingPNG && !loading && (
									<div className='flex flex-col items-center gap-[25px]'>
										<ImageNotSupportedOutlinedIcon
											sx={{ fontSize: 50, color: '#4285f4' }}
										/>
										<h1 className={generals.modal_success_text}>
											No hay imagen previa disponible.
										</h1>
									</div>
								)}
								{loading && !message.state && <Loading loading={loading} />}
								{!loading && message.state && (
									<div className='h-[300px] flex flex-col gap-2 justify-center items-center'>
										<h1 className={generals.modal_success_text}>
											{message.msg}
										</h1>
										<h2 className={`mb-5 ${generals.modal_success_subtitle}`}>
											{message.subtext}
										</h2>
										{buttonReturn()}
									</div>
								)}
							</div>

							{existingPDF && (
								<button
									onClick={downloadFiles}
									className={`${generals.button_blue}`}
								>
									Descargar declaración
								</button>
							)}
							{buttonReturn()}
						</div>
					)}
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default DeclaracionesModalYear;
