import React, { useState } from 'react';
import classes from './Información.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { clientStatusActions } from '../../../../../store/clientStatus';
import FileUploader from '../../../../common/FileUploader';
import ClickOutsideReact from '../../../../common/Clickoutside';
import { addUserFiles, uploadFiles } from '../../../../api/userfiles';
import checked from '../../../../../assets/images/checked.png';
import { Tooltip } from 'react-tooltip';
import commonStyles from '../../../../common/Common.module.css';

export const InfoUploadItem = (props) => {
	// Redux dispatch
	const dispatch = useDispatch();

	// TOKEN && USERID
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	// Value State
	const clientDataState = useSelector((state) => state.clientStatus);
	const clientDataValue = useSelector(
		(state) => state.clientStatus[props?.fieldUserValue]
	);
	const [currentValue, setCurrentValue] = useState(clientDataValue);

	// Client RFC
	const clientDataRFC = useSelector((state) => state.clientStatus.userRFC);

	// Profile Complete
	const { userEfirma, userCIEC, userKEYFile, userCERFile } = useSelector(
		(state) => state.clientStatus
	);
	const profileComplete = userEfirma && userCIEC && userKEYFile && userCERFile;

	// Modal && FormData
	const [modal, setModal] = useState(false);
	const [formData, setFormData] = useState(false);

	// Error && field
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const uploadFile = async (typeFile) => {
		if (formData) {
			const { name } = await formData.get('file[]');
			const fileName = clientDataRFC + (typeFile === 'key' ? '_KEY' : '_CER');

			setCurrentValue(name);
			const responseUpload = await uploadFiles(TOKEN, formData);
			const userFilesResponse = await addUserFiles(TOKEN, USERID, fileName);

			const resultUpload = await responseUpload.json();
			const resultUserFiles = await userFilesResponse.json();

			if (
				resultUpload.status === 'SUCCESS' &&
				resultUserFiles.status === 'SUCCESS'
			) {
				setFormData(null);
				updateClientData(name);
			} else {
				handleError();
				console.error('Error uploading files:', resultUpload);
			}
		} else {
			handleError();
			console.error('Error uploading files');
		}
	};

	const handleError = () => {
		setCurrentValue(clientDataValue);
		setError(true);
		setTimeout(() => {
			setError(false);
		}, 4000);
		setModal(false);
	};

	const updateClientData = async (fileName) => {
		if (TOKEN && USERID) {
			try {
				const response = await fetch(
					process.env.REACT_APP_API_URL +
						'client/user/partial-update/' +
						USERID,
					{
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
							// prettier-ignore
							'Authorization': 'Bearer ' + TOKEN,
						},
						body: JSON.stringify({
							[props.fieldValue]: fileName,
						}),
					}
				);
				if (response.status === 200) {
					setModal(false);
					setSuccess(true);
					setTimeout(() => {
						setSuccess(false);
					}, 4000);
					let profileUpdated = {
						...clientDataState,
						[props.fieldUserValue]: fileName,
					};

					// Notify profile has been completed
					if (
						!profileComplete &&
						profileUpdated.userEfirma &&
						profileUpdated.userCIEC &&
						profileUpdated.userKEYFile &&
						profileUpdated.userCERFile
					) {
						props?.onComplete(true);
					}
					dispatch(clientStatusActions.setClientData(profileUpdated));
				}
			} catch (error) {
				handleError();
			}
		} else {
			handleError();
		}
	};

	const onClose = () => {
		setFormData(null);
		setModal(false);
	};

	const editableField = () => {
		if (!currentValue) {
			return `${classes.smooth_transition} ${classes.inputFile_editable_empty} md:text-[24px] md:leading-[29px] text-[14px] leading-[17px] border-2 border-white`;
		} else {
			return `${classes.smooth_transition} ${classes.inputFile_editable} md:text-[24px] md:leading-[29px] text-[14px] leading-[17px] border-2 border-white`;
		}
	};

	const tooltipContent = (fieldName) => {
		switch (fieldName) {
			case 'CERFile':
				return "<p>Certificado de identidad emitido por el SAT. Descárgalo <a style='text-decoration: underline' href='https://www.gob.mx/tramites/ficha/certificado-de-sello-digital/SAT139' target='_blank'>aquí</a>.</p>";
			case 'KEYFile':
				return "<p>Certificado de identidad emitido por el SAT. Descárgalo  <a style='text-decoration: underline' href='https://www.sat.gob.mx/aplicacion/16660/genera-y-descarga-tus-archivos-a-traves-de-la-aplicacion-certifica' target='_blank'>aquí</a>.</p>";
			default:
				return '';
		}
	};

	return (
		<>
			<div className='py-[20px]'>
				<p
					className={'flex gap-[10px] items-center md:text-[20px] text-[14px]'}
				>
					<div className={classes.icon_container}>
						<span
							title='Da clic para saber más'
							className={classes.question_icon}
							data-tooltip-id='my-tooltip'
							data-tooltip-html={tooltipContent(props?.fieldValue)}
						></span>
						<Tooltip
							style={{ maxWidth: '200px', fontSize: '1rem', zIndex: 1000 }}
							id='my-tooltip'
							place='top'
							effect='solid'
							openOnClick={true}
							clickable={true}
						/>
					</div>
					{'Archivo ' + props?.type.toUpperCase()}{' '}
				</p>

				<div className='flex items-center gap-2'>
					<input
						title='Da clic para seleccionar archivo.'
						type={'text'}
						className={editableField()}
						value={currentValue || 'Seleccionar archivo'}
						onClick={() => {
							setModal(true);
						}}
					/>
				</div>
				{error && (
					<p className={classes.input_error}>{'Un error ha ocurrido.'}</p>
				)}
				{success && (
					<p
						className={`flex items-center gap-[10px] ${classes.input_success}`}
					>
						<img
							src={checked}
							alt='Successful operation'
							className='w-[15px]'
						></img>{' '}
						{'Archivo .' + props?.type.toUpperCase() + ' actualizado.'}
					</p>
				)}
			</div>
			{modal && (
				<div className={`${classes.uploadFile_container}`}>
					<ClickOutsideReact onClickOutside={onClose}>
						<div className={`${classes.uploadFile_modal}`}>
							<div className={`mx-[15px] ${classes.uploadFile_header}`}>
								<h1>
									{'Selecciona tu archivo ' + '.' + props?.type.toUpperCase()}
								</h1>
							</div>

							<div className={`${classes.uploadFile_dropZone}`}>
								<FileUploader
									onSetFormData={setFormData}
									validExtensions={[props?.type]}
									nameConvention={'_' + props?.type.toUpperCase()}
									userId={clientDataRFC}
									includeDate={false}
									buttonOnly={true}
								/>
								{formData && (
									<div className={`${classes.uploadFile_buttonWrapper}`}>
										<button
											onClick={uploadFile}
											className={`${commonStyles.button} py-4 px-5 bg-[#4285f4] text-white text-[14px] rounded-md font-medium md:text-[24px]`}
										>
											Subir archivo
										</button>
									</div>
								)}
							</div>
						</div>
					</ClickOutsideReact>
				</div>
			)}
		</>
	);
};

export default InfoUploadItem;
