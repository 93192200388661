import { createSlice } from '@reduxjs/toolkit';

const initialFacturaState = {
	id: '-',
	userRFC: '-',
	extranjero: '-',
	pais: '-',
	idFiscal: '-',
	nombre: '-',
	RFC: '-',
	email: '-',
	CP: '-',
	regimenFiscal: '-',
	formaPago: '-',
	usoCFDI: '-',
	descripcion: '-',
	metodoPago: '-',
	total: '-',
	estatus: '-',
	fechaSolicitud: '-',
	pending: true,
	presented: false,
	filterUserRFC: '',
};

const facturaSlice = createSlice({
	name: 'factura',
	initialState: initialFacturaState,
	reducers: {
		setPresentFactura(state, action) {
			state.id = action.payload.id;
			state.userRFC = action.payload.userRFC;
			state.extranjero = action.payload.extranjero;
			state.pais = action.payload.pais;
			state.idFiscal = action.payload.idFiscal;
			state.nombre = action.payload.nombre;
			state.RFC = action.payload.RFC;
			state.email = action.payload.email;
			state.CP = action.payload.CP;
			state.regimenFiscal = action.payload.regimenFiscal;
			state.formaPago = action.payload.formaPago;
			state.usoCFDI = action.payload.usoCFDI;
			state.descripcion = action.payload.descripcion;
			state.metodoPago = action.payload.metodoPago;
			state.total = action.payload.total;
			state.estatus = action.payload.estatus;
			state.fechaSolicitud = action.payload.fechaSolicitud;
		},
		setFilterOptions(state, action) {
			state.pending = action.payload.pending;
			state.presented = action.payload.presented;
			state.filterUserRFC = action.payload.filterUserRFC;
		},
	},
});

export const facturaActions = facturaSlice.actions;
export default facturaSlice.reducer;
