import React, { useEffect, useState } from 'react';
import downloadIcon from '../../../../../../assets/images/download.png';
import { getUserFiles } from '../../../../../api/userfiles';
import { useSelector } from 'react-redux';

const UserInfoItem = ({ title, value, downloadItem }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [downloadURL, setDownloadURL] = useState('');

	useEffect(() => {
		const retrieveFiles = async () => {
			const userFile = await getUserFiles(TOKEN, value);
			setDownloadURL(userFile.data);
		};
		if (downloadItem && value !== 'No completado') {
			retrieveFiles();
		}
	}, [value]);

	return (
		<div key={value} className='pt-4 flex flex-wrap'>
			<div className={'w-full flex flex-start gap-[10px]'}>
				<p className={'text-[14px] pb-2 pl-2 text-[#4285f4]'}>{title}</p>

				{downloadItem && downloadURL && value !== 'No completado' && (
					<a href={downloadURL} download={downloadURL}>
						<img
							className={`h-[20px]`}
							src={downloadIcon}
							alt='Descargar archivo'
						/>
					</a>
				)}
			</div>

			<input
				type='text'
				className={
					'w-full p-[10px] text-[18px] border-2 border-white rounded-[15px]'
				}
				value={value}
				disabled={true}
			/>
		</div>
	);
};

export default UserInfoItem;
