import React from 'react';
import Accordion from '../../../home/Accordion';

const Preguntas = () => {
	return (
		<div>
			<h2 className='md:text-[64px] md:leading-[81px] text-[32px] mb-5 font-bold leading-[37px]'>
				Preguntas Frecuentes
			</h2>
			<div>
				<Accordion />
			</div>
		</div>
	);
};

export default Preguntas;
