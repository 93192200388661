import React, { useState, useEffect } from 'react';
import classes from './Card.module.css';
import generals from '../../css/Generals.module.css';
import { BsArrowRight } from 'react-icons/bs';
import { TbMessage } from 'react-icons/tb';

const Card = ({
	number,
	title,
	img,
	imgAlt,
	subtitle,
	description,
	primaryCta,
	primaryCtaText,
	onClickPrimaryCta,
	secondaryCta,
	secondaryCtaText,
	onClickSecondaryCta,
	overrideClass,
}) => {
	return (
		<div className={overrideClass}>
			<h3 className={`${classes.main_title} ${classes.main_title_profile}`}>
				{number && <div className={classes.main_number_wrapper}>{number}</div>}
				<button
					className={classes.main_title_button}
					onClick={onClickPrimaryCta}
				>
					{title}
				</button>
			</h3>
			<div className={classes.main_grid}>
				<div className={classes.main_wrapper_profile}>
					<div className={classes.main_card_img_wrapper_profile}>
						<img src={img} className={classes.main_card_img} alt={imgAlt} />
					</div>
					<div className={classes.main_card_wrapper_profile}>
						<h2 className={classes.main_card_subtitle}>{subtitle}</h2>
						<h3 className={classes.main_card_text}>{description} </h3>
						<div className={classes.main_title_button_wrapper}>
							{primaryCta && (
								<button
									onClick={onClickPrimaryCta}
									className={`${generals.button_blue} ${classes.button_blue}`}
								>
									{primaryCtaText}{' '}
									<BsArrowRight className='text-[25px] hidden md:block' />
								</button>
							)}
							{secondaryCta && (
								<button
									className={`${generals.button_white} ${classes.button_white}`}
									onClick={onClickSecondaryCta}
								>
									<TbMessage
										className='text-[25px] mb-[5px] hidden md:block'
										color='#4285f4'
									/>{' '}
									{secondaryCtaText}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card;
