import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import FileUploader from '../../../../common/FileUploader';
import {
	monthNames,
	getCurrentAndLastFiveYears,
} from '../../../../../constants/constants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import classes from './css/Presentar.module.css';
import { uploadFiles, addUserFiles } from '../../../../api/userfiles';
import { addSatDeclaration } from '../../../../api/satDeclarations';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../common/Clickoutside';
import { useSelector, useDispatch } from 'react-redux';
import { satDeclarationActions } from '../../../../../store/satDeclaration';
import Loading from '../../../../common/Loading';

const PresentarModal = ({ TOKEN, USERID, users, currentDate, setModal }) => {
	const {
		declarationMonth,
		declarationYear,
		declarationType,
		declarationDueDate,
		userRFC,
		userId,
		payment,
		subscription,
		anualPlan,
	} = useSelector((state) => state.satDeclaration);

	const initialAutocompleteStates = {
		clientId: userId,
		rfc: userRFC,
		subscription: subscription,
		anualPlan: anualPlan,
		fechaVigencia: '',
		declarationMonth: declarationMonth,
		declarationYear: declarationYear,
		declarationType: declarationType || 'normal',
		total: payment,
	};
	const dispatch = useDispatch();
	const [declaration, setDeclaration] = useState(initialAutocompleteStates);
	const [formData, setFormData] = useState(null);
	const years = getCurrentAndLastFiveYears();
	const types = ['normal', 'complementaria', 'anual', 'otro'];
	const [warningDialog, setWarningDialog] = useState(true);
	const [loading, setLoading] = useState(false);
	const [registro, setRegistro] = useState(false);
	const [error, setError] = useState({ state: false, message: '' });
	const [success, setSuccess] = useState({ state: false, message: '' });
	const usersRFC = users.map((user) => user.label);
	const [showAnimation, setShowAnimation] = useState(false);

	// Effect to trigger the animation on mount
	useEffect(() => {
		setShowAnimation(true);
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (
			declaration.total &&
			declaration.declarationType &&
			declaration.clientId &&
			declaration.rfc &&
			declaration.declarationMonth &&
			declaration.declarationYear &&
			declaration.fechaVigencia
		) {
			if (!formData) {
				setError({
					state: true,
					message: 'Favor de subir los archivos indicados.',
				});
				return;
			}

			setLoading(true);
			try {
				const responseUpload = await uploadFiles(TOKEN, formData);
				const responseSatDeclaration = await addSatDeclaration(TOKEN, {
					satDeclarationID: currentDate,
					payment: declaration.total,
					userId: declaration.clientId,
					userRFC: declaration.rfc,
					declarationMonth: declaration.declarationMonth,
					declarationYear: declaration.declarationYear,
					declarationType: declaration.declarationType,
					createdBy: USERID,
					currentStatus: Number(declaration.total) <= 0 ? 'pagado' : 'vigente',
					effectiveDate:
						Number(declaration.total) <= 0 ? null : declaration.fechaVigencia,
					paidDate: Number(declaration.total) <= 0 ? new Date() : null,
					reactivate: false,
				});
				if (responseUpload.ok && responseSatDeclaration.ok) {
					for (let element of formData) {
						const userFilesResponse = await addUserFiles(
							TOKEN,
							USERID,
							element[1].name
						);
						if (!userFilesResponse.ok) {
							setLoading(false);
							setError({
								state: true,
								message: 'Algo salió mal. Favor de volver a intentar.',
							});
							return;
						}
					}
					setSuccess({
						state: true,
						message: 'Tu operación ha sido completada.',
					});
					setLoading(false);
				} else {
					setLoading(false);
					setRegistro(true);
					setError({
						state: true,
						message: 'Algo salió mal. Favor de volver a intentar.',
					});
				}
			} catch (error) {
				setLoading(false);
				setRegistro(true);
				setError({
					state: true,
					message: 'Algo salió mal. Favor de volver a intentar.',
				});
			}
		} else {
			setError({
				state: true,
				message: 'Favor de llenar todos los campos.',
			});
			setLoading(false);
		}
	};

	const handleClose = (e) => {
		const dialogOpen = document.querySelectorAll('div[role="dialog"]');
		const noSubscriptionOpen = document.querySelector('#noSubscription');
		if (dialogOpen?.length > 0 || noSubscriptionOpen) {
			setWarningDialog(false);
		} else {
			setModal(false);
			dispatch(
				satDeclarationActions.setPresentSatDeclaration({
					declarationMonth: '',
					declarationYear: '',
					declarationType: '',
					declarationDueDate: '',
					payment: 0,
					userRFC: '',
					userId: '',
					subscription: '',
					anualPlan: '',
				})
			);
		}
	};

	const cleanErr = () => {
		setError({
			state: false,
			message: '',
		});
	};

	const handleCloseWarning = () => {
		setWarningDialog(false);
	};

	return (
		<div className={classes.modal_overlay}>
			{warningDialog &&
				declaration.subscription !== 'activa' &&
				!declaration.anualPlan &&
				declaration.clientId &&
				!error.state && (
					<div
						id='noSubscription'
						className={
							showAnimation
								? `${classes.slide_up} ${classes.slide_up_show} ${classes.modal_error_wrapper}`
								: `${classes.slide_up}  ${classes.modal_error_wrapper}`
						}
					>
						<div className={classes.modal_error}>
							<p>Este usuario no está subscrito a ningún plan de ciberconta.</p>
							<button onClick={handleCloseWarning}>
								<CloseIcon sx={{ fontSize: 25, color: '#fff' }} />
							</button>
						</div>
					</div>
				)}
			<ClickOutsideReact onClickOutside={(e) => handleClose(e)}>
				<div className={classes.modal_container}>
					<button
						className={classes.icon_close_wrapper}
						onClick={(e) => handleClose(e)}
					>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>Presentar declaración</h1>
					<h2 className={classes.modal_subtitle}>Forma de registro</h2>
					{!loading && !success.state && (
						<form
							className={classes.modal_form}
							action=''
							onSubmit={handleSubmit}
						>
							<div className={classes.modal_form_wrapper_column}>
								<div className={classes.modal_form_column}>
									<div className='py-4'>
										<Autocomplete
											key={registro}
											disablePortal
											options={usersRFC}
											sx={{ width: 400 }}
											value={declaration.rfc}
											renderInput={(params) => (
												<TextField
													className='input_box_declaration'
													{...params}
													label='Seleccionar RFC'
												/>
											)}
											onChange={(e, newValue) => {
												const selectedUser = users.find(
													(user) => user.label === newValue
												);
												setDeclaration((prevState) => ({
													...prevState,
													clientId: selectedUser.id || '',
													rfc: selectedUser.label || '',
													subscription: selectedUser.subscription || '',
													anualPlan: selectedUser.anualPlan || '',
												}));
												if (error.state) cleanErr();
											}}
										/>
									</div>
									<div className='py-4'>
										<Autocomplete
											key={registro}
											disablePortal
											options={monthNames}
											sx={{ width: 400 }}
											value={declaration.declarationMonth}
											renderInput={(params) => (
												<TextField
													required
													className='input_box_declaration'
													{...params}
													label='Mes de declaración'
												/>
											)}
											onChange={(e, newValue) => {
												setDeclaration((prevState) => ({
													...prevState,
													declarationMonth: newValue || '',
												}));
												if (error.state) cleanErr();
											}}
										/>
									</div>
									<div className='py-4'>
										<Autocomplete
											key={registro}
											disablePortal
											options={years}
											sx={{ width: 400 }}
											value={declaration.declarationYear}
											renderInput={(params) => (
												<TextField
													required
													className='input_box_declaration'
													{...params}
													label='Año de declaración'
												/>
											)}
											onChange={(e, newValue) => {
												setDeclaration((prevState) => ({
													...prevState,
													declarationYear: newValue || '',
												}));
												if (error.state) cleanErr();
											}}
										/>
									</div>
								</div>
								<div className={classes.modal_form_column}>
									<div className='py-4'>
										<Autocomplete
											key={registro}
											disablePortal
											options={types}
											sx={{ width: 400 }}
											value={declaration.declarationType}
											renderInput={(params) => (
												<TextField
													required
													className='input_box_declaration'
													{...params}
													label='Tipo de declaración'
												/>
											)}
											onChange={(e, newValue) => {
												setDeclaration((prevState) => ({
													...prevState,
													declarationType: newValue || '',
												}));
												if (error.state) cleanErr();
											}}
										/>
									</div>
									<div className='py-4'>
										<TextField
											key={registro}
											required
											sx={{ width: 400 }}
											label='Total a pagar'
											className='input_box_declaration'
											id='filled-start-adornment'
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>$</InputAdornment>
												),
											}}
											value={declaration.total || ''}
											onChange={(e) => {
												let formatted = e.target.value
													.replace(/[^0-9.]/g, '')
													.replace(/\./g, '');
												const dotPosition = formatted.length - 1;
												let money = formatted;
												if (formatted.length > 2) {
													money =
														formatted.substring(0, dotPosition - 1) +
														'.' +
														formatted.substring(dotPosition - 1);
												}
												setDeclaration((prevState) => ({
													...prevState,
													total: money,
												}));
												if (error.state) cleanErr();
											}}
											variant='outlined'
										/>
									</div>
									<div className='py-4'>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												key={registro}
												label='Fecha de expiración'
												className='input_box_declaration'
												sx={{ width: 400 }}
												onChange={(newValue) => {
													setDeclaration((prevState) => ({
														...prevState,
														fechaVigencia: newValue,
													}));
													if (error.state) cleanErr();
												}}
											/>
										</LocalizationProvider>
									</div>
								</div>
							</div>
							{declaration.declarationMonth &&
								declaration.rfc &&
								declaration.declarationYear && (
									<div className={classes.modal_fileUploader_wrapper}>
										<FileUploader
											userId={declaration.rfc}
											onSetFormData={setFormData}
											validExtensions={['pdf', 'png']}
											nameConvention={
												declaration.declarationType === 'anual'
													? '_DECLARACIÓN_ANUAL_'
													: '_DECLARACIÓN_'
											}
											includeDate={true}
											month={declaration.declarationMonth}
											year={declaration.declarationYear}
										/>
									</div>
								)}
							<div className={classes.modal_wrapper_button}>
								{error.state && (
									<p className={classes.modal_error_message}>{error.message}</p>
								)}

								<button type='submit' className={classes.modal_button}>
									Presentar Declaración
								</button>
							</div>
						</form>
					)}
					{loading && !success.state && (
						<div className='w-[960px] h-[544px] flex items-center justify-center'>
							<Loading loading={loading} />
						</div>
					)}
					{!loading && success.state && (
						<div className='w-[960px] h-[544px] flex flex-col items-center justify-center'>
							<h2 className={classes.modal_success_title}>
								¡Resultado exitoso!
							</h2>
							<h2 className={classes.modal_success_message}>
								{success.message}
							</h2>
						</div>
					)}
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default PresentarModal;
