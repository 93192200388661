import React from 'react'
import { useNavigate } from 'react-router-dom'

const Empezar = () => {

    const navigate = useNavigate()
    const submit = ()=>{
      navigate("/signup")
    }

  return (
    <div className='emapezar xl:h-[700px] md:h-[600px] mt-5 h-[330px] flex items-center justify-center'>
        <div className="container">
            <div className='mt-[100px]'>
                <div className='flex items-center justify-center'>
                    <h2 className='md:text-[64px] text-center text-[32px] font-bold text-white'>Empezar te tomará unos segundos</h2>
                </div>
                <div className='flex items-center justify-center md:gap-[30px] gap-2 mt-[40px]'>
                    <button onClick={submit} className='py-3 md:text-[24px] text-[14px] font-normal px-4 rounded-md bg-white text-[#4285F4]'>Descarga la app</button>
                    <button onClick={submit} className='py-3 md:text-[24px] text-[14px] font-normal px-4 rounded-md bg-transparent border border-white text-white'>Habla con un asesor</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Empezar