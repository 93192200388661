import React from 'react';
import Accordion from './Accordion';

const Preguntas = ({ reviewsRef }) => {
	return (
		<div className='py-[70px]' ref={reviewsRef}>
			<div className='container'>
				<div>
					<div className='headding flex items-center justify-center flex-col pb-[100px]'>
						<h2 className='md:text-[64px] text-[32px] font-bold'>
							Preguntas frecuentes
						</h2>
						<p className='md:text-[24px] text-[14px] font-normal'>
							Resuelve aquí tus dudas o contáctate con un asesor
						</p>
					</div>
					<div>
						<Accordion />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Preguntas;
