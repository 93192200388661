export const getUserFiles = async (TOKEN, userDetail) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/generate-pre-signed-url',
			{
				method: 'POST',
				headers: {
					//prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
					//prettier-ignore
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					uri: userDetail,
				}),
			}
		);
		if (response.ok) {
			const contentType = response.headers.get('content-type');
			if (contentType?.includes('application/json')) {
				const data = await response.json();
				return data;
			} else {
				throw new Error('Invalid response format: Expected JSON');
			}
		} else {
			throw new Error('Error fetching pre-signed URL');
		}
	} catch (error) {
		console.error('Error fetching pre-signed URL:', error);
	}
};

export const getExistingUserFiles = async (TOKEN, fileName) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/userfiles/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { createdAt: -1 },
						where: { file: fileName },
						select: ['file'],
					},
					isCountOnly: false,
				}),
			}
		);
		const result = await response.json();
		return result;
	} catch (error) {
		console.error(error);
	}
};

export const addUserFiles = async (TOKEN, USERID, fileName) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/userfiles/create',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					fileId: USERID,
					userId: USERID,
					transactionNo: 1,
					file: fileName,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error('Error adding user file:', error);
	}
};

export const uploadFiles = async (TOKEN, formData) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/upload',
			{
				method: 'POST',
				body: formData,
				headers: {
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
					// prettier-ignore
					'Accept': 'application/json',
				},
				timeout: 5000,
			}
		);
		return response;
	} catch (error) {
		console.error('Error uploading file:', error);
	}
};

export const uploadFactura = async (TOKEN, formData) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/uploadFactura',
			{
				method: 'POST',
				body: formData,
				headers: {
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
					// prettier-ignore
					'Accept': 'application/json',
				},
				timeout: 5000,
			}
		);
		return response;
	} catch (error) {
		console.error('Error uploading file:', error);
	}
};
