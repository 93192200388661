import React, { useState, useEffect } from 'react';
import { getAllUsers } from '../../../../api/users';
import classes from './css/Users.module.css';
import generals from '../../../../../css/Generals.module.css';
import TuneIcon from '@mui/icons-material/Tune';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import Generals from './Generals';
import User from './User';
import { useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';

const Usuarios = () => {
	const [usersMode, setUsersMode] = useState('Generals');
	const [users, setUsers] = useState();
	const [loading, setLoading] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState('');
	const TOKEN = useSelector((state) => state.auth.tokenId);

	useEffect(() => {
		const fetchUsers = async () => {
			setLoading(true);
			try {
				const users = await getAllUsers(TOKEN);
				setUsers(users);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		};
		fetchUsers();
	}, []);

	return (
		<div className={`${classes.users_container}`}>
			<div className='headding mb-8'>
				<div className={classes.users_title_container}>
					<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
						Usuarios
					</h2>
					<button
						className={
							usersMode === 'Generals'
								? `${classes.users_title_button} ${classes.active}`
								: classes.users_title_button
						}
						onClick={() => setUsersMode('Generals')}
					>
						<TuneIcon sx={{ fontSize: 24 }} />
						<h1 className={classes.users_title_button_text}>Generales</h1>
					</button>
					<button
						className={
							usersMode === 'User'
								? `${classes.users_title_button} ${classes.active}`
								: classes.users_title_button
						}
						onClick={() => setUsersMode('User')}
					>
						<PersonSearchOutlinedIcon sx={{ fontSize: 24 }} />
						<h1 className={classes.users_title_button_text}>Usuario</h1>
					</button>
				</div>
				<h3 className={generals.page_subtitle}>
					Módulo para gestión de prospectos y clientes{' '}
				</h3>
			</div>
			{!loading && usersMode === 'Generals' && (
				<Generals
					users={users}
					setUsersMode={setUsersMode}
					setSelectedUserId={setSelectedUserId}
				/>
			)}
			{!loading && usersMode === 'User' && (
				<User users={users} selectedUserFromGenerals={selectedUserId} />
			)}
			{loading && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
		</div>
	);
};

export default Usuarios;
