import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeNav from '../components/layout/navbar/HomeNav';
import { validatePassword } from '../utils/validation';
import FooterHome from '../components/layout/footer/FooterHome';

const ResetPassword = () => {
	const [code, setCode] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (code && newPassword) {
			if (validatePassword(newPassword)) {
				try {
					const response = await fetch(
						process.env.REACT_APP_API_URL + 'client/auth/reset-password',
						{
							method: 'PUT',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								code: code,
								newPassword: newPassword,
							}),
						}
					);

					if (response.status == 200) {
						setError({ state: false, message: '' });
						setSuccess(true);
					} else {
						setError({
							state: true,
							message: 'Algo salió mal. Favor de volver a intentar.',
						});
					}
				} catch (error) {
					setError({
						state: true,
						message: 'Algo salió mal. Favor de volver a intentar.',
					});
					console.error('Error:', error);
				}
			} else {
				setError({
					state: true,
					message:
						'La contraseña debe de tener al menos 8 caracteres, una letra y un número.',
				});
			}
		} else {
			setError({
				state: true,
				message: 'Favor de llenar los campos.',
			});
		}
	};
	return (
		<>
			<HomeNav />
			{!success ? (
				<div className='md:pt-[120px] pt-[90px]'>
					<div className='container'>
						<div className='max-w-[500px] m-auto'>
							<div className='headding mb-4'>
								<h2 className='md:text-[42px] text-[20px] md:leading-[81px] leading-[37px] font-bold'>
									Restablecer Contraseña
								</h2>
							</div>

							<form action='' onSubmit={handleSubmit}>
								<div className='flex items-center flex-col md:flex-row gap-0  md:gap-[100px] w-full'>
									<div className='w-full'>
										<div className='py-2'>
											<label htmlFor='codigo' className='ml-5 text-[20px]'>
												Código OTP
											</label>
											<input
												type='text'
												id='codigo'
												className='input_box text-[20px] w-full py-2 px-2'
												value={code}
												onChange={(e) => setCode(e.target.value)}
												onFocus={() => setError(false)}
											/>
										</div>

										<div className='py-2'>
											<label
												htmlFor='nueva-contraseña'
												className='ml-5 text-[20px]'
											>
												Nueva Contraseña
											</label>
											<div className=' relative'>
												<input
													type='text'
													id='nueva-contraseña'
													className='input_box text-[20px] w-full py-2 px-2'
													value={newPassword}
													onChange={(e) => setNewPassword(e.target.value)}
													onFocus={() => setError(false)}
												/>
											</div>
										</div>
									</div>
								</div>
								{error.state && (
									<p style={{ fontSize: '1rem', color: '#DB6363' }}>
										{error.message}
									</p>
								)}
								<div className='flex justify-content items-center my-[20px]'>
									<button
										type='submit'
										className='py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]'
									>
										Restablecer
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			) : (
				<div className='md:pt-[120px] pt-[90px]'>
					<div>
						<h2 className='md:text-[42px] text-center text-[32px] font-bold pt-[120px]'>
							Tu contraseña ha sido restablecida de forma exitosa.
						</h2>
					</div>
					<div className='mt-14 max-w-[550px] text-center m-auto'>
						<div className='mt-8'>
							<Link to={'/login'}>
								<button className='py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]'>
									Volver a página principal
								</button>
							</Link>
						</div>
					</div>
				</div>
			)}
			<FooterHome />
		</>
	);
};

export default ResetPassword;
