import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	changeKeysToLabel,
	getCurrentDate,
} from '../../../../../utils/validation';
import classes from './css/Presentar.module.css';
import { getAllUsers } from '../../../../api/users';
import StatusIndicator from '../Common/StatusIndicator';
import Pendings from './Pendings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PresentarModal from './PresentarModal';
import PresentarMoreInfo from './PresentarMoreInfo';
import PresentarFilters from './PresentarFilters';

const PresentarDeclaración = () => {
	const [modal, setModal] = useState(false);
	const [moreInfo, setMoreInfo] = useState(false);
	const [users, setUsers] = useState([]);
	const [currentDate, setCurrentDate] = useState('');
	const [loading, setLoading] = useState(false);
	const [registro, setRegistro] = useState(false);
	const [error, setError] = useState({ state: false, message: '' });
	const [totalPendings, setTotalPendings] = useState('');
	const [totalPresented, setTotalPresented] = useState('');
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	useEffect(() => {
		setLoading(false);
		setError({ state: false, message: '' });

		async function fetchUsers() {
			const users = await getAllUsers(TOKEN);
			if (users) {
				const filteredUsers = await changeKeysToLabel(users);
				setUsers(filteredUsers);
			}
		}
		async function fetchDate() {
			const formattedDate = await getCurrentDate(USERID);
			setCurrentDate(formattedDate);
		}

		fetchDate();
		fetchUsers();
	}, []);
	return (
		<div className='relative'>
			<div className='w-full flex gap-[20px] items-center'>
				<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
					Presentar Declaración{' '}
				</h2>

				<button
					className={classes.button_nueva_presentar}
					onClick={() => setModal(true)}
				>
					<h1 className={classes.text_nueva_presentar}>Nueva Declaración</h1>
					<AddCircleIcon sx={{ fontSize: 24, color: '#4285f4' }} />
				</button>
			</div>
			<h3 className={classes.page_subtitle}>
				Módulo para presentar declaraciones fiscales{' '}
			</h3>
			<StatusIndicator
				loading={loading}
				registro={registro}
				error={error}
				setRegistro={setRegistro}
			/>
			<div className='pt-[15px] w-full'>
				<PresentarFilters
					users={users}
					totalPendings={totalPendings}
					totalPresented={totalPresented}
				/>
				<div className={classes.container_nueva_presentar}></div>
				<Pendings
					setTotalPendings={setTotalPendings}
					setTotalPresented={setTotalPresented}
					setModal={setModal}
					setMoreInfo={setMoreInfo}
					users={users}
				/>
			</div>
			{modal && (
				<PresentarModal
					TOKEN={TOKEN}
					USERID={USERID}
					users={users}
					currentDate={currentDate}
					setModal={setModal}
				/>
			)}
			{moreInfo.state && (
				<PresentarMoreInfo
					moreInfo={moreInfo}
					setMoreInfo={setMoreInfo}
					setModal={setModal}
				/>
			)}
		</div>
	);
};

export default PresentarDeclaración;
