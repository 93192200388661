import React from 'react';
import classes from '../css/Modal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import EastIcon from '@mui/icons-material/East';
import { capitalizeEveryWord, cropWord } from '../utils/utils';

const ModalUsersInfo = ({
	setMoreInfo,
	moreInfo,
	setUsersMode,
	setSelectedUserId,
}) => {
	const handleClose = () => {
		setMoreInfo({ state: false, title: '', subtitle: '', users: [] });
	};

	const handleGoToUser = (user) => {
		setMoreInfo({ state: false, title: '', subtitle: '', users: [] });
		setUsersMode('User');
		setSelectedUserId(user);
	};

	return (
		<div className={classes.modal_overlay}>
			<ClickOutsideReact onClickOutside={handleClose}>
				<div className={classes.modal_container}>
					<button className={classes.icon_close_wrapper} onClick={handleClose}>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>{moreInfo.title}</h1>
					<h2 className={classes.modal_subtitle}>{moreInfo.subtitle}</h2>
					<div className={classes.modal_info_container}>
						{moreInfo.users.length > 0 && (
							<div className={classes.modal_info_element}>
								{moreInfo.users.map((x) => (
									<button
										key={x.rfc}
										onClick={() => handleGoToUser(x)}
										className={classes.modal_info_user}
									>
										<span
											className={
												x.pendingSignIn || x.pendingPlan
													? `${classes.modal_info_element_status} ${classes.modal_info_element_pending}`
													: `${classes.modal_info_element_status}`
											}
										></span>
										<h1 className={classes.modal_info_element_title}>
											{x.rfc || '-'}
										</h1>
										<h1 className={classes.modal_info_element_subtitle}>
											{cropWord(capitalizeEveryWord(x.name), 25) || '-'}
										</h1>
										<div className={classes.modal_info_arrow}>
											<EastIcon />
										</div>
									</button>
								))}
							</div>
						)}
						{(moreInfo.users.length <= 0 || !moreInfo.users.length) && (
							<div className={classes.modal_info_nousers}>
								<h1>{'No se encontraron usuarios.'}</h1>
							</div>
						)}
					</div>
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default ModalUsersInfo;
