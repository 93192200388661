import React, { useState } from 'react';
import classes from '../css/FacturaModal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import generals from '../../../../../../css/Generals.module.css';
import { cropWord } from '../../../../../../utils/format';
import FacturaModalFormContainer from './FacturaModalFormContainer';
import { deleteFacturaContact } from '../../../../../api/facturas';
import { useSelector } from 'react-redux';
import Loading from '../../../../../common/Loading';
import $ from 'jquery';

const FacturaModal = ({
	contacts,
	setModal,
	selectedContact,
	setSelectedContact,
	setRefetch,
}) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [contactModal, setContactModal] = useState(false);
	const [cancelContactModal, setCancelContactModal] = useState(false);
	const [message, setMessage] = useState({ state: false, msg: '' });
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		const dialogOpen = document.querySelectorAll('div[role="dialog"]');
		const presentationOpen = document.querySelectorAll('[role="presentation"]');
		if (dialogOpen.length <= 0 && presentationOpen.length <= 0) {
			$('body').css('overflow', '');
			setModal(false);
		}
	};

	const handleCancelFacturaContact = async () => {
		setLoading(true);
		try {
			const response = await deleteFacturaContact(TOKEN, selectedContact.id);
			if (response.status === 200) {
				setRefetch((prevState) => !prevState);
				setLoading(false);
				setMessage({
					state: 'success',
					msg: 'Tu cliente a facturar se ha eliminado con éxito.',
				});
			} else {
				setLoading(false);
				setMessage({
					state: 'error',
					msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		} catch (error) {
			console.error(error);
			setLoading(false);
			setMessage({
				state: 'error',
				msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
			});
		}
	};

	return (
		<div className={classes.modal_overlay}>
			{!contactModal && (
				<ClickOutsideReact onClickOutside={handleClose}>
					<div className={classes.modal_container}>
						<button
							className={classes.icon_close_wrapper}
							onClick={handleClose}
						>
							<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
						</button>
						<h1 className={classes.modal_title}>Contactos</h1>
						<h2 className={classes.modal_subtitle}>
							Elige o crea nuevos clientes a facturar
						</h2>
						<div className={classes.modal_info_container}>
							{!loading && contacts.length > 0 && !cancelContactModal && (
								<div className={classes.modal_info_element}>
									{contacts.map((x) => (
										<div
											onClick={() => {
												setContactModal(true);
												setSelectedContact(x);
											}}
											key={x.id}
											className={classes.modal_info_user}
										>
											<div className={classes.modal_info_element_title}>
												<h1>{x.rfccliente || '-'}</h1>
											</div>

											<div className={classes.modal_info_element_subtitle}>
												<h1>{cropWord(x.emailcliente, 25) || '-'}</h1>
											</div>
											<button
												onClick={(e) => {
													e.stopPropagation();
													setCancelContactModal(true);
													setSelectedContact(x);
												}}
												className={classes.modal_info_close_wrapper}
											>
												<CloseIcon sx={{ fontSize: 20 }} />
											</button>
										</div>
									))}
								</div>
							)}
							{!loading && cancelContactModal && !message.state && (
								<h1 className={`mt-5 ${generals.modal_success_text}`}>
									¿Estás seguro de querer eliminar este cliente a facturar?
								</h1>
							)}
							{!loading && cancelContactModal && message.state && (
								<h1 className={`mt-5 ${generals.modal_success_text}`}>
									{message.msg}
								</h1>
							)}
							{loading && <Loading loading={loading} />}
						</div>
						{!cancelContactModal && (
							<button
								onClick={() => {
									setSelectedContact(false);
									setContactModal(true);
								}}
								className={`my-4 ${generals.button_blue}`}
							>
								Nuevo cliente
							</button>
						)}
						{cancelContactModal && !message.state && (
							<button
								onClick={handleCancelFacturaContact}
								className={`${generals.button_blue}`}
							>
								Eliminar cliente
							</button>
						)}
						{cancelContactModal && (
							<button
								onClick={() => {
									setCancelContactModal(false);
									setMessage({ state: false, msg: '' });
								}}
								className={`my-4 ${generals.button_white}`}
							>
								Regresar
							</button>
						)}
					</div>
				</ClickOutsideReact>
			)}
			{contactModal && (
				<FacturaModalFormContainer
					selectedContact={selectedContact}
					setContactModal={setContactModal}
					setRefetch={setRefetch}
				/>
			)}
		</div>
	);
};
export default FacturaModal;
