import React from 'react';
import classes from '../css/DetailItem.module.css';
import DetailHeaderItem from './DetailHeaderItem';
import { useSelector } from 'react-redux';

const DetailHeaderPendings = ({
	sortResults,
	currentSortingCategory,
	setCurrentSortingCategory,
}) => {
	const categories = ['RFC', 'Cliente', 'Total', 'Fecha', 'Estatus'];
	const { pending } = useSelector((state) => state.factura);
	return (
		<div
			className={
				pending
					? `${classes.detail_header_wrapper} ${classes.pending}`
					: `${classes.detail_header_wrapper} ${classes.presented}`
			}
		>
			{categories.map((x) => {
				return (
					<DetailHeaderItem
						key={x}
						sortResults={sortResults}
						categoryName={x}
						currentSortingCategory={currentSortingCategory}
						setCurrentSortingCategory={setCurrentSortingCategory}
					/>
				);
			})}
		</div>
	);
};

export default DetailHeaderPendings;
