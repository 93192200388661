export const monedas = [
	'Pesos mexicanos',
	'Dólares americanos',
	'Dólares canadienses',
	'Pesos argentinos',
];

export const paymentForms = [
	'01 - Efectivo',
	'02 - Cheque Nominativo',
	'03 - Transferencia electrónica de fondos',
	'04 - Tarjeta de crédito',
	'05 - Monedero electrónico',
	'06 - Dinero electrónico',
	'07 - Vales de despensa',
	'27 - A satisfacción del acreedor',
	'28 - Tarjeta de débito',
	'29 - Tarjeta de servicio',
];

export const paymentMethods = [
	'Pago en una sola exhibición',
	'Pago en parcialidades o diferido',
];

export const CFDI = [
	'000 - Sin efectos fiscales',
	'G01 - Adquisición de mercancías',
	'G02 - Devoluciones, descuentos o bonificaciones',
	'G03 - Gastos en general',
	'I01 - Construcciones',
	'I02 - Mobiliario y equipo de oficina por inversiones',
	'I03 - Equipo de transporte',
	'I04 - Equipo de cómputo y accesorios',
	'I05 - Dados, troqueles, moldes, matrices y herramental',
	'I06 - Comunicaciones telefónicas',
	'I07 - Comunicaciones satelitales',
	'I08 - Otra maquinaria y equipo',
	'D01 - Honorarios médicos, dentales y gastos hospitalarios',
	'D02 - Gastos médicos por incapacidad o discapacidad',
	'D03 - Gastos funerales',
	'D04 - Donativos',
	'D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',
	'D06 - Aportaciones voluntarias al SAR',
	'D07 - Primas por seguros de gastos médicos',
	'D08 - Gastos de transportación escolar obligatoria',
	'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',
	'D10 - Pagos por servicios educativos (colegiaturas)',
	'P01 - Por definir',
];

export const regimenFiscal = [
	'601 - General de Ley Personas Morales',
	'603 - Personas Morales con Fines no Lucrativos',
	'605 - Sueldos y Salarios e Ingresos Asimilados a Salarios',
	'606 - Arrendamiento',
	'607 - Régimen de Enajenación o Adquisición de Bienes',
	'608 - Demás ingresos',
	'609 - Consolidación',
	'610 - Residentes en el Extranjero sin Establecimiento Permanente en México',
	'611 - Ingresos por Dividendos (socios y accionistas)',
	'612 - Personas Físicas con Actividades Empresariales y Profesionales',
	'614 - Ingresos por intereses',
	'615 - Régimen de los ingresos por obtención de premios',
	'616 - Sin obligaciones fiscales',
	'620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
	'621 - Incorporación Fiscal',
	'622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
	'623 - Opcional para Grupos de Sociedades',
	'624 - Coordinados',
	'625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
	'626 - Régimen Simplificado de Confianza',
	'628 - Hidrocarburos',
	'629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
	'630 - Enajenación de acciones en bolsa de valores',
];
