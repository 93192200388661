import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../../../store/profile';
import { useNavigate, Link } from 'react-router-dom';
import { persistor } from '../../../store/index';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import classes from './Profile.module.css';

const ProfileLeft = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { userprofiledata } = useSelector((state) => state.profile);

	const {
		userRFC,
		userEfirma,
		userCIEC,
		userKEYFile,
		userCERFile,
		userAnualPlan,
		userSubscription,
	} = useSelector((state) => state.clientStatus);

	const profileComplete = userEfirma && userCIEC && userKEYFile && userCERFile;
	const planComplete = userSubscription || userAnualPlan;

	const [isContabilidadMenuOpen, setIsContabilidadMenuOpen] = useState(false);
	const [isPerfilMenuOpen, setIsPerfilMenuOpen] = useState(false);

	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
	};

	const Logout = () => {
		persistor.purge();
		navigate('/login');
	};

	return (
		<div className='fixed mt-[30px]'>
			<div className='flex flex-col gap-4 items-start text-[24px]'>
				<Link to='/profile'>
					<button
						onClick={() => setProfileData('profile')}
						className={`${classes.profile_option} ${
							userprofiledata === 'profile' ? ' font-bold' : ''
						}`}
					>
						Dashboard
					</button>
				</Link>
				<button
					onClick={() => {
						isPerfilMenuOpen
							? setIsPerfilMenuOpen(false)
							: setIsPerfilMenuOpen(true);
					}}
					className={`${
						userprofiledata === 'Información' || userprofiledata === 'Mi'
							? 'font-bold'
							: ''
					} administrador`}
				>
					Mi perfil {isPerfilMenuOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
				</button>
				{isPerfilMenuOpen && (
					<>
						<Link to='/profile'>
							<button
								onClick={() => setProfileData('Información')}
								className={`${classes.profile_option} ${
									userprofiledata === 'Información' ? ' font-bold' : ''
								} administradorSubtitle`}
							>
								{!profileComplete && (
									<span className={classes.info_redCircle}></span>
								)}
								Datos
								{profileComplete && (
									<span className={classes.info_profileComplete}>100%</span>
								)}
								{!profileComplete && (
									<span className={classes.info_profileIncomplete}>50%</span>
								)}
							</button>
						</Link>
						<Link to='/profile'>
							<button
								onClick={() => setProfileData('Mi')}
								className={`${classes.profile_option} ${
									classes.profile_option
								} ${
									userprofiledata === 'Mi' ? ' font-bold' : ''
								} administradorSubtitle`}
							>
								Plan{' '}
							</button>
						</Link>
					</>
				)}

				{profileComplete && planComplete && (
					<button
						onClick={() => {
							isContabilidadMenuOpen
								? setIsContabilidadMenuOpen(false)
								: setIsContabilidadMenuOpen(true);
						}}
						className={`${
							userprofiledata === 'Declaraciones' ||
							userprofiledata === 'Facturas'
								? 'font-bold'
								: ''
						} administrador`}
					>
						Mi contabilidad{' '}
						{isContabilidadMenuOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
					</button>
				)}
				{profileComplete && planComplete && isContabilidadMenuOpen && (
					<>
						<Link to='/profile'>
							<button
								onClick={() => setProfileData('Facturas')}
								className={`${classes.profile_option} ${
									userprofiledata === 'Facturas' ? ' font-bold' : ''
								} administradorSubtitle`}
							>
								Facturas
							</button>
						</Link>
						<Link to='/profile'>
							<button
								onClick={() => setProfileData('Declaraciones')}
								className={`${classes.profile_option} ${
									userprofiledata === 'Declaraciones' ? ' font-bold' : ''
								} administradorSubtitle`}
							>
								Declaraciones
							</button>
						</Link>
					</>
				)}
				<Link to='/profile'>
					<button
						onClick={() => setProfileData('Preguntas')}
						className={`${classes.profile_option} ${
							userprofiledata === 'Preguntas' ? ' font-bold' : ''
						}`}
					>
						Preguntas Frecuentes
					</button>
				</Link>
				<button
					className={`${classes.profile_option}`}
					onClick={() => Logout()}
				>
					Cerrar sesión
				</button>
			</div>
		</div>
	);
};

export default ProfileLeft;
