import React from 'react';
import classes from '../css/DetailItem.module.css';

const DetailHeaderDeclaraciones = () => {
	return (
		<div className={classes.detail_header_wrapper}>
			<h2 className={classes.detail_header_element}>Declaración</h2>
			<h2 className={classes.detail_header_element}>Total</h2>
			<h2 className={classes.detail_header_element}>Presentado</h2>
			<h2 className={classes.detail_header_element}>Límite</h2>
			<h2 className={classes.detail_header_element}>Pagado</h2>
			<h2 className={classes.detail_header_element}>Estatus</h2>
		</div>
	);
};

export default DetailHeaderDeclaraciones;
