export const capitalizeFirstLetter = (str) => {
	if (typeof str !== 'string') {
		throw new Error('Input must be a string');
	}

	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function validateEmail(email) {
	const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return regex.test(email);
}

export function validateRFC(RFC) {
	const regex =
		/^([A-Z&Ñ]{3,4}\d{6}[A-V1-9][0-9A-Z]?)|(([A-Z&Ñ]{3})\d{6}[A-Z\d]{3})\b$/;
	return regex.test(RFC);
}

export function validatePassword(password) {
	const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
	return regex.test(password);
}

export function validatePhoneNumber(number) {
	const regex = /^\d+$/;
	return regex.test(number);
}

export async function changeKeysToLabel(users) {
	const filteredRFCs = await users.filter((x) => validateRFC(x.rfc));

	for (let obj of filteredRFCs) {
		const rfcValue = obj.rfc;
		delete obj.rfc;
		obj.label = rfcValue;
	}
	return filteredRFCs;
}

export async function getCurrentDate(USERID) {
	let currentDate = new Date();
	let day = String(currentDate.getDate()).padStart(2, '0');
	let month = String(currentDate.getMonth() + 1).padStart(2, '0');
	let year = String(currentDate.getFullYear()).slice(-2);

	let formattedDate = (USERID || '') + '' + day + '' + month + '' + year;
	return formattedDate;
}

export async function getClientStatusId(clientId, monthString, yearString) {
	const monthMap = {
		enero: '01',
		febrero: '02',
		marzo: '03',
		abril: '04',
		mayo: '05',
		junio: '06',
		julio: '07',
		agosto: '08',
		septiembre: '09',
		octubre: '10',
		noviembre: '11',
		diciembre: '12',
	};

	const month = monthMap[monthString.toLowerCase()];
	const year = yearString.slice(-2);

	return `${clientId}${month}${year}`;
}
