import React, { useState } from 'react';
import classes from '../css/FacturasMoreInfo.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import FacturasUploader from './FacturasUploader';
import FacturasCancelUploader from './FacturasCancelUploader';
import { partialUpdateFactura } from '../../../../../api/facturas';
import { addUserFiles, uploadFactura } from '../../../../../api/userfiles';
import Loading from '../../../../../common/Loading';
import { useSelector } from 'react-redux';

const FacturasMoreInfo = ({ setMoreInfo, moreInfo, setRefreshFacturas }) => {
	const [showUploadFiles, setShowUploadFiles] = useState({
		state: false,
		mode: '',
	});
	const [formData, setFormData] = useState('');
	const [folioFiscal, setFolioFiscal] = useState('');
	const [error, setError] = useState({ state: false, msg: '' });
	const [success, setSuccess] = useState({ state: false, msg: '' });
	const [loading, setLoading] = useState(false);
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	const getNewFacturaStatus = (currentStatus) => {
		switch (currentStatus) {
			case 'Solicitud de cancelación': {
				return 'cancelación emitida';
			}
			case 'Solicitud de factura': {
				return 'emitida';
			}
			default: {
				return 'sin estatus';
			}
		}
	};

	const handleClose = () => {
		setMoreInfo({ state: false });
		setShowUploadFiles({ state: false, mode: '' });
		setFolioFiscal('');
		setFormData('');
		setError({
			state: false,
			msg: '',
		});
		setSuccess({
			state: false,
			msg: '',
		});
	};

	const handlePresentar = () => {
		if (moreInfo.data[0].value === 'Solicitud de factura') {
			setShowUploadFiles({ state: true, mode: 'create' });
		} else if (moreInfo.data[0].value === 'Solicitud de cancelación') {
			setShowUploadFiles({ state: true, mode: 'cancel' });
		}
	};

	const handleGoBack = () => {
		setShowUploadFiles({ state: false, mode: '' });
	};

	const handleGoBackError = () => {
		setFolioFiscal('');
		setFormData('');
		setError({
			state: false,
			msg: '',
		});
		setSuccess({
			state: false,
			msg: '',
		});
	};

	const handleSubmit = async () => {
		setLoading(true);
		if (!formData) {
			setLoading(false);
			setError({
				state: true,
				msg: 'Favor de subir los archivos correspondientes.',
			});
			return;
		}
		try {
			moreInfo.data.forEach((item) => {
				formData.append(item.field, item.value);
			});
			formData.set('Estatus', getNewFacturaStatus(moreInfo.data[0].value));
			formData.set('Folio fiscal', folioFiscal || moreInfo.folioFiscal);

			const responseUpload = await uploadFactura(TOKEN, formData);
			const responseFacturaUpdate = await partialUpdateFactura(
				TOKEN,
				moreInfo.facturaId,
				{
					estatus: getNewFacturaStatus(moreInfo.data[0].value),
					folioFiscal: folioFiscal || moreInfo.folioFiscal,
				}
			);

			if (responseUpload.ok && responseFacturaUpdate.ok) {
				for (let element of formData) {
					if (element[1].name) {
						const userFilesResponse = await addUserFiles(
							TOKEN,
							USERID,
							element[1].name
						);
						if (!userFilesResponse.ok) {
							setLoading(false);
							setError({
								state: true,
								msg: 'Algo salió mal. Favor de volver a intentar.',
							});
							return;
						}
					}
				}
				setLoading(false);
				setSuccess({
					state: true,
					msg: 'Tu operación ha sido completada.',
				});
				setRefreshFacturas((prevState) => !prevState);
			} else {
				setLoading(false);
				setError({
					state: true,
					msg: 'Algo salió mal. Favor de volver a intentar.',
				});
			}
		} catch (error) {
			setLoading(false);
			setError({
				state: true,
				msg: 'Algo salió mal. Favor de volver a intentar.',
			});
		}
	};

	return (
		<div className={classes.modal_overlay}>
			<ClickOutsideReact onClickOutside={handleClose}>
				<div className={classes.modal_container}>
					<button className={classes.icon_close_wrapper} onClick={handleClose}>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>{moreInfo.data[0].value}</h1>
					<h2 className={classes.modal_subtitle}>
						{showUploadFiles.state &&
							showUploadFiles.mode === 'cancel' &&
							'Subir acuse de cancelación de factura'}
						{showUploadFiles.state &&
							showUploadFiles.mode === 'create' &&
							'Subir documentos de factura emitida'}
						{!showUploadFiles.state && 'Desglose de información'}
					</h2>
					{!success.state && !error.state && (
						<>
							{!showUploadFiles.state && (
								<section>
									<div className={classes.modal_info_container}>
										{moreInfo.data.length > 0 && (
											<div className={classes.modal_info_element}>
												{moreInfo.data.map((x) => (
													<button
														key={x.field}
														className={classes.modal_info_user}
													>
														<h1 className={classes.modal_info_element_title}>
															{x.field}
														</h1>
														<h1 className={classes.modal_info_element_subtitle}>
															{x.value}
														</h1>
													</button>
												))}
											</div>
										)}
									</div>
									{(moreInfo.data[0].value === 'Solicitud de factura' ||
										moreInfo.data[0].value === 'Solicitud de cancelación') && (
										<div className={classes.modal_wrapper_button}>
											<button
												className={classes.modal_button}
												onClick={handlePresentar}
											>
												{moreInfo.data[0].value === 'Solicitud de factura'
													? 'Subir factura'
													: 'Subir acuse'}
											</button>
										</div>
									)}
								</section>
							)}
							{showUploadFiles.state && (
								<section>
									<div className={classes.modal_info_container}>
										{loading && <Loading loading={loading} />}
										{!loading && showUploadFiles.mode === 'create' && (
											<FacturasUploader
												userRFC={moreInfo.userRFC}
												onSetFormData={setFormData}
												onSetFolioFiscal={setFolioFiscal}
												validExtensions={['pdf', 'xml']}
												nameConvention={'_FACTURA_'}
											/>
										)}
										{!loading && showUploadFiles.mode === 'cancel' && (
											<FacturasCancelUploader
												userRFC={moreInfo.userRFC}
												onSetFormData={setFormData}
												validExtensions={['pdf']}
												nameConvention={'_FACTURACANCELADA_'}
												folioFiscal={moreInfo.folioFiscal}
											/>
										)}
									</div>
									{!loading && (
										<div className={classes.modal_wrapper_button}>
											{!error.state && formData && (
												<button
													className={classes.modal_button}
													onClick={handleSubmit}
												>
													Emitir
												</button>
											)}
											<button
												className={`${classes.modal_button} ${classes.modal_button_back}`}
												onClick={handleGoBack}
											>
												Volver
											</button>
										</div>
									)}
								</section>
							)}
						</>
					)}
					{success.state && !error.state && (
						<div className={classes.modal_info_container}>
							<h1 className={classes.modal_info_success}>{success.msg}</h1>
							<button
								className={`${classes.modal_button} ${classes.modal_button_back}`}
								onClick={handleClose}
							>
								Cerrar
							</button>
						</div>
					)}
					{error.state && (
						<div className={classes.modal_info_container}>
							<h1 className={classes.modal_info_error}>{error.msg}</h1>
							<button
								className={`${classes.modal_button} ${classes.modal_button_back}`}
								onClick={handleGoBackError}
							>
								Volver
							</button>
						</div>
					)}
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default FacturasMoreInfo;
