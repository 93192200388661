import React from 'react';
import classes from './css/DetailItem.module.css';
import DetailHeaderItem from './DetailHeaderItem';

const DetailHeaderPendings = ({
	sortResults,
	currentSortingCategory,
	setCurrentSortingCategory,
	isPendingDeclarations,
}) => {
	const categories = isPendingDeclarations
		? ['Tipo', 'RFC', 'Periodo', 'Aprobado/Solicitado', 'Estatus']
		: ['Tipo', 'RFC', 'Periodo', 'Presentada', 'Estatus'];
	return (
		<div className={classes.detail_header_wrapper}>
			{categories.map((x) => {
				return (
					<DetailHeaderItem
						key={x}
						sortResults={sortResults}
						categoryName={x}
						currentSortingCategory={currentSortingCategory}
						setCurrentSortingCategory={setCurrentSortingCategory}
					/>
				);
			})}
		</div>
	);
};

export default DetailHeaderPendings;
