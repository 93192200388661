import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import message from '../assets/images/message.png';
import RegisterNavbar from '../components/layout/navbar/RegisterNav';
import FooterHome from '../components/layout/footer/FooterHome';

const ForgetPassword = () => {
	const [state, setState] = useState(false);
	const [email, setEmail] = useState('');
	const sendEmail = async () => {
		try {
			const response = await fetch(
				process.env.REACT_APP_API_URL + 'client/auth/forgot-password',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: email,
					}),
				}
			);

			if (response.ok) {
				setState(true);
				console.log('Email sent successfully');
			} else {
				console.error('Failed to send email');
			}
		} catch (error) {
			console.error('Error sending email:', error);
		}
	};
	return (
		<>
			<RegisterNavbar />
			<div className='md:pt-[130px] pt-[70px]'>
				<div className='container'>
					{state ? (
						<div>
							<div>
								<h2 className='md:text-[64px] text-center text-[32px] font-bold'>
									¡Revisa tu correo! Hemos enviado las instrucciones para
									restablecer tu contraseña.
								</h2>
							</div>
							<div className='mt-14 max-w-[550px] text-center m-auto'>
								<div className='mt-8'>
									<Link to={'/login'}>
										<button className='py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]'>
											Volver a página principal
										</button>
									</Link>
								</div>
							</div>
						</div>
					) : (
						<div>
							<div>
								<h2 className='md:text-[64px] text-center text-[32px] font-bold'>
									¿Necesitas una contraseña nueva?
								</h2>
								<p className='md:text-[24px] md:px-[150px]  md:leading-[28px] text-[14px] leading-[17px]'>
									Introduce tu dirección de correo electrónico y te enviaremos
									las instrucciones para cambiar tu contraseña.
								</p>
							</div>
							<div className='mt-14 max-w-[550px] text-center m-auto'>
								<div className='input_data'>
									<input
										type='text'
										id='text'
										className='md:text-[24px] text-[14px] font-normal'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									<label
										htmlFor='text'
										className='md:text-[24px] text-[14px] font-normal flex items-center gap-2'
									>
										{' '}
										<img src={message} alt='' /> <span>Correo electrónico</span>
									</label>
								</div>
								<div className='mt-8'>
									<button
										onClick={sendEmail}
										className='py-4 px-5 bg-[#4285F4] text-white text-[14px] font-medium rounded-md md:text-[24px]'
									>
										Obtén nueva contraseña
									</button>
									<p className='md:text-[24px] text-[14px] font-normal mt-5'>
										¿Aún no tienes una cuenta?{' '}
										<Link className='  border-b border-gray-400' to={'/signup'}>
											Regístrate aquí
										</Link>
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<FooterHome />
		</>
	);
};

export default ForgetPassword;
