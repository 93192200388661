import React from 'react';

const Aviso = (props) => {
	return (
		<div>
			<div>
				<h2 className='md:text-[64px] md:leading-[81px] text-[32px] mb-5 font-bold leading-[37px]'>
					{props.title}
				</h2>
				<p className='md:text-[24px] text-[14px] font-normal'>
					AVISO DE PRIVACIDAD INTEGRAL
					<br />
					<br />
					<strong>Responsable.</strong>
					<br />
					CIBERCONTA, S.A.P.I. DE C.V., (en adelante, y de forma conjunta el
					“Responsable”) con domicilio convencional ubicado en Calle Miguel
					Hidalgo Y Costilla, número exterior 310, Col. La Fama, C.P., 66100,
					Santa Catarina, Nuevo León., Teléfono 18131160850, correo electrónico
					admin@ciberconta.com , estamos conscientes que usted como visitante de
					nuestras oficinas y/o sitio web, consumidor o potencial consumidor de
					nuestros productos y/o servicios tiene derecho a conocer qué
					información recabamos de usted y nuestras prácticas en relación con
					dicha información.
					<br />
					<br />
					Las condiciones contenidas en el presente son aplicables a la
					información que se recaba a nombre de y por el Responsable o
					cualquiera de sus empresas filiales o subsidiarias, por cualquier
					medio, incluyendo a través de o cualquier sitio web operado por el
					Responsable. Datos Personales
					<br />
					<br />
					Los datos personales que puede llegar a recabar el Responsable de
					forma directa o indirecta consisten en los siguientes: Los datos
					personales considerados como de identificación son todos los relativos
					a la identificación de la persona (nombre completo, dirección,
					teléfonos fijo y/o celular, empresa para la cual labora, huellas
					digitales, fecha de nacimiento, nacionalidad, lugar de nacimiento,
					ocupación y/o sus familiares directos). Nos comprometemos a que todos
					los datos obtenidos serán tratados bajo las más estrictas medidas de
					seguridad que garanticen su confidencialidad.
					<br />
					<br />
					Con el fin de otorgar el servicio principal de CIBERCONTA, que tiene
					como objetivo, analizar y realizar la declaración correspondiente, se
					le solicitara al usuario y autoriza a CIBERCONTA hacer uso de la
					siguiente información:
					<br />
					a. Nombre;
					<br />
					b. Registro Federal de Contribuyentes (“RFC”);
					<br />
					c. Nombre y Clave de Identificación Electrónica Confidencial
					(“Contraseña CIEC”) emitida por el Portal del Servicio de
					Administración Tributaria (“SAT”);
					<br />
					d. Régimen Fiscal en que se encuentra registrado en el SAT;
					<br />
					e. Aplicaciones digitales relacionados con el SAT que utiliza el
					Usuario;
					<br />
					f. Constancias de retenciones y/o estados de cuenta bancarios;{'\n'}
					g. Factura, recibos fiscales o retenciones emitidas y/o recibidas en
					formato XML y CFDI ( por sus siglas Comprobante Fiscal Digital por
					Internet);
					<br />
					h. Firma Electrónica Avanzada;
					<br />
					i. Certificados de sellos digitales.
					<br />
					<br />
					<strong>Finalidades</strong>
					<br />
					La finalidad principal para las que recabamos sus datos tiene por
					objeto prestar los servicios integrales de contabilidad a personas
					físicas y morales por medio de una aplicación móvil o cualquier otro
					medio electrónico disponible cumpliendo con los estándares mediante
					los procesos internos para asegurar la calidad y seguridad del cliente
					en nuestras instalaciones.
					<br />
					<br />
					Las finalidades secundarias para las que recabamos sus datos son:
					facturación, cobranza, informarle sobre nuevos productos, servicios o
					cambios en los mismos, mensajes promocionales; evaluar la calidad del
					servicio; cumplir con las obligaciones derivadas de la prestación del
					servicio; cumplir con la legislación aplicable vigente; contestar
					requerimientos de información de cualquier autoridad, ya sea por
					investigaciones, estadísticas o reportes normativos; atender a sus
					comentarios relacionados con la prestación de servicios; enviar avisos
					e información de nuestros servicios; y coadyuvar con el proceso de
					mejora continua, tales como asesoría, consultoría, y análisis,
					relacionados con los ramos contable y fiscal.
					<br />
					<br />
					<strong>Transferencias y Encargados de Datos Personales</strong>
					<br />
					Asimismo, le informamos que sus datos personales podrán ser
					transferidos a terceros y podrán ser compartidos a encargados para su
					tratamiento dentro y fuera del país, por personas distintas al
					Responsable, quien girará las instrucciones para su tratamiento. En
					ese sentido, su información puede ser transferida o compartida con (i)
					Diversos profesionales, técnicos y auxiliares, así como otros entes
					privados por cuestión de servicios subrogados en relación con la
					atención del cliente; (ii) Administradoras de programas de lealtad;
					(iii) Socios comerciales del Responsable, con la finalidad de que
					estos administren y operen servicios complementarios a los del
					Responsable; (iv) Sociedades o terceros que operen en forma conjunta
					con el Responsable algún producto, servicio o cualquier software o
					infraestructura informática que sirva como plataforma para la
					realización de operaciones o servicios; (v) Terceros prestadores de
					servicios o vendedores de productos necesarios para la operación de la
					responsable, así como comisionistas que realicen operaciones o brinden
					servicios a la responsable que esta pueda realizar de acuerdo con la
					legislación vigente y sus estatutos sociales, como son, entre otros,
					comisionistas, procesadores de datos, empresas de envío de material de
					marketing, empresas de mensajería, de seguridad, transporte de
					valores, agencias de publicidad, guarda de información, con el
					propósito de que estos asistan en la realización de las finalidades
					previstas en este aviso de privacidad; (vi) Profesionistas, asesores o
					consultores externos, para efecto de la administración de operaciones
					de venta, servicios y de los demás actos que la responsable pueda
					realizar de conformidad con la legislación vigente y sus estatutos
					sociales, así como para la defensa de sus intereses ante cualquier
					controversia legal que surja con motivo de dichas operaciones y
					servicios, tales como agencias de cobranza, auditores externos,
					legales, contables, etc.; y (vii) Todas aquellas dependencias
					gubernamentales y/o judiciales que por ministerio de ley soliciten y/o
					requieran de la responsable datos personales de sus clientes y/o
					familiares, necesarias para el cumplimiento de diversas legislaciones.
					<br />
					<br />
					Si usted no manifiesta su oposición para que sus datos personales sean
					transferidos, se entenderá que ha otorgado su consentimiento para
					ello.
					<br />
					<br />
					El Responsable informa que todos los contratos de prestación de
					servicios con terceros que impliquen el tratamiento de su información
					personal a nombre y por cuenta del Responsable incluirán una cláusula
					garantizando que otorgan el nivel de protección de datos personales,
					mediante el cual se constituyan encargados en términos del párrafo
					anterior. En cualquier caso, todo manejo de datos personales se
					realizará dando cumplimiento a la Ley Federal de Protección de Datos
					Personales en Posesión de Particulares (en adelante la “Ley”) y su
					Reglamento.
					<br />
					<br />
					La información que proporcione deberá ser veraz y completa. Por lo que
					queda bajo su responsabilidad la veracidad de los datos proporcionados
					y en ningún caso el Responsable será responsable a este respecto.
					<br />
					<br />
					<strong>Ejercicio de Derechos ARCO</strong>
					<br />
					En el momento que lo estime oportuno podrá ejercer sus derechos ARCO
					(acceso, rectificación, cancelación y oposición) sobre el tratamiento
					de los datos personales proporcionados, así como revocar el
					consentimiento otorgado en este documento, por lo cual deberá ponerse
					en contacto con nosotros a través del correo electrónico
					admin@ciberconta.com , el procedimiento y requisitos que deberá
					contener su solicitud de conformidad con lo dispuesto en la Ley y su
					Reglamento, son los siguientes:
					<br />
					(1) Nombre y Domicilio, si no se incluye la dirección se dará por no
					recibida la solicitud. (2) El documento que acredite su identidad o la
					personalidad de su representante (copia de identificación oficial
					vigente.) El representante deberá acreditar la identidad del titular,
					identidad del representante, y sus facultades de representación
					mediante instrumento público o carta poder firmada ante dos testigos,
					o declaración en comparecencia personal del titular; (3) La
					descripción clara y precisa de los datos personales a los que desea
					acceder, rectificar, cancelar u oponerse; (4) Descripción de otros
					elementos que faciliten la localización de sus datos personales (sitio
					Web, Sucursal).Los documentos deberán ser escaneados y adjuntados al
					correo electrónico para verificar la veracidad de los mismos.
					<br />
					<br />
					Para conocer el procedimiento, requisitos y plazos del ejercicio de
					los derechos ARCO puedes ponerte en contacto al correo electrónico
					admin@ciberconta.com.
					<br />
					<br />
					<strong>Modificaciones al Aviso de Privacidad</strong>
					<br />
					El Responsable se reserva el derecho de efectuar en cualquier momento
					modificaciones o actualizaciones al presente aviso de privacidad, para
					la atención de novedades legislativas o jurisprudenciales, políticas
					internas, nuevos requerimientos para la prestación u ofrecimiento de
					nuestros servicios y prácticas del mercado, cualquier modificación al
					Aviso de Privacidad estará disponible a través de nuestro portal Web;
					sección “aviso de privacidad”.
					<br />
					<br />
					El presente Aviso de Privacidad ha sido modificado el día 17
					(diecisiete) del mes de abril de 2023 (dos mil veintitrés).
					<br />
					<br />{' '}
				</p>
			</div>
		</div>
	);
};

export default Aviso;
