import React from 'react';
import HomeNav from '../components/layout/navbar/HomeNav';
import Aviso from '../components/layout/views/Client/Privacidad/Aviso';
import FooterHome from '../components/layout/footer/FooterHome';

const AvisoPage = () => {
	return (
		<div>
			<HomeNav />
			<div className='mx-[150px] pt-[120px]'>
				<p className='md:text-[24px] text-[14px] font-normal'>
					<Aviso title='Aviso de Privacidad' />
				</p>
			</div>
			<FooterHome />
		</div>
	);
};

export default AvisoPage;
