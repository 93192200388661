import { createSlice } from '@reduxjs/toolkit';

const initialClientStatusState = {
	impuestosPorPagar: 0,
	email: '',
	userName: '',
	userFullName: '',
	userRFC: '',
	userCIEC: '',
	userPhone: '',
	userRole: 0,
	userEfirma: '',
	userStripeId: '',
	userKEYFile: '',
	userCERFile: '',
	userSubscription: '',
	userAnualPlan: '',
};

const clientStatusSlice = createSlice({
	name: 'clientStatus',
	initialState: initialClientStatusState,
	reducers: {
		setImpuestos(state, action) {
			state.impuestosPorPagar = action.payload;
		},
		setClientData(state, action) {
			state.userEmail = action.payload.userEmail;
			state.userName = action.payload.userName;
			state.userFullName = action.payload.userFullName;
			state.userRFC = action.payload.userRFC;
			state.userCIEC = action.payload.userCIEC;
			state.userPhone = action.payload.userPhone;
			state.userRole = action.payload.userRole;
			state.userEfirma = action.payload.userEfirma;
			state.userStripeId = action.payload.userStripeId;
			state.userKEYFile = action.payload.userKEYFile;
			state.userCERFile = action.payload.userCERFile;
			state.userSubscription = action.payload.userSubscription;
			state.userAnualPlan = action.payload.userAnualPlan;
		},
	},
});

export const clientStatusActions = clientStatusSlice.actions;
export default clientStatusSlice.reducer;
